import { Typography, Grid, Button, Box, Link } from "@mui/material";
import { MobileScreenShareOutlined as MobileScreenShareOutlinedIcon } from "@mui/icons-material/";
import { labels } from "../../../labels";
import PrintIcon from "@mui/icons-material/Print";
import { PATH_STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE } from "../../../path";

const { TEXT_BUTTON_PUBBLICAZIONE, TEXT_BUTTON_PUBBLICAZIONE_LINK, TEXT_BUTTON_PUBBLICAZIONE_2, BUTTON_CONDIVIDI, BUTTON_SCARICA } = labels;

export const CardButton = (props) => {
  let uuid = props.uuid;
  return (
    <Box className="cardButton">
      <Grid container>
        <Grid item xs={12} className="center">
          <Typography className="fontBold" sx={{ marginTop: "10px", cursor: "default" }}>
            {TEXT_BUTTON_PUBBLICAZIONE}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container className="center">
            <Grid item>
              <Typography sx={{ cursor: "default" }} className="fontBold">
                {TEXT_BUTTON_PUBBLICAZIONE_2}
              </Typography>
            </Grid>
            <Grid item sx={{ marginLeft: "10px" }}>
              <Link sx={{ cursor: "pointer" }} onClick={() => window.open(PATH_STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE, "_blank")}>
                <Typography className="fontBold">{TEXT_BUTTON_PUBBLICAZIONE_LINK}</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button
        className="buttonScaricaCondividiPubblicazione"
        onClick={() => window.open(window.origin + "/shared/v1/projects/" + uuid + "?print=true", "_blank")}
      >
        <Grid>
          <Grid item>
            <PrintIcon className="iconButtonPubblicazione" />
          </Grid>
          <Grid item>
            <Typography className="textButtonPubblicazione"> {BUTTON_SCARICA}</Typography>
          </Grid>
        </Grid>
      </Button>
      <Button
        className="buttonScaricaCondividiPubblicazione"
        disabled={props.disabledCondividi}
        onClick={() => window.open(window.origin + "/shared/v1/projects/" + uuid + "?print=false", "_blank")}
      >
        <Grid>
          <Grid item>
            <MobileScreenShareOutlinedIcon className="iconButtonPubblicazione" />
          </Grid>
          <Grid item>
            <Typography className="textButtonPubblicazione"> {BUTTON_CONDIVIDI}</Typography>
          </Grid>
        </Grid>
      </Button>
    </Box>
  );
};
