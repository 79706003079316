import { createAction } from "@reduxjs/toolkit";

export const fetchDettagliProgetto = createAction("FETCH_DETTAGLI_PROGETTO@data");

export const codiceProgetto = createAction("CODICE_PROGETTO@data");

export const fetchSezioneMieiProgetti = createAction("FETCH_SEZIONE_MIEI_PROGETTI@data");

export const fetchSezioneProgettiCurricolariExtraCurricolari = createAction("SEZIONE_PROGETTI_CURRICOLARI_EXTRA_CURRICOLARE@data");

export const fetchSezioneProgettiIstituto = createAction("FETCH_SEZIONE_PROGETTI_ISTITUTO@data");

export const fetchProgettiBozzaSuccess = createAction("FETCH_PROGETTI_BOZZA_SUCCESS@data");

export const fetchProgettiBozzaFailed = createAction("FETCH_PROGETTI_BOZZA_FAILED@data");

export const fetchProgettiConclusiSuccess = createAction("FETCH_PROGETTI_CONCLUSI_SUCCESS@data");

export const fetchProgettiConclusiFailed = createAction("FETCH_PROGETTI_CONCLUSI_FAILED@data");

export const fetchProgettiIstitutoSuccess = createAction("FETCH_PROGETTI_ISTITUTO_SUCCES@data");

export const fetchProgettiIstitutoFailed = createAction("FETCH_PROGETTI_ISTITUTO_FAILED@data");

export const fetchProgettiCurricolariExtraCurricolariSuccess = createAction("FETCH_PROGETTI_CURRICOLARI_EXTRACURRICOLARE_SUCCESS@data");

export const fetchProgettiCurricolariExtraCurricolariFailed = createAction("FETCH_PROGETTI_CURRICOLARI_EXTRACURRICOLARE_FAILED@data");

export const fetchProgettiInnovazioneScuolaFailed = createAction("FETCH_PROGETTI_INNOVAZIONE_SCUOLA_FAILED@data");

export const fetchDettagliProgettoSuccess = createAction("FETCH_DETTAGLI_PROGETTO_SUCCESS@data");

export const fetchDettagliProgettoFiled = createAction("FETCH_DETTAGLI_PROGETTO_FAILED@data");

export const fetchDettagliProgettoReset = createAction("FETCH_DETTAGLI_PROGETTO_RESET@data");

export const fetchCodiceProgettoSuccess = createAction("FETCH_CODICE_PROGETTO_SUCCESS@data");

export const fetchCodiceProgettoFailed = createAction("FETCH_CODICE_PROGETTO_FAILED@data");
