
import { createSelector } from "@reduxjs/toolkit";

export const getConfigurationStore = (state) => state.datiOsservatorio;

export const getDisciplineCoinvolte = createSelector(getConfigurationStore, (configuration) => configuration.disciplineCoinvolte);

export const getAttivitaInnovazione = createSelector(getConfigurationStore, (configuration) => configuration.attivitaInnovazione);

export const getComune = createSelector(getConfigurationStore, (configuration) => configuration.comune);

export const getIdProgNuovo = createSelector(getConfigurationStore, (configuration) => configuration.idProgetto);

export const getUuidProgNuovo = createSelector(getConfigurationStore, (configuration) => configuration.uuidProgetto);

export const getSchedaProfilo = createSelector(getConfigurationStore, (configuration) => configuration.schedaProfilo);

