import { Grid, Box, Avatar, Typography, Link } from "@mui/material";
import { useState } from "react";
import { PaginationShared } from "../../../shared/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getMembriTeam } from "../../../../modules/istituti";
import { labels } from "../../../../labels";
import { useNavigate } from "react-router-dom";
import { savePath, setIdProgetto } from "../../../../modules/configuration";
import { PATH_SCHEDA_PROFILO } from "../../../../path";
import { fetchSchedaProfilo } from "../../../../modules/datiOsservatorio";

const { RUOLO, MAIL, LINK_SOCIAL } = labels;

export const MembriTeamIstituto = (props) => {
  const [page, setPage] = useState(1);
  const [val1, setVal1] = useState(0);
  const [val2, setVal2] = useState(4);
  const datiMembriTeam = useSelector(getMembriTeam);
  const MembriTeam = datiMembriTeam.data.length !== 0 ? datiMembriTeam.data : [];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //cambio pagina visualizzazione membri team
  function handlechange(e, value) {
    setPage(value);
    setVal2(4 * value);
    setVal1(value * 4 - 4);
  }

  return (
    <Box className="tabMembriTeam">
      <Grid container>
        <Grid container>
          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", "@media (max-width:900px)": { display: "none" } }}>
            {datiMembriTeam.data.length === 0 && (
              <Typography sx={{ fontSize: "22px", fontWeight: "Bold", marginTop: "20px" }}>
                Nessun membro del team dell'istituto ha effettuato l'accesso
              </Typography>
            )}
          </Grid>
          {MembriTeam.slice(val1, val2).map((elemento, index) => (
            <Grid item xs={12} sm={6} md={6} xl={6} sx={{ marginTop: "30px" }} key={index}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={4} className="center">
                  <Avatar
                    className="avatar"
                    alt="immagine-profilo"
                    src={elemento.immagine_profilo}
                    sx={{
                      color: props.color1,
                      borderColor: props.color1,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={8}>
                  <Link
                    sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer !important" }}
                    onClick={() => {
                      dispatch(setIdProgetto(elemento.uuid_progetto));
                      navigate(PATH_SCHEDA_PROFILO);
                      dispatch(fetchSchedaProfilo("/" + elemento.id_team));
                      dispatch(savePath(props.path));
                    }}
                  >
                    <Typography className="titleCampiMembriTeam">
                      {(elemento.cognome !== null ? elemento.cognome : "") + " " + (elemento.nome !== null ? elemento.nome : "")}
                    </Typography>
                  </Link>
                  <Typography className="titleCampiMembriTeam">{RUOLO}</Typography>
                  <Typography>{elemento.descr_incarico}</Typography>
                  <Typography className="titleCampiMembriTeam">{MAIL}</Typography>
                  <Link
                    sx={{
                      cursor: "pointer",
                      fontSize: "18px",
                      wordWrap: "break-word",
                      textDecorationColor: "black !important",
                      color: "black !important",
                    }}
                    onClick={() => window.open("mailto:" + elemento.email)}
                  >
                    <Typography> {elemento.email} </Typography>
                  </Link>
                  <Typography className="titleCampiMembriTeam">{elemento.link_social !== null ? LINK_SOCIAL : null}</Typography>
                  <Link
                    sx={{ cursor: "pointer", fontSize: "18px", textDecorationColor: "black !important", color: "black !important" }}
                    onClick={() => window.open(elemento.link_social !== null ? elemento.link_social : null)}
                  >
                    {elemento.link_social !== null ? elemento.link_social : null}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Box className="center" sx={{ marginTop: "30px" }}>
        {Math.ceil(MembriTeam.length / 4) > 1 ? (
          <PaginationShared page={page} handlechange={handlechange} color={props.color1} count={Math.ceil(MembriTeam.length / 4)} />
        ) : null}
      </Box>
    </Box>
  );
};
