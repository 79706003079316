import { Box } from "@mui/system";
import { ButtonFiltri } from "../ButtonFiltri";
import { useFormik } from "formik";
import { Grid, Typography } from "@mui/material";
import { getFiltriProgettiAttivita, getfiltri, resetFiltriProgetti } from "../../../../modules/configuration";
import { PATH_PROGETTI_INNOVAZIONE_SCUOLA } from "../../../../path";
import { useSelector, useDispatch } from "react-redux";
import { CercaIcon } from "../../../../styles/icons";
import { HeaderPage } from "../../../shared/Header";
import { labels } from "../../../../labels";
import { getCommonStore } from "../../../../modules/common/selectors";
import { Autocomplete, Calendar, Input, Select } from "../../../shared/form";
import { getIstituti } from "../../../../modules/istituti";
import { FinalitaFiltro } from "../../../shared/FinalitaFiltro";
import { getAttivitaInnovazione } from "../../../../modules/datiOsservatorio";
import { useEffect } from "react";

const {
  DATI_PROGETTO,
  SCEGLI_FILTRI_PROGETTI,
  TIPOLOGIA_BENEFICIARIO,
  PLACEHOLDER_TIPOLOGIA_BENEFICIARIO,
  DATA_INIZIO_PROGETTO,
  ORDINE_SCUOLA,
  PLACEHOLDER_ORDINE_SCUOLA,
  CODICE_MECCANOGRAFICO,
  PLACEHOLDER_CODICE_MECCANOGRAFICO,
  DATA_INSERIMENTO,
  TITOLO_PROGETTO,
  PLACEHOLDER_TITOLO_PROGETTO,
  PLACEHOLDER_DATA,
  PROVINCIA,
  PLACEHOLDER_PROVINCIA,
  ABSTRACT,
  PLACEHOLDER_ABSTRACT,
  ANNO_REALIZZAZIONE,
  DENOMINAZIONE_ISTITUTO,
  PLACEHOLDER_DENOMINAZIONE,
  TEMPO_REALIZZAZIONE,
  PLACEHOLDER_TEMPO_REALIZZAZIONE,
  REFERENTE,
  PLACEHOLDER_REFERENTE,
  PUBBLICATO,
  PLACEHOLDER_STATO_PUBBLICAZIONE,
  PLACEHOLDER_SCELTA,
  DOCUMENTATO,
  PLACEHOLDER_DOCUMENTAZIONE,
  PLACEHOLDER_VALUTAZIONE,
  DOCUMENTAZIONE,
  VALUTAZIONE,
  PLACEHOLDER_ANNO_REALIZZAZIONE,
  FONDI_CONTESTO,
  PLACEHOLDER_FONDI_CONTESTO,
  AREA_COINVOLTA,
  PLACEHOLDER_AREA_COINVOLTA,
  CO_REFERENTE,
  PLACEHOLDER_DOCUMENTATO,
} = labels;

export const FiltriProgInnSc = () => {
  const dispatch = useDispatch();
  const options = useSelector(getfiltri);
  const allOptions = useSelector(getCommonStore);
  const filtriAttivita = useSelector(getFiltriProgettiAttivita);

  let optDocumentato = [
    { id: 1, descrizione: "NO" },
    { id: 2, descrizione: "SI" },
  ];

  let allOptionsFiltered = {};

  for (const [key, value] of Object.entries(allOptions)) {
    allOptionsFiltered[key] = value.data !== null ? value.data : [];
  }

  const attivita = useSelector(getAttivitaInnovazione);

  //estraggo dati per selezioni scelta select dai dati che già possiedo
  const istituti = useSelector(getIstituti);
  const istitutiDati = istituti.data;
  const codiceMeccElenco = [];
  istitutiDati.map((e, i) => codiceMeccElenco.push({ id: i, descrizione: e.codice_scuola }));
  const elencoDenominazioni = [];
  istitutiDati.map((e, i) => elencoDenominazioni.push({ id: i, descrizione: e.nome }));

  const formik = useFormik({
    initialValues: {
      titolo: options["titolo"] || "",
      dataInizio: options["data_inizio"] || "",
      denominazione: options["denominazione"] || "",
      codiceMeccanografico: options["codiceMeccanografico"] || "",
      abstract: options["abstract"] || "",
      dataInserimento: options["dataInserimento"] || "",
      ordineDiScuola: options["ordineDiScuola"] || "",
      tipologiaBeneficiario: options["tipologiaBeneficiario"] || "",
      provincia: options["provincia"] || "",
      fondiEcontestoFinanziamento: options["fondiEcontestoFinanziamento"] || "",
      annoRealizzazione: options["annoRealizzazione"] || "",
      areaCoinvolta: options["areaCoinvolta"] || "",
      pubblicato: options["pubblicato"] || "",
      tempoRealizzazione: options["tempoRealizzazione"] || "",
      referente: options["referente"] || "",
      documentato: options["documentato"] || "",
      attivita: filtriAttivita.length === 0 ? "" : filtriAttivita,
      documentazione: options["documentazione"] || "",
      valutazione: options["valutazione"] || "",
      coReferenti: options["coReferenti"] || "",
    },

    onSubmit: (values, { resetForm }) => {
      for (const [key, value] of Object.entries(values)) {
        if (value !== "") {
          formik.values[key] = "";
        }
      }
      resetForm({ values: "" });
      dispatch(resetFiltriProgetti());
    },
  });

  //cambio il valore formik di attivita in aggiunta ai filtri delle attivita
  useEffect(() => {
    formik.setFieldValue("attivita", filtriAttivita.length === 0 ? "" : filtriAttivita);
    // eslint-disable-next-line
  }, [filtriAttivita]);

  //al cambiamento del valore della scelta setto il valore di formikF
  const onDatiChange = (modifiche) => {
    // eslint-disable-next-line
    modifiche.map((modifica) => {
      const { fieldName, fieldValue } = modifica;
      formik.setFieldValue(fieldName, fieldValue);
    });
  };

  return (
    <form onSubmit={formik.handleSubmit} id="formFiltriProgCurrExtraCurr">
      <Box className="ricerca">
        <HeaderPage linkBack={true} icon={<CercaIcon />} title={SCEGLI_FILTRI_PROGETTI} color={"#8E2155"} />
        <Typography sx={{ fontWeight: "Bold", fontSize: "20px !important" }}>{DATI_PROGETTO}</Typography>
        <Grid container spacing={1}>
          {/* R1 C1 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              label={TITOLO_PROGETTO}
              placeholder={PLACEHOLDER_TITOLO_PROGETTO}
              options={allOptionsFiltered.titoli.innovazione}
              value={formik.values.titolo !== "" ? allOptionsFiltered.titoli.innovazione.find((e) => e.descrizione === formik.values.titolo) : null}
              onChange={(e, v) => {
                const { descrizione } = v;
                onDatiChange([{ fieldName: "titolo", fieldValue: descrizione !== PLACEHOLDER_TITOLO_PROGETTO ? descrizione : "" }]);
              }}
            />
          </Grid>
          {/* R1 C2 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Input
              label={ABSTRACT}
              placeholder={PLACEHOLDER_ABSTRACT}
              value={formik.values.abstract}
              onChange={(e) => {
                onDatiChange([{ fieldName: "abstract", fieldValue: e.target.value }]);
              }}
            />
          </Grid>
          {/* R1 C3 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Grid container spacing={0.5}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  label={REFERENTE}
                  placeholder={PLACEHOLDER_REFERENTE}
                  options={allOptionsFiltered.referenti.innovazione}
                  value={
                    formik.values.referente !== ""
                      ? allOptionsFiltered.referenti.innovazione.find((e) => e.descrizione === formik.values.referente)
                      : null
                  }
                  onChange={(e, v) => {
                    if (v) {
                      const { descrizione } = v;
                      onDatiChange([{ fieldName: "referente", fieldValue: descrizione !== PLACEHOLDER_REFERENTE ? descrizione : "" }]);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  label={CO_REFERENTE}
                  placeholder={PLACEHOLDER_REFERENTE}
                  options={allOptionsFiltered.coReferenti.innovazione}
                  value={
                    formik.values.coReferenti !== ""
                      ? allOptionsFiltered.coReferenti.innovazione.find((e) => e.descrizione === formik.values.coReferenti)?.id
                      : ""
                  }
                  onChange={(e, v) => {
                    const { children: descrizione } = v.props;
                    onDatiChange([{ fieldName: "coReferenti", fieldValue: descrizione !== PLACEHOLDER_REFERENTE ? descrizione : "" }]);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* R1 C4 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={TIPOLOGIA_BENEFICIARIO}
              placeholder={PLACEHOLDER_TIPOLOGIA_BENEFICIARIO}
              options={allOptionsFiltered.tipologieBeneficiarioProgetto}
              value={
                formik.values.tipologiaBeneficiario !== ""
                  ? allOptionsFiltered.tipologieBeneficiarioProgetto.find((e) => e.descrizione === formik.values.tipologiaBeneficiario)?.id
                  : ""
              }
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([
                  { fieldName: "tipologiaBeneficiario", fieldValue: descrizione !== PLACEHOLDER_TIPOLOGIA_BENEFICIARIO ? descrizione : "" },
                ]);
              }}
            />
          </Grid>
          {/* R2 C1 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Calendar
              label={DATA_INIZIO_PROGETTO}
              placeholder={PLACEHOLDER_DATA}
              name={"dataInizio"}
              section={"anagrafica"}
              formik={formik}
              ricerca={true}
              handle={formik.handleChange("dataInizio")}
            />
          </Grid>
          {/* R2 C2 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Calendar
              label={DATA_INSERIMENTO}
              name={"dataInserimento"}
              section={"anagrafica"}
              placeholder={PLACEHOLDER_DATA}
              formik={formik}
              ricerca={true}
              handle={formik.handleChange("dataInserimento")}
            />
          </Grid>
          {/* R2 C3 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={ANNO_REALIZZAZIONE}
              placeholder={PLACEHOLDER_ANNO_REALIZZAZIONE}
              options={allOptionsFiltered.anniScolasticiRealizzazione}
              value={
                formik.values.annoRealizzazione !== ""
                  ? allOptionsFiltered.anniScolasticiRealizzazione.find((e) => e.descrizione === formik.values.annoRealizzazione)?.id
                  : ""
              }
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([{ fieldName: "annoRealizzazione", fieldValue: descrizione !== PLACEHOLDER_ANNO_REALIZZAZIONE ? descrizione : "" }]);
              }}
            />
          </Grid>
          {/* R2 C4 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={TEMPO_REALIZZAZIONE}
              placeholder={PLACEHOLDER_TEMPO_REALIZZAZIONE}
              options={allOptionsFiltered.tempoRealizzazioneProgetto}
              value={
                formik.values.tempoRealizzazione !== ""
                  ? allOptionsFiltered.tempoRealizzazioneProgetto.find((e) => e.descrizione === formik.values.tempoRealizzazione)?.id
                  : ""
              }
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([{ fieldName: "tempoRealizzazione", fieldValue: descrizione !== PLACEHOLDER_TEMPO_REALIZZAZIONE ? descrizione : "" }]);
              }}
            />
          </Grid>
          {/* R3 C1 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={PROVINCIA}
              placeholder={PLACEHOLDER_PROVINCIA}
              options={allOptionsFiltered.province}
              value={formik.values.provincia !== "" ? allOptionsFiltered.province.find((e) => e.descrizione === formik.values.provincia)?.id : ""}
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([{ fieldName: "provincia", fieldValue: descrizione !== PLACEHOLDER_PROVINCIA ? descrizione : "" }]);
              }}
            />
          </Grid>
          {/* R3 C2 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              label={CODICE_MECCANOGRAFICO}
              placeholder={PLACEHOLDER_CODICE_MECCANOGRAFICO}
              options={codiceMeccElenco}
              value={
                formik.values.codiceMeccanografico !== "" ? codiceMeccElenco.find((e) => e.descrizione === formik.values.codiceMeccanografico) : null
              }
              onChange={(e, v) => {
                const { descrizione } = v;
                onDatiChange([
                  { fieldName: "codiceMeccanografico", fieldValue: descrizione !== PLACEHOLDER_CODICE_MECCANOGRAFICO ? descrizione : "" },
                ]);
              }}
            />
          </Grid>
          {/* R3 C3 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              label={DENOMINAZIONE_ISTITUTO}
              placeholder={PLACEHOLDER_DENOMINAZIONE}
              options={elencoDenominazioni}
              value={formik.values.denominazione !== "" ? elencoDenominazioni.find((den) => den.descrizione === formik.values.denominazione) : null}
              onChange={(e, v) => {
                if (v) {
                  const { descrizione } = v;
                  onDatiChange([{ fieldName: "denominazione", fieldValue: descrizione !== PLACEHOLDER_DENOMINAZIONE ? descrizione : "" }]);
                }
              }}
            />
          </Grid>
          {/* R3 C4 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              label={ORDINE_SCUOLA}
              placeholder={PLACEHOLDER_ORDINE_SCUOLA}
              options={allOptionsFiltered.ordiniScuola}
              value={
                formik.values.ordineDiScuola !== ""
                  ? allOptionsFiltered.ordiniScuola.find((ist) => ist.descrizione === formik.values.ordineDiScuola)
                  : null
              }
              onChange={(e, v) => {
                const { descrizione } = v;
                onDatiChange([{ fieldName: "ordineDiScuola", fieldValue: descrizione !== PLACEHOLDER_ORDINE_SCUOLA ? descrizione : "" }]);
              }}
            />
          </Grid>
          {/* R4 C1 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={AREA_COINVOLTA}
              placeholder={PLACEHOLDER_AREA_COINVOLTA}
              options={allOptionsFiltered.areeCoinvolte}
              value={
                formik.values.areaCoinvolta !== ""
                  ? allOptionsFiltered.areeCoinvolte.find((e) => e.descrizione === formik.values.areaCoinvolta)?.id
                  : ""
              }
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([{ fieldName: "areaCoinvolta", fieldValue: descrizione !== PLACEHOLDER_AREA_COINVOLTA ? descrizione : "" }]);
              }}
            />
          </Grid>
          {/* R4 C2 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              label={FONDI_CONTESTO}
              placeholder={PLACEHOLDER_FONDI_CONTESTO}
              options={allOptionsFiltered.fondiContestoProgetto}
              value={
                formik.values.fondiEcontestoFinanziamento !== ""
                  ? allOptionsFiltered.fondiContestoProgetto.find((ist) => ist.descrizione === formik.values.fondiEcontestoFinanziamento)
                  : null
              }
              onChange={(e, v) => {
                const { descrizione } = v;
                onDatiChange([
                  { fieldName: "fondiEcontestoFinanziamento", fieldValue: descrizione !== PLACEHOLDER_FONDI_CONTESTO ? descrizione : "" },
                ]);
              }}
            />
          </Grid>
          {/* R4 C3 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={PUBBLICATO}
              placeholder={PLACEHOLDER_STATO_PUBBLICAZIONE}
              options={allOptionsFiltered.statiPubblicazione.filter((e) => e.id !== 1)}
              value={
                formik.values.pubblicato !== ""
                  ? allOptionsFiltered.statiPubblicazione.filter((e) => e.id !== 1).find((e) => e.descrizione === formik.values.pubblicato)?.id
                  : ""
              }
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([{ fieldName: "pubblicato", fieldValue: descrizione !== PLACEHOLDER_STATO_PUBBLICAZIONE ? descrizione : "" }]);
              }}
            />
          </Grid>
          {/* R4 C4 */}
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={DOCUMENTATO}
              placeholder={PLACEHOLDER_DOCUMENTATO}
              options={optDocumentato}
              value={formik.values.documentato !== "" ? optDocumentato.find((e) => e.descrizione === formik.values.documentato)?.id : ""}
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([{ fieldName: "documentato", fieldValue: descrizione !== PLACEHOLDER_SCELTA ? descrizione : "" }]);
              }}
            />
          </Grid>
          {/* R5 C1 */}
          <Grid item xs={12} sm={12} lg={6}>
            <FinalitaFiltro formik={formik} attivita={attivita.data} ricerca={true} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={DOCUMENTAZIONE}
              placeholder={PLACEHOLDER_DOCUMENTAZIONE}
              options={allOptionsFiltered.tipologieDocumentazione.innovazione}
              value={
                formik.values.documentazione !== ""
                  ? allOptionsFiltered.tipologieDocumentazione.innovazione.find((e) => e.descrizione === formik.values.documentazione)?.id
                  : ""
              }
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([{ fieldName: "documentazione", fieldValue: descrizione !== PLACEHOLDER_DOCUMENTAZIONE ? descrizione : "" }]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Select
              label={VALUTAZIONE}
              placeholder={PLACEHOLDER_VALUTAZIONE}
              options={allOptionsFiltered.attivitaValutazioneEfficaciaProgetto.innovazione}
              value={
                formik.values.valutazione !== ""
                  ? allOptionsFiltered.attivitaValutazioneEfficaciaProgetto.innovazione.find((e) => e.descrizione === formik.values.valutazione)?.id
                  : ""
              }
              onChange={(e, v) => {
                const { children: descrizione } = v.props;
                onDatiChange([{ fieldName: "valutazione", fieldValue: descrizione !== PLACEHOLDER_VALUTAZIONE ? descrizione : "" }]);
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "center" }}>
          <ButtonFiltri formName="formFiltriProgCurrExtraCurr" formik={formik} path={PATH_PROGETTI_INNOVAZIONE_SCUOLA} />
        </Box>
      </Box>
    </form>
  );
};
