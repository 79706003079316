import { Grid, Typography, IconButton, Button, Box } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttivitaSelezionata,
  getAttivitaSelezionataTablet,
  getDescrizioniSottoAttivita,
  saveSceltaAtt,
  setAttivitaSelezionata,
  setAttivitaSelezionataTablet,
} from "../../modules/configuration";

export const OptionsCheckModale = ({
  attivitaSelezTablet,
  progetto,
  clickSelezione,
  dettagli,
  color,
  border,
  width,
  elemento,
  index,
  click,
  color2,
}) => {
  const dispatch = useDispatch();
  let attSel = useSelector(getAttivitaSelezionata);
  const attSelTab = useSelector(getAttivitaSelezionataTablet);
  attSel = attivitaSelezTablet === true ? attSelTab : attSel;
  let descrizioneSottoAttivita = useSelector(getDescrizioniSottoAttivita);
  let MapDescrizioneSottoAttivita = new Map(JSON.parse(descrizioneSottoAttivita));

  const colorSel = () => {
    if (elemento === attSel) {
      return dettagli === false ? "#FABE10" : color2;
    } else {
      return color;
    }
  };
  color = clickSelezione ? colorSel() : color;

  const marginIcon = (elemento) => {
    if (width === "75%") {
      if (elemento.length < 45) {
        return "20px";
      }
      if (elemento.length > 45 && elemento.length < 55) {
        return "27px";
      }
      if (elemento.length > 55 && elemento.length < 105) {
        return "35px";
      }
      if (elemento.length > 105) {
        return "45px";
      }
    } else {
      if (elemento.length < 45) {
        return "20px";
      }
      if (elemento.length > 45 && elemento.length < 55) {
        return "20px";
      }
      if (elemento.length > 55 && elemento.length < 105) {
        return "25px";
      }
      if (elemento.length > 105) {
        return "35px";
      }
    }
  };

  const checkText = (elemento) => {
    let text = elemento;
    let attivita = progetto !== undefined && progetto !== null ? progetto.attivita : null;

    if (dettagli === true) {
      if (attivita !== null)
        attivita.map((el) =>
          elemento === el.descr_valore
            ? el.descr_valore_progetto !== null
              ? (text = elemento + " (" + el.descr_valore_progetto + ")")
              : (text = elemento)
            : null
        );
    } else {
      for (let [key, value] of MapDescrizioneSottoAttivita.entries()) {
        if (elemento === key && value[0] !== null) text = elemento + " (" + value + ")";
      }
    }

    return (
      <Typography sx={{ fontSize: "16px", wordWrap: "break-word" }} key={index}>
        {" "}
        {text}{" "}
      </Typography>
    );
  };

  return (
    <Box key={index}>
      <Button
        disableRipple={!clickSelezione}
        sx={{
          marginTop: "20px",
          cursor: "default",
          border: border,
          bgcolor: color,
          color: dettagli === true && color !== "#FFFFFF" ? "white" : "black",
          width: width,
          borderRadius: "40px",
          opacity: "1",
          float: "left",
          textTransform: "none",
          fontWeight: "Bold",
          boxShadow: "0px 3px 6px #000000",
          textDecoration: "none",
          ":hover": {
            border: border,
            bgcolor: color,
          },
          "@media (max-width:480px)": { borderRadius: "10px" },
        }}
        onClick={
          clickSelezione
            ? () => {
                dispatch(saveSceltaAtt(true));
                if (attivitaSelezTablet === true) {
                  dispatch(setAttivitaSelezionataTablet(elemento));
                } else {
                  dispatch(setAttivitaSelezionata(elemento));
                }
                colorSel();
              }
            : null
        }
        key={index}
      >
        <Grid container key={index}>
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            xl={10}
            sx={{
              padding: "20px",
              textAlign: "left",
              verticalAlign: "center",
              "@media (max-width:480px)": {
                paddingTop: "15px",
                paddingLeft: "10px",
              },
            }}
            key={index}
          >
            {checkText(elemento)}
          </Grid>
          {dettagli ? null : (
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              xl={2}
              sx={{
                textAlign: "right",
              }}
              key={index}
            >
              <IconButton disableRipple onClick={click} aria-label="Rimuovi attività" sx={{ marginTop: marginIcon(elemento) }} key={index}>
                <CloseIcon
                  sx={{
                    color: "black",
                  }}
                  key={index}
                />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Button>
    </Box>
  );
};
