import { PATH_DETTAGLI_PROGETTO } from "../../../path";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DettagliIcon } from "../../../styles/icons";
import { useDispatch } from "react-redux";
import { savePath, setIdProgetto, setIndexGestisciProgetti } from "../../../modules/configuration";

export const DettagliLink = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let id = props.id;

  return (
    <Button
      aria-label="dettagli"
      onClick={() => {
        dispatch(savePath(props.path));
        dispatch(setIdProgetto(id));
        if (props.indexTabs !== undefined) {
          dispatch(setIndexGestisciProgetti(props.indexTabs));
        }
        navigate(PATH_DETTAGLI_PROGETTO);
      }}
    >
      <DettagliIcon />
    </Button>
  );
};
