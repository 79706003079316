import { createAction } from "@reduxjs/toolkit";

export const saveProgress = createAction("SAVE_PROGRESS@configuration");

export const saveProgressCont = createAction("SAVE_PROGRESS_CONT@configuration");

export const saveProgressBar = createAction("SAVE_PROGRESS_BAR@configuration");

export const deleteval = createAction("DELETE@configuration");

export const checkIndex = createAction("CHECK_INDEX@configuration");

export const changetabs = createAction("CHANGE_TABS@configuration");

export const setDropDownCompletamento = createAction("SET_DROPDOWN_COMPLETAMENTO@configuration");

export const setDropDown = createAction("SET_DROPDOWN@configuration");

export const setDropDown2 = createAction("SET_DROPDOWN2@configuration");

export const setDropDown3 = createAction("SET_DROPDOWN3@configuration");

export const setDropDown4 = createAction("SET_DROPDOWN4@configuration");

export const setDropDown5 = createAction("SET_DROPDOWN5@configuration");

export const setDropDown6 = createAction("SET_DROPDOWN6@configuration");

export const saveFiltriProgetti = createAction("SAVE_FILTRI_PROGETTI@configuration");

export const resetFiltriProgetti = createAction("RESET_FILTRI_PROGETTI@configuration");

export const deleteFiltriProgetti = createAction("DELETE_FILTRI_PROGETTI@configuration");

export const resetIndex = createAction("RESET_INDEX@configuration");

export const resetDropDown = createAction("RESET_DROPDOWN@configuration");

export const aggiornaValoriSlice = createAction("AGGIORNA_VALORI_SLICE@configuration");

export const resetValoriSlice = createAction("RESET_VALORI_SLICE@configuration");

export const savePath = createAction("SAVE_PATH@configuration");

export const saveOptionSelect = createAction("SAVE_OPTION_SELECT@configuration");

export const deleteOptionSelect = createAction("DELETE_OPTION_SELECT@configuration");

export const resetAllOptions = createAction("RESET_ALL_OPTIONS@configuration");

export const changeTabsDettagli = createAction("CHANGE_TABS_DETTAGLI@configuration");

export const resetDatiPost = createAction("RESET_DATI_POST@configuration");

export const resetAttPost = createAction("RESET_ATT_POST@configuration");

export const saveSottoAttivita = createAction("SAVE_SOTTO_ATTIVITA@configuration");

export const setValueCheckOrdineSc = createAction("SET_VALUE_CHECK_ORDINE_SCUOLA@configuration");

export const saveAttivitaFilter = createAction("SAVE_ATTIVITA_FILTER@configuration");

export const deleteValAttivita = createAction("DELETE_VAL_ATTIVITA@configuration");

export const setFilterTipologia = createAction("SET_FILTER_TIPOLOGIA@configuration");

export const setFilterCompletamento = createAction("SET_FILTER_COMPLETAMENTO@configuration");

export const setFilterSort = createAction("SET_FILTER_SORT@configuration");

export const setFilterDataInizio = createAction("SET_FILTER_DATA_INIZIO@configuration");

export const setFilterDataInizioOrdine = createAction("SET_FILTER_DATA_INIZIO_ORDINE@configuration");

export const setFilterImieiP = createAction("SET_FILTER_IMIEIP@configuration");

export const setFilterTitle = createAction("SET_FILTER_TITLE@configuration");

export const setFilterProvincia = createAction("SET_FILTER_PROVINCIA@configuration");

export const setCountPagination = createAction("SET_COUNT_PAGINATION@configuration");

export const setAttivitaSelezionata = createAction("SET_ATTIVITA_SELEZIONATA@configuration");

export const setAttivitaSelezionataTablet = createAction("SET_ATTIVITA_SELEZIONATA_TABLET@configuration");

export const setIdProgetto = createAction("SET_ID_PROGETTO@configuration");

export const setFiltriProgetti = createAction("SET_FILTRI_PROGETTI@configuration");

export const saveattivitapost = createAction("SAVE_ATTIVITA_POST@configuration");

export const setEdit = createAction("SET_EDIT@configuration");

export const titleSet = createAction("TITLE_SET@configuration");

export const setCopiaAtt = createAction("SET_COPIA_ATT@configuration");

export const setCodiceMecc = createAction("SET_CODICE_MECC@configuration");

export const setButtTypeFooterInsProg = createAction("SET_BUTTON_TYPE@configuration");

export const setFilterPubblicato = createAction("SET_FILTER_PUBBLICAZIONE@configuration");

export const setDocumentazioneOptions = createAction("SET_DOCUMENTAZIONE_OPTIONS@configuration");

export const setAzDiffDiss = createAction("SET_AZIONEDIFF_OPTIONS@configuration");

export const resetAttivita = createAction("RESET_ATTIVITA@configuration");

export const deleteOptAzioneDiffusione = createAction("DELETE_OPT_AZZ_DIFF@configuration");

export const deleteOptDocumentazioneProgetto = createAction("DELETE_OPT_DOC_PROGETTO@configuration");

export const saveSceltaAtt = createAction("SAVE_SCELTA_ATT@configuration");

export const setTipoBeneficiari = createAction("SET_TIPO_BENEFICIARIO@configuration");

export const setDescrizioniSottoAttivita = createAction("SET_DESCRIZIONE_SOTTO_ATTIVITA@configuration");

export const saveValueProgetto = createAction("SAVE_VALUE_PROGETTO@configuration");

export const saveValueProgettoInn = createAction("SAVE_VALUE_PROGETTO_INN@configuration");

export const setProgettoCurr = createAction("SET_PROGETTO_CURR@configuration");

export const setProgettoInn = createAction("SET_PROGETTO_INN@configuration");

export const setIndexGestisciProgetti = createAction("SET_INDEX_GESTISCI_PROG@configuration");

export const changeTabsAttività = createAction("CHANGE_TAB_ATTIVITA@configuration");

export const setCheckAttivita = createAction("SET_CHECK_ATTIVITA@configuration");

export const saveCopiaAttivita = createAction("SAVE_COPIA_ATTIVITA@configuration");

export const resetAttivitaId = createAction("RESET_ATTIVITA_ID@configuration");

export const setIndexTabSpostamento = createAction("SET_INDEX_TAB_SPOSTAMENTO@configuration");

export const setThemeColor = createAction("SET_THEME_COLOR@configuration");

export const setIndexTabAttivita = createAction("SET_INDEX_TAB_ATTIVITA@configuration");

export const setIndexTabAttivitaPrecedente = createAction("SET_INDEX_TAB_ATTIVITA_PRECEDENTE@configuration");

export const setmodaleSpostamentoTab = createAction("SET_MODALE__SPOSTAMENTO_TAB_ATTIVITA@configuration");

export const deleteAttivitaTab = createAction("DELETE_ATTIVITA_TAB@configuration");