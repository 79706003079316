import { Grid, IconButton, Link, Typography } from "@mui/material";
import { DeleteElementIcon, ModificaIcon } from "../../styles/icons";
import { getPath, getVal1Slice, getVal2Slice, savePath, setIdProgetto, setProgettoCurr, setProgettoInn } from "../../modules/configuration";
import {
  PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA,
  PATH_INNOVAZIONE_MODIFICA,
  PATH_ISTITUTI_SCOLASTICI,
  PATH_SCHEDA_ISTITUTO,
  PATH_SCHEDA_PROFILO,
  PATH_SCRIVANIA,
} from "../../path";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DettagliLink } from "../pages/dettagliProgetto/DettagliLink";
import { labels } from "../../labels";
import { useState } from "react";
import { Box, alpha } from "@mui/system";
import { EliminaModal } from "../pages/gestisciProgetti/eliminaModal";
import { Modal } from "@mui/base";
import { PROFILO_CODICE_SCUOLA, getInfoProfilo } from "../../modules/profilo";
import { changeDataProgetto, changeDataProgettoCurr, fetchDettagliProgettoFiled, fetchDettagliProgettoSuccess } from "../../modules/datiProgetti";
import { MobileScreenShareOutlined } from "@mui/icons-material";
import { format } from "../pages/gestisciProgetti/TableProgetti";
import PrintIcon from "@mui/icons-material/Print";
import { notificationCodes, setNotification } from "../../modules/notification";
import { GET_DETTAGLI_PROGETTI, GET_SCHEDA_PROFILO, PUT_BLOCCO_MODIFICA } from "../../apiUrl";
import { createRequest } from "../../utility";
import { ModificaModal } from "./ModificaModal";
import { AvvisoElimModal } from "../pages/gestisciProgetti/AvvisoElimModal";
import { fetchSchedaProfiloFailed, fetchSchedaProfiloSuccess } from "../../modules/datiOsservatorio";
import { AvvisoUtDisabilitato } from "../pages/schedaProfilo/AvvisoUtDisabilitato";

const {
  AVVISO_ASSENZA_PROGETTI,
  CAMPO0_TABLE_RESPONSIVE,
  CAMPO1_TABLE_RESPONSIVE,
  CAMPO3_TABLE_RESPONSIVE,
  CAMPO4_TABLE_RESPONSIVE,
  CAMPO5_TABLE_RESPONSIVE,
  DETTAGLI_TABLE_RESPONSIVE,
  MODIFICA_TABLE_RESPONSIVE,
  ELIMINA_TABLE_RESPONSIVE,
  SAGAS_DETTAGLI_PROGETTO_TITLE,
  SAGAS_DETTAGLI_PROGETTO_DESCRIPTION,
  SAGAS_SCHEDA_PROFILO_DESCRIPTION,
  SAGAS_SCHEDA_PROFILO_TITLE,
} = labels;

export const TableGestisciProgResp = (props) => {
  let progetti = props.progetti;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalAvviso, setModalAvviso] = useState(false);
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  const [open, setOpen] = useState(false);
  const [modalModifica, setModalModifica] = useState(false);
  const [avvisoUtente, setAvvisoUtente] = useState(false); //caso in cui l'utente sia disabilitato
  const profilo = useSelector(getInfoProfilo);
  const [nominativo_modifica, setNominativo] = useState("");
  const codiceMeccanografico = profilo ? profilo[PROFILO_CODICE_SCUOLA] : null;
  const path = useSelector(getPath);
  let istituti = props.istituto;

  const edit = (tipologia, id) => {
    dispatch(setIdProgetto(id));
    createRequest(GET_DETTAGLI_PROGETTI.method, GET_DETTAGLI_PROGETTI.baseUrl, GET_DETTAGLI_PROGETTI.apiUrl + "/" + id, {
      responseType: "json",
      decompress: true,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== null) {
            if (response.data.nominativo_modifica !== null) {
              setModalModifica(true);
              setNominativo(response.data.nominativo_modifica);
              dispatch(fetchDettagliProgettoSuccess(response.data));
              const tipoProgetto = response.data.descr_t_progetto;

              if (tipoProgetto != null) {
                if (tipoProgetto === "Innovazione della Scuola") dispatch(setProgettoInn(changeDataProgetto(response.data)));
                else dispatch(setProgettoCurr(changeDataProgettoCurr(response.data)));
              }
            } else {
              dispatch(fetchDettagliProgettoSuccess(response.data));
              const tipoProgetto = response.data.descr_t_progetto;

              if (tipoProgetto != null) {
                if (tipoProgetto === "Innovazione della Scuola") dispatch(setProgettoInn(changeDataProgetto(response.data)));
                else dispatch(setProgettoCurr(changeDataProgettoCurr(response.data)));
              }

              let uuid = response.data.uuid_progetto;
              createRequest(PUT_BLOCCO_MODIFICA.method, PUT_BLOCCO_MODIFICA.baseUrl, PUT_BLOCCO_MODIFICA.apiUrl, null, { uuid_progetto: uuid })
                .then((response) => {
                  if (response.status === 200) {
                    if (tipologia === "Innovazione della Scuola") {
                      navigate(PATH_INNOVAZIONE_MODIFICA);
                    } else {
                      navigate(PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA);
                    }
                  }
                })
                .catch((e) => {
                  dispatch(
                    setNotification({
                      tipo: notificationCodes.ERROR,
                      message: "Errore durante il cambio stato del progetto in modifica",
                      description: "Errore durante il cambio stato del progetto in modifica",
                    })
                  );
                });
            }
          }
        } else throw response.message;
      })
      .catch((e) => {
        console.error(SAGAS_DETTAGLI_PROGETTO_TITLE, ":", e);
        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DETTAGLI_PROGETTO_TITLE, description: SAGAS_DETTAGLI_PROGETTO_DESCRIPTION })); //prettier-ignore
        dispatch(fetchDettagliProgettoFiled());
      });
  };

  const confermaDelete = (id) => {
    createRequest(GET_DETTAGLI_PROGETTI.method, GET_DETTAGLI_PROGETTI.baseUrl, GET_DETTAGLI_PROGETTI.apiUrl + "/" + id, {
      responseType: "json",
      decompress: true,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data != null) {
            if (response.data.nominativo_modifica !== null) {
              if (response.data.nominativo_modifica !== profilo.nome + " " + profilo.cognome) setModalModifica(true);
              else setModalAvviso(true);
              setNominativo(response.data.nominativo_modifica);
            } else {
              setOpen(true);
            }
          }
        } else throw response.message;
      })
      .catch((e) => {
        console.error(SAGAS_DETTAGLI_PROGETTO_TITLE, ":", e);
        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DETTAGLI_PROGETTO_TITLE, description: SAGAS_DETTAGLI_PROGETTO_DESCRIPTION })); //prettier-ignore
        dispatch(fetchDettagliProgettoFiled());
      });
  };
  return (
    <>
      {" "}
      <Modal
        sx={{
          ".MuiModal-backdrop": {
            backgroundColor: alpha("#000000", 0.2),
          },
        }}
        open={avvisoUtente}
        onClose={() => setAvvisoUtente(false)}
      >
        <Box>
          <AvvisoUtDisabilitato setAvvisoUtente={setAvvisoUtente} />
        </Box>
      </Modal>
      <Box className="tableResponsive">
        {progetti.length === 0 ? (
          <Box className="center">
            <Typography sx={{ fontSize: "22px", fontWeight: "Bold" }}>{AVVISO_ASSENZA_PROGETTI}</Typography>
          </Box>
        ) : (
          <Grid container>
            {progetti.slice(valore1slice, valore2slice).map((elem, index) => (
              <Grid item xs={12} sm={12} md={12} xl={12} sx={{ marginTop: "30px", marginBottom: "30px" }} key={index}>
                <Grid container sx={{ display: "block" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    sx={{
                      borderColor: props.borderColor,
                      bgcolor: props.borderColor,
                    }}
                    className="center borderTableResponsive"
                  >
                    <Typography sx={{ color: "white !important" }} className="titleTableResponsive">
                      {elem.titolo_progetto}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={6}
                        sx={{
                          borderColor: props.borderColor,
                        }}
                        className="borderTableResponsive"
                      >
                        <Typography className="campiTableResponsive">{CAMPO0_TABLE_RESPONSIVE}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={6}
                        sx={{
                          borderColor: props.borderColor,
                        }}
                        className="borderTableResponsive"
                      >
                        {" "}
                        <Typography
                          sx={{
                            marginTop: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          {" "}
                          {elem.completamento === null ? "non inserito" : elem.completamento + "%"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={6}
                        sx={{
                          borderColor: props.borderColor,
                        }}
                        className="borderTableResponsive"
                      >
                        <Typography className="campiTableResponsive">{CAMPO1_TABLE_RESPONSIVE}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={6}
                        sx={{
                          borderColor: props.borderColor,
                        }}
                        className="borderTableResponsive"
                      >
                        {" "}
                        <Typography
                          sx={{
                            marginTop: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          {elem.descr_t_progetto}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={6}
                        sx={{
                          borderColor: props.borderColor,
                        }}
                        className="borderTableResponsive"
                      >
                        <Typography className="campiTableResponsive">{CAMPO3_TABLE_RESPONSIVE}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={6}
                        sx={{
                          borderColor: props.borderColor,
                        }}
                        className="borderTableResponsive"
                      >
                        {" "}
                        <Typography
                          sx={{
                            marginTop: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          {format(new Date(elem.data_inserimento), "dd-MM-yyyy")}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                    {props.azioni === false ? null : (
                      <Box>
                        {props.tabIstituti === false ? null : (
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              xl={6}
                              sx={{
                                borderColor: props.borderColor,
                              }}
                              className="borderTableResponsive"
                            >
                              <Typography className="campiTableResponsive">{CAMPO4_TABLE_RESPONSIVE}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              xl={6}
                              sx={{
                                borderColor: props.borderColor,
                              }}
                              className="borderTableResponsive"
                            >
                              <Link
                                onClick={() => {
                                  createRequest(
                                    GET_SCHEDA_PROFILO.method,
                                    GET_SCHEDA_PROFILO.baseUrl,
                                    GET_SCHEDA_PROFILO.apiUrl + "/" + elem.id_referente
                                  )
                                    .then((response) => {
                                      if (response.status === 200) {
                                        dispatch(fetchSchedaProfiloSuccess(response.data));
                                        navigate(PATH_SCHEDA_PROFILO);
                                      } else throw response.message;
                                    })
                                    .catch((e) => {
                                      console.error(SAGAS_SCHEDA_PROFILO_TITLE, ":", e);
                                      console.log(e.response.status === 403, "e.response.status");
                                      if (e.response.status === 403) {
                                        setAvvisoUtente(true);
                                      } else {
                                        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_SCHEDA_PROFILO_DESCRIPTION, description: SAGAS_SCHEDA_PROFILO_DESCRIPTION})); //prettier-ignore
                                        dispatch(fetchSchedaProfiloFailed());
                                      }
                                    });
                                  dispatch(
                                    savePath(
                                      path === PATH_ISTITUTI_SCOLASTICI
                                        ? PATH_ISTITUTI_SCOLASTICI
                                        : istituti === true
                                          ? PATH_SCHEDA_ISTITUTO
                                          : PATH_SCRIVANIA
                                    )
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    marginTop: "10px !important",
                                    marginLeft: "10px !important",
                                    color: "black !important",
                                    textDecorationColor: "black !important",
                                    cursor: "pointer !important",
                                  }}
                                >
                                  {" "}
                                  {(elem.referente_cogn !== null ? elem.referente_cogn : "") +
                                    " " +
                                    (elem.referente_nome !== null ? elem.referente_nome : "")}{" "}
                                </Typography>
                              </Link>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            xl={6}
                            sx={{
                              borderColor: props.borderColor,
                            }}
                            className="borderTableResponsive"
                          >
                            <Typography className="campiTableResponsive">{CAMPO5_TABLE_RESPONSIVE}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            xl={6}
                            sx={{
                              borderColor: props.borderColor,
                            }}
                            className="borderTableResponsive"
                          >
                            {" "}
                            <Typography
                              sx={{
                                marginTop: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              {" "}
                              {elem.descr_stato_progetto}{" "}
                            </Typography>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    sx={{
                      borderColor: props.borderColor,
                    }}
                    className="borderTableResponsive"
                  >
                    <Grid container sx={{ paddingTop: "10px" }}>
                      <Grid item xs={props.azioni === true && props.tabIstituti === false ? 2.4 : 4}>
                        <Grid container>
                          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-20px" }}>
                            <DettagliLink indexTabs={props.indexTabs} path={props.path} id={elem.uuid_progetto} />
                          </Grid>
                          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-20px" }}>
                            <Typography sx={{ "@media (max-width:480px)": { fontSize: "12px !important" } }}> {DETTAGLI_TABLE_RESPONSIVE}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {props.tabIstituti === false ? (
                        elem.codice_scuola === codiceMeccanografico ? (
                          <Grid item xs={props.azioni ? 2.4 : 4}>
                            <Grid container sx={{ marginTop: "-7px" }}>
                              <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                                <IconButton aria-label="modifica" onClick={() => edit(elem.descr_t_progetto, elem.uuid_progetto)}>
                                  <ModificaIcon aria-label="modifica" />
                                </IconButton>
                                <Modal
                                  sx={{
                                    ".MuiModal-backdrop": {
                                      backgroundColor: alpha("#000000", 0.2),
                                    },
                                  }}
                                  open={modalModifica}
                                  onClose={() => setModalModifica(false)}
                                >
                                  <Box>
                                    <ModificaModal
                                      uuid_progetto={elem.uuid_progetto}
                                      utenteInModifica={nominativo_modifica}
                                      color={props.color2}
                                      setOpen={setModalModifica}
                                    />
                                  </Box>
                                </Modal>{" "}
                              </Grid>
                              <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-7px" }}>
                                <Typography sx={{ "@media (max-width:480px)": { fontSize: "12px !important" } }}>
                                  {MODIFICA_TABLE_RESPONSIVE}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null
                      ) : null}

                      {props.tabIstituti === false ? (
                        elem.codice_scuola === codiceMeccanografico ? (
                          <Grid item xs={props.azioni ? 2.4 : 4}>
                            <Grid container sx={{ marginTop: "-7px" }}>
                              <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                                <Box>
                                  <IconButton
                                    aria-label="elimina"
                                    onClick={() => {
                                      dispatch(setIdProgetto(elem.uuid_progetto));
                                      confermaDelete(elem.uuid_progetto);
                                    }}
                                  >
                                    <DeleteElementIcon />
                                  </IconButton>{" "}
                                  <Modal
                                    sx={{
                                      ".MuiModal-backdrop": {
                                        backgroundColor: alpha("#000000", 0.2),
                                      },
                                    }}
                                    open={open}
                                    onClose={() => setOpen(false)}
                                  >
                                    <Box>
                                      <EliminaModal elem={elem} color={props.color2} setOpen={setOpen} />
                                    </Box>
                                  </Modal>{" "}
                                  <Modal
                                    sx={{
                                      ".MuiModal-backdrop": {
                                        backgroundColor: alpha("#000000", 0.2),
                                      },
                                    }}
                                    open={modalAvviso}
                                    onClose={() => setModalAvviso(false)}
                                  >
                                    <Box>
                                      <AvvisoElimModal setOpen={setModalAvviso} />
                                    </Box>
                                  </Modal>{" "}
                                </Box>
                              </Grid>{" "}
                              <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-7px" }}>
                                <Typography sx={{ "@media (max-width:480px)": { fontSize: "12px !important" } }}>
                                  {ELIMINA_TABLE_RESPONSIVE}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null
                      ) : null}

                      {props.azioni ? (
                        <Grid item xs={props.tabIstituti ? 4 : 2.4}>
                          <Grid
                            container
                            className="center"
                            sx={{
                              a: {
                                textAlign: "center",
                                color: "black",
                                textDecoration: "none",
                              },
                            }}
                          >
                            <Link
                              aria-label="Stampa"
                              onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=true", "_blank")}
                            >
                              <PrintIcon sx={{ color: "black" }} />
                              <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                                <Typography sx={{ "@media (max-width:480px)": { fontSize: "12px !important" } }}>Stampa</Typography>
                              </Grid>{" "}
                            </Link>
                          </Grid>
                        </Grid>
                      ) : null}

                      {props.azioni ? (
                        <Grid item xs={props.tabIstituti ? 4 : 2.4}>
                          <Grid
                            container
                            className="center"
                            sx={{
                              a: {
                                textAlign: "center",
                                color: "black",
                                textDecoration: "none",
                              },
                            }}
                          >
                            <Link
                              aria-label="Condividi"
                              onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=false", "_blank")}
                            >
                              <MobileScreenShareOutlined sx={{ color: "black" }} />
                              <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                                <Typography sx={{ "@media (max-width:480px)": { fontSize: "12px !important" } }}>Condividi</Typography>
                              </Grid>
                            </Link>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </>
  );
};
