import { Grid, Box, Typography, Card, IconButton, Modal, List, FormHelperText, Button, CardActionArea } from "@mui/material";
import { ControlPoint as ControlPointIcon } from "@mui/icons-material";
import { WarningAmber as WarningAmberIcon } from "@mui/icons-material";
import {
  checkIndex,
  deleteval,
  getOptionSelect,
  deleteValAttivita,
  getSottoAttivitaId,
  getButtonType,
  resetFiltriProgetti,
  resetIndex,
  getDatiProgetto,
  saveValueProgetto,
  getProgressAttivita,
  resetDatiPost,
  saveProgressCont,
  getIdProgetto,
  setCopiaAtt,
  getIndexTabSpostamento,
  changetabs,
} from "../../../../modules/configuration";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getDisciplineCoinvolte } from "../../../../modules/datiOsservatorio";
import { FooterInserisciProgetti } from "../FooterInserisciProgetti";
import { PATH_HOME } from "../../../../path";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getInfoProfilo } from "../../../../modules/profilo";
import { SelectContesto } from "./SelectContesto";
import { OptionsCheckModale } from "../../../shared/OptionsCheckModale";
import { BinocoloIcon } from "../../../../styles/icons";
import { labels } from "../../../../labels";
import { ModaleAttivita } from "../pageAttivita/ModaleAttivita";
import { getAttivitaCurricolari, getCommonStore } from "../../../../modules/common";
import { Input, Select } from "../../../shared";
import { valProgrCurr } from "../progressFunction";
import { POST_CONTESTO, PUT_SBLOCCO_MODIFICA } from "../../../../apiUrl";
import { notificationCodes, setNotification } from "../../../../modules/notification";
import { createRequest } from "../../../../utility";
import { ModaleSpostamentoTab } from "../ModaleSpostamentoTab";

const {
  REQUIRED_FIELD,
  TEMPI_SPAZI_COMPETENZE,
  PLACEHOLDER_ATTIVITA,
  ATTIVITA,
  DESCRIZIONE_ACCOMPAGNAMENTO,
  PLACEHOLDER_DESCRIVI,
  INTERDISCIPLINARIETA,
  PLACEHOLDER_INTERDISCIPLINARIETA,
  ATTIVITA_CLIL,
  PLACEHOLDER_ATTIVITA_CLIL,
  CONTESTO_ACCOMPAGNAMENTO,
  PLACEHOLDER_CONTESTO_ACCOMPAGNAMENTO,
} = labels;

export const Contesto = (props) => {
  const [open, setOpen] = useState(false);
  const allOptions = useSelector(getCommonStore);

  let allOptionsFiltered = {};
  for (const [key, value] of Object.entries(allOptions)) {
    allOptionsFiltered[key] = value.data !== null ? value.data : [];
  }
  const idProg = useSelector(getIdProgetto);
  const attivitaCurr = useSelector(getAttivitaCurricolari);
  const attivitaC = attivitaCurr;
  const dispatch = useDispatch();
  const index = useSelector(getIndexTabSpostamento);
  const disciplineCoinvolte = useSelector(getDisciplineCoinvolte);
  const optionsSelected = useSelector(getOptionSelect);
  const sottoAttivitaId = useSelector(getSottoAttivitaId);
  let sottAtt = new Map(JSON.parse(sottoAttivitaId));
  const buttonType = useSelector(getButtonType);
  const navigate = useNavigate();
  const idProgNuovo = useSelector(getIdProgetto);
  const profilo = useSelector(getInfoProfilo);
  const datiProg = useSelector(getDatiProgetto);
  const attivitaProgress = useSelector(getProgressAttivita);
  const datiProgetto = datiProg.contesto;
  const datiProgettoAtt = datiProgetto.attivita;
  const codiceScuola = profilo.codiceScuola;
  const tipo = "progettoCurricolare";
  const section = "contesto";
  let progetto = props.progetto;
  let attivitaInserite = [];

  const checkContesto = (attivitaC) => {
    const checkC = attivitaC.descr_sottotab === "CONTESTO";
    return checkC;
  };

  let attivitaContesto = attivitaC.filter(checkContesto);
  let checkAttObb = false;
  let checkAttObbNum = 0;
  let checkAtt = 0;

  for (let [key] of sottAtt.entries()) {
    // eslint-disable-next-line
    attivitaContesto.map((opt) =>
      opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkAttObbNum = checkAttObbNum + 1) : null
    );
    // eslint-disable-next-line
    attivitaContesto.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkAtt = checkAtt + 1) : null));

    if (checkAttObbNum === 3 && checkAtt < 6) {
      checkAttObb = true;
    } else {
      if (checkAttObbNum === 3 && checkAtt == 6) {
        checkAttObb = true;
      }
    }
  }

  // eslint-disable-next-line
  datiProgettoAtt.map((e) => {
    attivitaInserite.push(e.id_campo);
    attivitaInserite = attivitaInserite.filter(function (e, el) {
      return attivitaInserite.indexOf(e) === el;
    });
  });

  let progrCont = attivitaInserite.length * 2;
  let progress = Math.floor(valProgrCurr(datiProg) + attivitaProgress + progrCont);

  useEffect(() => {
    dispatch(saveProgressCont(Math.floor(progrCont)));
    // eslint-disable-next-line
  }, [progrCont]);

  progress = progress > 100 ? 100 : progress;

  // controllo sui campi obbligatori
  const validationSchema = yup.object({
    interdisciplinarieta: yup.string().required(),
    descr_contesto_accompagnamento: yup.string().required(),
    attivita: yup
      .array()
      .required()
      .test({ test: (value) => (checkAttObb === true ? true : false) }),
  });

  //prendo dati iniziali inseriti precedentemente se mi sposto di tab o copia salvata da progetto che va modificato
  const formik = useFormik({
    initialValues: {
      discipline: optionsSelected[0] !== undefined ? optionsSelected[0] : optionsSelected,
      interdisciplinarieta:
        datiProgetto["interdisciplinarieta"] === "Si"
          ? 1
          : datiProgetto["interdisciplinarieta"] === "No"
            ? 2
            : datiProgetto["interdisciplinarieta"] === 1
              ? 1
              : datiProgetto["interdisciplinarieta"] === 2
                ? 2
                : null,
      descr_attivita_clil: datiProgetto["descr_attivita_clil"],
      descr_contesto_accompagnamento: datiProgetto["descr_contesto_accompagnamento"],
      nome_mentor: datiProgetto["nome_mentor"],
      attivita: datiProgetto["attivita"],
    },
    validationSchema: validationSchema,

    onSubmit: () => {
      if (checkAttObb === true) {
        createRequest(POST_CONTESTO.method, POST_CONTESTO.baseUrl, POST_CONTESTO.apiUrl, null, {
          codice_scuola: codiceScuola,
          uuid_progetto: progetto.data === undefined ? progetto.uuid_progetto : idProgNuovo,
          interdisciplinarieta: formik.values.interdisciplinarieta === 1 ? "Si" : "No",
          id_discipline: datiProgetto["discipline"],
          id_attivita_clil: formik.values.descr_attivita_clil,
          id_contesto_accompagnamento: formik.values.descr_contesto_accompagnamento,
          nome_mentor: formik.values.nome_mentor,
          id_attivita: datiProgetto["attivita"],
          completamento: progress,
        })
          .then((response) => {
            if (response.status === 200) {
              //verifico se bottone salva ed esci o salva e avanti
              if (buttonType === "AVANTI") {
                //verifico in che tab mi trovo
                if (index !== null) {
                  let versione = true;
                  dispatch(changetabs({ index, versione }));
                } else {
                  dispatch(checkIndex("4"));
                }
              } else {
                createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
                  uuid_progetto: progetto.data === undefined ? progetto.uuid_progetto : idProgNuovo,
                })
                  .then((response) => {
                    if (response.status === 200) {
                      //caso bottone salva ed esci
                      navigate(PATH_HOME);
                      dispatch(resetFiltriProgetti());
                      dispatch(resetIndex());
                    }
                  })
                  .catch((e) => {
                    dispatch(
                      setNotification({
                        tipo: notificationCodes.ERROR,
                        message: "Errore durante il cambio stato del progetto in modifica",
                        description: "Errore durante il cambio stato del progetto in modifica",
                      })
                    );
                  });
              }
            }
          })
          .catch((e) => {
            dispatch(
              setNotification({
                tipo: notificationCodes.ERROR,
                message: "Errore durante il salvataggio dei dati della sezione contesto ",
                description: "Si è verificato un errore imprevisto nel salvataggio dei dati della sezione contesto",
              })
            );
          });
      }
      dispatch(resetDatiPost());
    },
  });
  // creo array di componenti da renderizzare per visualizzare le sottoAttivita
  const checkSA = (sottAtt) => {
    let array = [];
    let i = -1;
    for (let [key, value] of sottAtt.entries()) {
      i = i + 1;
      attivitaContesto.map(
        // eslint-disable-next-line
        (opt, ind) =>
          opt.finalita + " " + opt.descr_campo === key &&
          array.push(
            <Box>
              <Box sx={{ marginLeft: "10px" }}>
                <OptionsCheckModale
                  color={"#FABE10"}
                  border={"none"}
                  width={"98%"}
                  elemento={key}
                  index={i}
                  formik={formik}
                  click={(e) => {
                    let value = key;
                    let tipo = "progettoCurricolare";
                    let section = "contesto";
                    dispatch(deleteValAttivita({ value, section, tipo }));
                  }}
                />
                {typeof value !== "string" ? (
                  value.map((elemento, ind) => (
                    <OptionsCheckModale
                      color={"#FFFFFF"}
                      border={"solid 3px #FABE10"}
                      width={"90%"}
                      elemento={elemento}
                      index={ind}
                      formik={formik}
                      click={(e) => {
                        let value = elemento;
                        let tipo = "progettoCurricolare";
                        let section = "contesto";
                        dispatch(deleteval({ value, section, tipo }));
                      }}
                    />
                  ))
                ) : (
                  <OptionsCheckModale
                    color={"#FFFFFF"}
                    border={"solid 3px #FABE10"}
                    width={"90%"}
                    elemento={value}
                    index={0}
                    formik={formik}
                    click={(e) => {
                      dispatch(deleteval(value));
                    }}
                  />
                )}
              </Box>
            </Box>
          )
      );
    }
    return <>{array}</>;
  };

  const err = () => {
    if (formik.touched["attivita"] && Boolean(formik.errors["attivita"]) && checkAttObb === false) return "solid";
  };

  let interdisciplinarieta = [
    { id: 1, descrizione: "Si" },
    { id: 2, descrizione: "No" },
  ];

  const onDatiChange = (modifiche) => {
    // eslint-disable-next-line
    modifiche.map((modifica) => {
      const { fieldName, fieldValue } = modifica;
      formik.setFieldValue(fieldName, fieldValue);
      dispatch(saveValueProgetto(modifica));
    });
  };

  return (
    <form onSubmit={formik.handleSubmit} id="myform">
      <ModaleSpostamentoTab
        setTabSpostamentoAttivita={null}
        stateModal={props.modal}
        setModalSpostamentoTab={props.setModalSpostamentoTab}
        onSave={formik.handleSubmit}
        uuid={progetto.data === undefined ? progetto.uuid_progetto : idProg}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={12} xl={4}>
          <SelectContesto formik={formik} name={"discipline"} optionsSelected={optionsSelected} optionsCheckList={disciplineCoinvolte.data} />
        </Grid>
        <Grid item xs={12} md={4} sm={12} xl={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={12} xl={12}>
              <Select
                label={INTERDISCIPLINARIETA + "*"}
                placeholder={PLACEHOLDER_INTERDISCIPLINARIETA}
                options={interdisciplinarieta}
                value={interdisciplinarieta.find((e) => e.id === formik.values.interdisciplinarieta)?.id}
                onChange={(e, v) => {
                  const { value: id } = v.props;
                  onDatiChange([{ fieldSection: "contesto", fieldName: "interdisciplinarieta", fieldValue: id }]);
                }}
                {...(formik.touched["interdisciplinarieta"] &&
                  Boolean(formik.errors["interdisciplinarieta"]) && {
                    error: true,
                    errorText: REQUIRED_FIELD,
                  })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12} xl={12}>
              <Select
                label={CONTESTO_ACCOMPAGNAMENTO + "*"}
                placeholder={PLACEHOLDER_CONTESTO_ACCOMPAGNAMENTO}
                options={allOptionsFiltered.contestiAccompagnamento}
                value={allOptionsFiltered.contestiAccompagnamento.find((e) => e.id === formik.values.descr_contesto_accompagnamento)?.id}
                onChange={(e, v) => {
                  const { value: id } = v.props;
                  onDatiChange([{ fieldSection: "contesto", fieldName: "descr_contesto_accompagnamento", fieldValue: id }]);
                }}
                {...(formik.touched["descr_contesto_accompagnamento"] &&
                  Boolean(formik.errors["descr_contesto_accompagnamento"]) && {
                    error: true,
                    errorText: REQUIRED_FIELD,
                  })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12} xl={12}>
              <Select
                label={ATTIVITA_CLIL}
                placeholder={PLACEHOLDER_ATTIVITA_CLIL}
                options={allOptionsFiltered.attivitaClil}
                value={allOptionsFiltered.attivitaClil.find((e) => e.id === formik.values.descr_attivita_clil)?.id}
                onChange={(e, v) => {
                  const { value: id } = v.props;
                  onDatiChange([{ fieldSection: "contesto", fieldName: "descr_attivita_clil", fieldValue: id }]);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12} xl={12}>
              <Input
                label={DESCRIZIONE_ACCOMPAGNAMENTO}
                rows={7}
                numeroCaratteri={400}
                infoNumeroCaratteri={true}
                placeholder={PLACEHOLDER_DESCRIVI}
                value={formik.values.nome_mentor}
                multiline={true}
                onChange={(e) => {
                  onDatiChange([{ fieldSection: "contesto", fieldName: "nome_mentor", fieldValue: e.target.value }]);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} sm={12} xl={4}>
          <Grid container>
            <Grid container>
              <Grid item xs={10} md={10} xl={8}>
                <Typography
                  sx={{
                    fontSize: "16px !important",
                    fontWeight: "Bold",
                  }}
                >
                  {TEMPI_SPAZI_COMPETENZE + "*"}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} xl={3} sx={{ marginTop: "-7px" }}>
                {checkAttObb === true ? (
                  checkAtt < 6 ? (
                    <BinocoloIcon />
                  ) : null
                ) : (
                  <WarningAmberIcon
                    sx={{
                      color: formik.touched["attivita"] && Boolean(formik.errors["attivita"]) ? "#d32f2f" : "black",
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <CardActionArea
              onClick={() => {
                setOpen(true);
                dispatch(setCopiaAtt({ tipo, section }));
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  borderColor: "#d32f2f",
                  borderWidth: "1px",
                  borderStyle: err(),
                  height: "39px",
                  boxShadow: "0px 3px 6px #000000C7",
                  opacity: "1",
                }}
              >
                <Grid container spacing={2} sx={{ paddingLeft: "5px", paddingTop: "5px" }}>
                  <Grid item xs={10} sm={11} md={10} xl={10.5}>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        color: "#595959",
                        fontSize: "16px !important",
                        opacity: 1,
                      }}
                    >
                      {PLACEHOLDER_ATTIVITA}
                    </Typography>
                  </Grid>{" "}
                  <Grid item xs={2} sm={1} md={1} xl={1.5}>
                    <IconButton
                      aria-label="Scegli attività"
                      disableRipple
                      sx={{
                        padding: "0px",
                        color: "black",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => {
                        setOpen(true);
                        dispatch(setCopiaAtt({ tipo, section }));
                      }}
                    >
                      <ControlPointIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>{" "}
            </CardActionArea>
            <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box>
                <ModaleAttivita
                  progetto={progetto}
                  titolo={ATTIVITA}
                  formik={formik}
                  attivita={attivitaContesto}
                  modal={setOpen}
                  textField={false}
                  section={"contesto"}
                  tipo={"progettoCurricolare"}
                />
              </Box>
            </Modal>
            {formik.touched["attivita"] && Boolean(formik.errors["attivita"]) && !checkAttObb && REQUIRED_FIELD && (
              <FormHelperText sx={{ color: "#d32f2f", marginLeft: "15px" }}>{REQUIRED_FIELD}</FormHelperText>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <List
              sx={{
                overflow: "auto",
                maxWidth: "800px",
                maxHeight: "300px",
                backgroundColor: "#F0F0F0",
                borderRadius: "20px",
                "@media (max-width:480px)": {
                  width: "270px",
                  height: "400px",
                },
              }}
            >
              {" "}
              {checkSA(sottAtt)}
            </List>
          </Box>
        </Grid>
      </Grid>{" "}
      <FooterInserisciProgetti onSave={formik.handleSubmit} percentuale={Math.floor(progress)} />
      <Button type="submit" sx={{ display: "none" }}>
        Salva
      </Button>
    </form>
  );
};
