import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Modal,
  Box,
  alpha,
  Link,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { KeyboardArrowDown as KeyboardArrowDownIcon, MobileScreenShareOutlined } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import { Tipologia, Titolo, DataInserimento, Referente, Pubblicazione } from "../../shared";
import {
  setDropDown,
  setDropDown2,
  setDropDown3,
  setDropDown4,
  setDropDown5,
  getDropDown,
  getDropDown2,
  getDropDown3,
  getDropDown4,
  getDropDown5,
  setIdProgetto,
  getPath,
  getCountPagination,
  aggiornaValoriSlice,
  setCountPagination,
  getDropDownCompletameto,
  setDropDownCompletamento,
  setIndexGestisciProgetti,
  setProgettoInn,
  setProgettoCurr,
  savePath,
} from "../../../modules/configuration";
import { DettagliLink } from "../dettagliProgetto/DettagliLink";
import { Progress } from "../../shared/Progress";
import {
  PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA,
  PATH_INNOVAZIONE_MODIFICA,
  PATH_ISTITUTI_SCOLASTICI,
  PATH_SCHEDA_ISTITUTO,
  PATH_SCHEDA_PROFILO,
  PATH_SCRIVANIA,
} from "../../../path";
import { useNavigate } from "react-router-dom";
import { EliminaModal } from "./eliminaModal";
import { useEffect, useState } from "react";
import { PaginationShared } from "../../shared/Pagination";
import { Completamento } from "../../shared/componentiDropDown/Completamento";
import { labels } from "../../../labels";
import { DeleteElementIcon, ModificaIcon } from "../../../styles/icons";
import { PROFILO_CODICE_SCUOLA, PROFILO_RUOLO, RUOLO_GES, getInfoProfilo } from "../../../modules/profilo";
import { GET_DETTAGLI_PROGETTI, GET_SCHEDA_PROFILO, PUT_BLOCCO_MODIFICA } from "../../../apiUrl";
import { createRequest } from "../../../utility";
import { ModificaModal } from "../../shared/ModificaModal";
import { notificationCodes, setNotification } from "../../../modules/notification";
import { changeDataProgetto, changeDataProgettoCurr, fetchDettagliProgettoFiled, fetchDettagliProgettoSuccess } from "../../../modules/datiProgetti";
import { AvvisoElimModal } from "./AvvisoElimModal";
import { fetchSchedaProfiloFailed, fetchSchedaProfiloSuccess } from "../../../modules/datiOsservatorio";
import { AvvisoUtDisabilitato } from "../schedaProfilo/AvvisoUtDisabilitato";
const {
  COMPLETAMENTO,
  TIPOLOGIA_PROGETTO,
  TITOLO_PROGETTO,
  DATA_INSERIMENTO,
  REFERENTE,
  PUBBLICAZIONE,
  DETTAGLI,
  MODIFICA,
  ELIMINA,
  AZIONI,
  AVVISO_ASSENZA_PROGETTI,
  BUTTON_CONDIVIDI,
  BUTTON_SCARICA,
  SAGAS_DETTAGLI_PROGETTO_TITLE,
  SAGAS_DETTAGLI_PROGETTO_DESCRIPTION,
  SAGAS_SCHEDA_PROFILO_TITLE,
  SAGAS_SCHEDA_PROFILO_DESCRIPTION,
} = labels;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: "10px",
  },
}));

export const format = function date2str(x, y) {
  var z = {
    M: x.getMonth() + 1,
    d: x.getDate(),
    h: x.getHours(),
    m: x.getMinutes(),
    s: x.getSeconds(),
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
    return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
  });

  return y.replace(/(y+)/g, function (v) {
    return x.getFullYear().toString().slice(-v.length);
  });
};

export const TableProgetti = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dropdownCompletamento = useSelector(getDropDownCompletameto);
  const dropdown = useSelector(getDropDown);
  const dropdown2 = useSelector(getDropDown2);
  const dropdown3 = useSelector(getDropDown3);
  const dropdown4 = useSelector(getDropDown4);
  const dropdown5 = useSelector(getDropDown5);
  const [modalModifica, setModalModifica] = useState(false); //caso in cui un altro utente abbia una sessione sul progetto aperta
  const [modalAvviso, setModalAvviso] = useState(false); //caso in cui l'utente abbia un'altra sessione aperta
  const [avvisoUtente, setAvvisoUtente] = useState(false); //caso in cui l'utente sia disabilitato
  const [nominativo_modifica, setNominativo] = useState(""); //stato per nominativo di chi ha la sessione aperta sul progetto
  let progetti = props.progetti;
  const path = useSelector(getPath);
  let istituti = props.istituto;
  let rows;
  const [page, setPage] = useState(1);
  const countPagination = useSelector(getCountPagination);
  const profilo = useSelector(getInfoProfilo);
  const codiceMeccanografico = profilo ? profilo[PROFILO_CODICE_SCUOLA] : null;
  let ruolo = profilo ? profilo[PROFILO_RUOLO] : null;

  function handlechange(e, value) {
    dispatch(aggiornaValoriSlice(value));
    setPage(value);
  }

  //prima di eliminare un progetto verifico che non sia aperto in un'altra sessione
  const confermaDelete = (id) => {
    createRequest(GET_DETTAGLI_PROGETTI.method, GET_DETTAGLI_PROGETTI.baseUrl, GET_DETTAGLI_PROGETTI.apiUrl + "/" + id, {
      responseType: "json",
      decompress: true,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data != null) {
            if (response.data.nominativo_modifica !== null) {
              if (response.data.nominativo_modifica !== profilo.nome + " " + profilo.cognome) setModalModifica(true);
              else setModalAvviso(true);
              setNominativo(response.data.nominativo_modifica);
            } else {
              setOpen(true);
            }
          }
        } else throw response.message;
      })
      .catch((e) => {
        console.error(SAGAS_DETTAGLI_PROGETTO_TITLE, ":", e);
        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DETTAGLI_PROGETTO_TITLE, description: SAGAS_DETTAGLI_PROGETTO_DESCRIPTION })); //prettier-ignore
        dispatch(fetchDettagliProgettoFiled());
      });
  };

  //prima di aprire un progetto in modifica verifico che non sia aperto in un'altra sessione
  const edit = (tipologia, id) => {
    dispatch(setIdProgetto(id));
    createRequest(GET_DETTAGLI_PROGETTI.method, GET_DETTAGLI_PROGETTI.baseUrl, GET_DETTAGLI_PROGETTI.apiUrl + "/" + id, {
      responseType: "json",
      decompress: true,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data != null) {
            if (response.data.nominativo_modifica != null) {
              setModalModifica(true);
              setNominativo(response.data.nominativo_modifica);
              dispatch(fetchDettagliProgettoSuccess(response.data));
              const tipoProgetto = response.data.descr_t_progetto;

              if (tipoProgetto != null) {
                if (tipoProgetto === "Innovazione della Scuola") dispatch(setProgettoInn(changeDataProgetto(response.data)));
                else dispatch(setProgettoCurr(changeDataProgettoCurr(response.data)));
              }
            } else {
              dispatch(fetchDettagliProgettoSuccess(response.data));
              const tipoProgetto = response.data.descr_t_progetto;

              if (tipoProgetto != null) {
                if (tipoProgetto === "Innovazione della Scuola") dispatch(setProgettoInn(changeDataProgetto(response.data)));
                else dispatch(setProgettoCurr(changeDataProgettoCurr(response.data)));
              }
              let uuid = response.data.uuid_progetto;
              createRequest(PUT_BLOCCO_MODIFICA.method, PUT_BLOCCO_MODIFICA.baseUrl, PUT_BLOCCO_MODIFICA.apiUrl, null, { uuid_progetto: uuid })
                .then((response) => {
                  if (response.status === 200) {
                    dispatch(setIdProgetto(uuid));
                    if (tipologia === "Innovazione della Scuola") {
                      navigate(PATH_INNOVAZIONE_MODIFICA);
                    } else {
                      navigate(PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA);
                    }
                  }
                })
                .catch((e) => {
                  dispatch(
                    setNotification({
                      tipo: notificationCodes.ERROR,
                      message: "Errore durante il cambio stato del progetto in modifica",
                      description: "Errore durante il cambio stato del progetto in modifica",
                    })
                  );
                });
            }
          }
        } else throw response.message;
      })
      .catch((e) => {
        console.error(SAGAS_DETTAGLI_PROGETTO_TITLE, ":", e);
        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DETTAGLI_PROGETTO_TITLE, description: SAGAS_DETTAGLI_PROGETTO_DESCRIPTION })); //prettier-ignore
        dispatch(fetchDettagliProgettoFiled());
      });
  };

  //in base al numero di progetti stabilisco numero pagine
  useEffect(() => {
    //set count pagination
    dispatch(setCountPagination(Math.ceil(progetti.length / 5)));
    // eslint-disable-next-line
  }, [progetti]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: props.color2,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let createData;
  if (props.azioni === true && props.tabIstituti === true) {
    if (ruolo === RUOLO_GES) {
      createData = (completamento, tipologia, titolo, dataInserimento, referente, pubblicazione, dettagli, azioni) => {
        return {
          completamento,
          tipologia,
          titolo,
          dataInserimento,
          referente,
          pubblicazione,
          dettagli,
          azioni,
        };
      };
    } else {
      createData = (completamento, tipologia, titolo, dataInserimento, referente, pubblicazione, dettagli, modifica, elimina, azioni) => {
        return { completamento, tipologia, titolo, dataInserimento, referente, pubblicazione, dettagli, modifica, elimina, azioni };
      };
    }
  } else {
    if (props.azioni === true) {
      createData = (completamento, tipologia, titolo, dataInserimento, pubblicazione, dettagli, modifica, elimina, azioni) => {
        return {
          completamento,
          tipologia,
          titolo,
          dataInserimento,
          pubblicazione,
          dettagli,
          modifica,
          elimina,
          azioni,
        };
      };
    } else {
      createData = (completamento, tipologia, titolo, dataInserimento, dettagli, modifica, elimina) => {
        return {
          completamento,
          tipologia,
          titolo,
          dataInserimento,
          dettagli,
          modifica,
          elimina,
        };
      };
    }
  }
  rows = progetti.map((elem, index) =>
    props.azioni
      ? props.tabIstituti
        ? ruolo === RUOLO_GES
          ? createData(
              <Progress progress={elem.completamento} />,
              elem.descr_t_progetto,
              elem.titolo_progetto,
              format(new Date(elem.data_inserimento), "dd-MM-yyyy"),

              <Link
                sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer !important" }}
                onClick={() => {
                  createRequest(GET_SCHEDA_PROFILO.method, GET_SCHEDA_PROFILO.baseUrl, GET_SCHEDA_PROFILO.apiUrl + "/" + elem.id_referente)
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(fetchSchedaProfiloSuccess(response.data));
                        navigate(PATH_SCHEDA_PROFILO);
                      } else throw response.message;
                    })
                    .catch((e) => {
                      console.error(SAGAS_SCHEDA_PROFILO_TITLE, ":", e);
                      console.log(e.response.status === 403, "e.response.status");
                      if (e.response.status === 403) {
                        setAvvisoUtente(true);
                      } else {
                        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_SCHEDA_PROFILO_DESCRIPTION, description: SAGAS_SCHEDA_PROFILO_DESCRIPTION})); //prettier-ignore
                        dispatch(fetchSchedaProfiloFailed());
                      }
                    });

                  dispatch(
                    savePath(path === PATH_ISTITUTI_SCOLASTICI ? PATH_ISTITUTI_SCOLASTICI : istituti === true ? PATH_SCHEDA_ISTITUTO : PATH_SCRIVANIA)
                  );
                }}
              >
                {(elem.referente_cogn !== null ? elem.referente_cogn : "") + " " + (elem.referente_nome !== null ? elem.referente_nome : "")}
              </Link>,
              elem.descr_stato_progetto,
              <DettagliLink
                indexTabs={props.indexTabs}
                path={path === PATH_ISTITUTI_SCOLASTICI ? PATH_ISTITUTI_SCOLASTICI : istituti === true ? PATH_SCHEDA_ISTITUTO : PATH_SCRIVANIA}
                id={elem.uuid_progetto}
              />,
              <Grid container>
                <Grid item xs={6}>
                  <Link
                    sx={{
                      color: "black",
                      cursor: "pointer",
                      textDecoration: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=true", "_blank")}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <PrintIcon sx={{ color: "black" }} />
                      </Grid>

                      <Grid item xs={12}>
                        {BUTTON_SCARICA}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    sx={{
                      color: "black",
                      cursor: "pointer",
                      textDecoration: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=false", "_blank")}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <MobileScreenShareOutlined sx={{ color: "black" }} />
                      </Grid>
                      <Grid item xs={12}>
                        {BUTTON_CONDIVIDI}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            )
          : createData(
              <Progress progress={elem.completamento} />,
              elem.descr_t_progetto,
              elem.titolo_progetto,
              format(new Date(elem.data_inserimento), "dd-MM-yyyy"),
              <Link
                sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer !important" }}
                onClick={() => {
                  createRequest(GET_SCHEDA_PROFILO.method, GET_SCHEDA_PROFILO.baseUrl, GET_SCHEDA_PROFILO.apiUrl + "/" + elem.id_referente)
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(fetchSchedaProfiloSuccess(response.data));
                        navigate(PATH_SCHEDA_PROFILO);
                      } else throw response.message;
                    })
                    .catch((e) => {
                      console.error(SAGAS_SCHEDA_PROFILO_TITLE, ":", e);
                      console.log(e.response.status === 403, "e.response.status");
                      if (e.response.status === 403) {
                        setAvvisoUtente(true);
                      } else {
                        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_SCHEDA_PROFILO_DESCRIPTION, description: SAGAS_SCHEDA_PROFILO_DESCRIPTION})); //prettier-ignore
                        dispatch(fetchSchedaProfiloFailed());
                      }
                    });
                  dispatch(
                    savePath(path === PATH_ISTITUTI_SCOLASTICI ? PATH_ISTITUTI_SCOLASTICI : istituti === true ? PATH_SCHEDA_ISTITUTO : PATH_SCRIVANIA)
                  );
                }}
              >
                {(elem.referente_cogn !== null ? elem.referente_cogn : "") + " " + (elem.referente_nome !== null ? elem.referente_nome : "")}
              </Link>,
              elem.descr_stato_progetto,
              <DettagliLink
                indexTabs={props.indexTabs}
                path={path === PATH_ISTITUTI_SCOLASTICI ? PATH_ISTITUTI_SCOLASTICI : istituti === true ? PATH_SCHEDA_ISTITUTO : PATH_SCRIVANIA}
                id={elem.uuid_progetto}
              />,
              elem.codice_scuola === codiceMeccanografico ? (
                <Box>
                  <IconButton
                    aria-label="modifica"
                    onClick={() => {
                      dispatch(setIndexGestisciProgetti(props.indexTabs));
                      edit(elem.descr_t_progetto, elem.uuid_progetto);
                    }}
                  >
                    <ModificaIcon aria-label="modifica" />
                  </IconButton>
                  <Modal
                    sx={{
                      ".MuiModal-backdrop": {
                        backgroundColor: alpha("#000000", 0.2),
                      },
                    }}
                    open={modalModifica}
                    onClose={() => setModalModifica(false)}
                  >
                    <Box>
                      <ModificaModal
                        uuid_progetto={elem.uuid_progetto}
                        utenteInModifica={nominativo_modifica}
                        color={props.color2}
                        setOpen={setModalModifica}
                      />
                    </Box>
                  </Modal>
                </Box>
              ) : null,
              elem.codice_scuola === codiceMeccanografico ? (
                <Box>
                  <IconButton
                    aria-label="elimina"
                    onClick={() => {
                      dispatch(setIdProgetto(elem.uuid_progetto));
                      confermaDelete(elem.uuid_progetto);
                    }}
                  >
                    <DeleteElementIcon />
                  </IconButton>{" "}
                  <Modal
                    sx={{
                      ".MuiModal-backdrop": {
                        backgroundColor: alpha("#000000", 0.2),
                      },
                    }}
                    open={open}
                    onClose={() => setOpen(false)}
                  >
                    <Box>
                      <EliminaModal color={props.color2} setOpen={setOpen} />
                    </Box>
                  </Modal>{" "}
                  <Modal
                    sx={{
                      ".MuiModal-backdrop": {
                        backgroundColor: alpha("#000000", 0.2),
                      },
                    }}
                    open={modalAvviso}
                    onClose={() => setModalAvviso(false)}
                  >
                    <Box>
                      <AvvisoElimModal setOpen={setModalAvviso} />
                    </Box>
                  </Modal>{" "}
                </Box>
              ) : null,
              <Grid container>
                <Grid item xs={6}>
                  <Link
                    sx={{
                      color: "black",
                      cursor: "pointer",
                      textDecoration: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=true", "_blank")}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <PrintIcon sx={{ color: "black" }} />
                      </Grid>

                      <Grid item xs={12}>
                        {BUTTON_SCARICA}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    sx={{
                      color: "black",
                      cursor: "pointer",
                      textDecoration: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=false", "_blank")}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <MobileScreenShareOutlined sx={{ color: "black" }} />
                      </Grid>
                      <Grid item xs={12}>
                        {BUTTON_CONDIVIDI}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            )
        : createData(
            <Progress progress={elem.completamento} />,
            elem.descr_t_progetto,
            elem.titolo_progetto,
            format(new Date(elem.data_inserimento), "dd-MM-yyyy"),
            elem.descr_stato_progetto,
            <DettagliLink indexTabs={props.indexTabs} path={istituti === true ? PATH_SCHEDA_ISTITUTO : PATH_SCRIVANIA} id={elem.uuid_progetto} />,
            elem.codice_scuola === codiceMeccanografico ? (
              <Box>
                <IconButton
                  aria-label="modifica"
                  onClick={() => {
                    dispatch(setIndexGestisciProgetti(props.indexTabs));
                    edit(elem.descr_t_progetto, elem.uuid_progetto);
                  }}
                >
                  <ModificaIcon aria-label="modifica" />
                </IconButton>{" "}
                <Modal
                  sx={{
                    ".MuiModal-backdrop": {
                      backgroundColor: alpha("#000000", 0.2),
                    },
                  }}
                  open={modalModifica}
                  onClose={() => setModalModifica(false)}
                >
                  <Box>
                    <ModificaModal
                      uuid_progetto={elem.uuid_progetto}
                      utenteInModifica={nominativo_modifica}
                      color={props.color2}
                      setOpen={setModalModifica}
                    />
                  </Box>
                </Modal>
              </Box>
            ) : null,
            elem.codice_scuola === codiceMeccanografico ? (
              <Box>
                <IconButton
                  aria-label="elimina"
                  onClick={() => {
                    dispatch(setIdProgetto(elem.uuid_progetto));
                    confermaDelete(elem.uuid_progetto);
                  }}
                >
                  {" "}
                  <DeleteElementIcon />
                </IconButton>{" "}
                <Modal
                  sx={{
                    ".MuiModal-backdrop": {
                      backgroundColor: alpha("#000000", 0.2),
                    },
                  }}
                  open={open}
                  onClose={() => setOpen(false)}
                >
                  <Box>
                    <EliminaModal elem={elem} color={props.color2} setOpen={setOpen} />
                  </Box>
                </Modal>{" "}
                <Modal
                  sx={{
                    ".MuiModal-backdrop": {
                      backgroundColor: alpha("#000000", 0.2),
                    },
                  }}
                  open={modalAvviso}
                  onClose={() => setModalAvviso(false)}
                >
                  <Box>
                    <AvvisoElimModal setOpen={setModalAvviso} />
                  </Box>
                </Modal>{" "}
              </Box>
            ) : null,
            <Grid container>
              <Grid item xs={6}>
                <Link
                  sx={{
                    color: "black",
                    cursor: "pointer",
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=true", "_blank")}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <PrintIcon sx={{ color: "black" }} />
                    </Grid>

                    <Grid item xs={12}>
                      {BUTTON_SCARICA}
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  sx={{
                    color: "black",
                    cursor: "pointer",
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=false", "_blank")}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <MobileScreenShareOutlined sx={{ color: "black" }} />
                    </Grid>
                    <Grid item xs={12}>
                      {BUTTON_CONDIVIDI}
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          )
      : createData(
          <Progress progress={elem.completamento} />,
          elem.descr_t_progetto,
          elem.titolo_progetto,
          format(new Date(elem.data_inserimento), "dd-MM-yyyy"),
          <DettagliLink indexTabs={props.indexTabs} path={istituti === true ? PATH_SCHEDA_ISTITUTO : PATH_SCRIVANIA} id={elem.uuid_progetto} />,
          elem.codice_scuola === codiceMeccanografico ? (
            <Box>
              <IconButton
                aria-label="modifica"
                onClick={() => {
                  dispatch(setIndexGestisciProgetti(props.indexTabs));
                  edit(elem.descr_t_progetto, elem.uuid_progetto);
                }}
              >
                <ModificaIcon aria-label="modifica" />
              </IconButton>{" "}
              <Modal
                sx={{
                  ".MuiModal-backdrop": {
                    backgroundColor: alpha("#000000", 0.2),
                  },
                }}
                open={modalModifica}
                onClose={() => setModalModifica(false)}
              >
                <Box>
                  <ModificaModal
                    uuid_progetto={elem.uuid_progetto}
                    utenteInModifica={nominativo_modifica}
                    color={props.color2}
                    setOpen={setModalModifica}
                  />
                </Box>
              </Modal>
            </Box>
          ) : null,
          elem.codice_scuola === codiceMeccanografico ? (
            <Box>
              <IconButton
                aria-label="elimina"
                onClick={() => {
                  dispatch(setIdProgetto(elem.uuid_progetto));
                  confermaDelete(elem.uuid_progetto);
                }}
              >
                {" "}
                <DeleteElementIcon />
              </IconButton>{" "}
              <Modal
                sx={{
                  ".MuiModal-backdrop": {
                    backgroundColor: alpha("#000000", 0.2),
                  },
                }}
                open={open}
                onClose={() => setOpen(false)}
              >
                <Box>
                  <EliminaModal elem={elem} color={props.color2} setOpen={setOpen} />
                </Box>
              </Modal>{" "}
              <Modal
                sx={{
                  ".MuiModal-backdrop": {
                    backgroundColor: alpha("#000000", 0.2),
                  },
                }}
                open={modalAvviso}
                onClose={() => setModalAvviso(false)}
              >
                <Box>
                  <AvvisoElimModal setOpen={setModalAvviso} />
                </Box>
              </Modal>{" "}
            </Box>
          ) : null
        )
  );

  return (
    profilo && (
      <>
        {" "}
        <Modal
          sx={{
            ".MuiModal-backdrop": {
              backgroundColor: alpha("#000000", 0.2),
            },
          }}
          open={avvisoUtente}
          onClose={() => setAvvisoUtente(false)}
        >
          <Box>
            <AvvisoUtDisabilitato setAvvisoUtente={setAvvisoUtente} />
          </Box>
        </Modal>
        <Grid container className="gestisciProgetti">
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{
                "@media (max-width:1024px)": { display: "none" },
              }}
            >
              <Table sx={{ minWidth: 700, cursor: "default" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" sx={{ width: "180px" }}>
                      <Grid container justifyContent={"center"}>
                        <Grid item>
                          <Typography className="titleColumnTable">{COMPLETAMENTO}</Typography>
                          {dropdownCompletamento ? <Completamento /> : null}
                        </Grid>
                        <Grid item>
                          <KeyboardArrowDownIcon onClick={() => dispatch(setDropDownCompletamento())} sx={{ cursor: "pointer" }} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: props.azioni === false ? "300px" : "190px" }}>
                      <Grid container justifyContent={"center"}>
                        <Grid item>
                          <Typography className="titleColumnTable">{TIPOLOGIA_PROGETTO}</Typography>
                          {dropdown ? <Tipologia /> : null}
                        </Grid>
                        <Grid item>
                          <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown())} sx={{ cursor: "pointer" }} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: props.azioni === false ? "220px" : "160px" }}>
                      <Grid container justifyContent={"center"}>
                        <Grid item>
                          <Typography className="titleColumnTable">{TITOLO_PROGETTO}</Typography>
                          {dropdown2 ? <Titolo dropDown={setDropDown2()} /> : null}
                        </Grid>
                        <Grid item>
                          <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown2())} sx={{ cursor: "pointer" }} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: props.azioni === false ? "170px" : null }}>
                      <Grid container justifyContent={"center"}>
                        <Grid item>
                          <Typography className="titleColumnTable">{DATA_INSERIMENTO}</Typography>
                          {dropdown3 ? <DataInserimento dropDown={setDropDown3()} /> : null}
                        </Grid>
                        <Grid item>
                          <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown3())} sx={{ cursor: "pointer" }} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    {props.tabIstituti ? (
                      <StyledTableCell align="center">
                        <Grid container justifyContent={"center"}>
                          <Grid item>
                            <Typography className="titleColumnTable">{REFERENTE}</Typography>
                            {dropdown4 ? <Referente dropDown={setDropDown4()} /> : null}
                          </Grid>
                          <Grid item>
                            <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown4())} sx={{ cursor: "pointer" }} />
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    ) : null}

                    {props.azioni ? (
                      <StyledTableCell align="center">
                        <Grid container justifyContent={"center"}>
                          <Grid item>
                            <Typography className="titleColumnTable">{PUBBLICAZIONE}</Typography>
                            {dropdown5 ? <Pubblicazione /> : null}
                          </Grid>
                          <Grid item>
                            <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown5())} sx={{ cursor: "pointer" }} />
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    ) : null}
                    <StyledTableCell align="center" sx={{ width: props.azioni === false ? "150px" : null }}>
                      <Typography className="titleColumnTable">{DETTAGLI}</Typography>
                    </StyledTableCell>
                    {props.tabIstituti && ruolo === RUOLO_GES ? null : (
                      <StyledTableCell align="center" sx={{ width: props.azioni === false ? "150px" : null }}>
                        <Typography className="titleColumnTable"> {MODIFICA}</Typography>
                      </StyledTableCell>
                    )}
                    {props.tabIstituti && ruolo === RUOLO_GES ? null : (
                      <StyledTableCell align="center" sx={{ width: props.azioni === false ? "150px" : null }}>
                        <Typography className="titleColumnTable"> {ELIMINA}</Typography>
                      </StyledTableCell>
                    )}
                    {props.azioni ? (
                      <StyledTableCell align="center">
                        <Typography className="titleColumnTable"> {AZIONI}</Typography>
                      </StyledTableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(props.valore1slice, props.valore2slice).map((row, index) =>
                    props.azioni ? (
                      props.tabIstituti ? (
                        ruolo === RUOLO_GES ? (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">{row.completamento}</StyledTableCell>
                            <StyledTableCell align="center">{row.tipologia}</StyledTableCell>
                            <StyledTableCell align="center">{row.titolo}</StyledTableCell>
                            <StyledTableCell align="center">{row.dataInserimento}</StyledTableCell>
                            <StyledTableCell align="center">{row.referente}</StyledTableCell>
                            <StyledTableCell align="center">{row.pubblicazione}</StyledTableCell>
                            <StyledTableCell align="center">{row.dettagli}</StyledTableCell>
                            <StyledTableCell align="center">{row.azioni}</StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">{row.completamento}</StyledTableCell>
                            <StyledTableCell align="center">{row.tipologia}</StyledTableCell>
                            <StyledTableCell align="center">{row.titolo}</StyledTableCell>
                            <StyledTableCell align="center">{row.dataInserimento}</StyledTableCell>
                            <StyledTableCell align="center">{row.referente}</StyledTableCell>
                            <StyledTableCell align="center">{row.pubblicazione}</StyledTableCell>
                            <StyledTableCell align="center">{row.dettagli}</StyledTableCell>
                            <StyledTableCell align="center">{row.modifica}</StyledTableCell>
                            <StyledTableCell align="center">{row.elimina}</StyledTableCell>
                            <StyledTableCell align="center">{row.azioni}</StyledTableCell>
                          </StyledTableRow>
                        )
                      ) : (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">{row.completamento}</StyledTableCell>
                          <StyledTableCell align="center">{row.tipologia}</StyledTableCell>
                          <StyledTableCell align="center">{row.titolo}</StyledTableCell>
                          <StyledTableCell align="center">{row.dataInserimento}</StyledTableCell>
                          <StyledTableCell align="center">{row.pubblicazione}</StyledTableCell>
                          <StyledTableCell align="center">{row.dettagli}</StyledTableCell>
                          <StyledTableCell align="center">{row.modifica}</StyledTableCell>
                          <StyledTableCell align="center">{row.elimina}</StyledTableCell>
                          <StyledTableCell align="center">{row.azioni}</StyledTableCell>
                        </StyledTableRow>
                      )
                    ) : (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">{row.completamento}</StyledTableCell>
                        <StyledTableCell align="center">{row.tipologia}</StyledTableCell>
                        <StyledTableCell align="center">{row.titolo}</StyledTableCell>
                        <StyledTableCell align="center">{row.dataInserimento}</StyledTableCell>
                        <StyledTableCell align="center">{row.dettagli}</StyledTableCell>
                        <StyledTableCell align="center">{row.modifica}</StyledTableCell>
                        <StyledTableCell align="center">{row.elimina}</StyledTableCell>
                      </StyledTableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", "@media (max-width:900px)": { display: "none" } }}>
            {progetti.length === 0 && (
              <Typography sx={{ fontSize: "22px", fontWeight: "Bold", marginTop: "20px" }}>{AVVISO_ASSENZA_PROGETTI}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ justifyContent: "center", display: "flex", marginTop: "20px" }}>
              {countPagination > 1 ? <PaginationShared page={page} handlechange={handlechange} color={props.color1} count={countPagination} /> : null}
            </Box>
          </Grid>
        </Grid>
      </>
    )
  );
};
