import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { validate as validateMail } from "react-email-validator";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  ModalDisabilitazione,
  DatiIstituto,
  DatiMultimedia,
  DatiPersonali,
  DisabilitaButton,
  FooterButtons,
  SkeletonProfilo,
  ModalConfermaModifiche,
  ModalRuoloLibero,
} from "./profilo/";
import { LoadingSpinner } from "../shared";
import {
  getInfoProfilo,
  verificaRuoloLibero,
  aggiornaProfilo,
  getEsitoAggiornamento,
  disabilitaProfilo,
  getEsitoDisabilitazione,
  getEsitoRuoloLibero,
  fetchRuoloLiberoEnded,
  resetSpinningProfilo,
} from "../../modules/profilo";
import { fetchIncarichiEnded, fetchIstitutiScolasticiEnded } from "../../modules/common";
import {
  PROFILO_NOME,
  PROFILO_COGNOME,
  PROFILO_AVATAR,
  PROFILO_BIOGRAFIA,
  PROFILO_CELLULARE,
  PROFILO_EMAIL,
  PROFILO_SOCIAL,
  PROFILO_ISCRIZIONE_NEWSLETTER,
  PROFILO_CODICE_SCUOLA,
  PROFILO_NOME_SCUOLA,
  PROFILO_CODICE_INCARICO,
  PROFILO_INCARICO,
} from "../../modules/profilo";
import { labels } from "../../labels";
import { ProfiloIcon } from "../../styles/icons";
import { HeaderPage } from "../shared/Header";
import { PATH_HOME } from "../../path";
import { validateCellulare, validateUrl } from "../../utility/utils";
import { setThemeColor } from "../../modules/configuration";

const { MENU_GESTISCI_PROFILO } = labels;

export const Profilo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDisabilitaModal, setShowDisabilitaModal] = useState(false);
  const [showModificheModal, setShowModificheModal] = useState(false);
  const [showRuoloLiberoModal, setShowRuoloLiberoModal] = useState(false);
  const [spinningAggiornamento, setSpinningAggiornamento] = useState(false);
  const profilo = useSelector(getInfoProfilo);
  const fetchRuoliEnded = useSelector(fetchIncarichiEnded);
  const fetchIstitutiEnded = useSelector(fetchIstitutiScolasticiEnded);
  const profiloAggiornato = useSelector(getEsitoAggiornamento);
  const profiloDisabilitato = useSelector(getEsitoDisabilitazione);
  const ruoloLibero = useSelector(getEsitoRuoloLibero);
  const fetchRuoloEnded = useSelector(fetchRuoloLiberoEnded);

  //setto il colore del tema da seguire nelle pagine successive
  useEffect(() => {
    dispatch(setThemeColor({ color1: "#105B70 !important", color2: "#98BCBD !important" }));
    // eslint-disable-next-line
  }, []);

  const validationSchema = yup.object({
    [PROFILO_CELLULARE]: yup
      .string()
      .required("is required")
      .test({
        test: (value) => validateCellulare(value),
      }),
    [PROFILO_EMAIL]: yup
      .string()
      .required("is required")
      .test({
        test: (value) => validateMail(value),
      }),
    [PROFILO_SOCIAL]: yup.string().test({
      test: (value) => (value != null ? validateUrl(value) : true),
    }),
    [PROFILO_CODICE_INCARICO]: yup.string().required("is required"),
    [PROFILO_CODICE_SCUOLA]: yup.string().required("is required"),
  });

  const formik = useFormik({
    initialValues: {
      [PROFILO_NOME]: "",
      [PROFILO_COGNOME]: "",
      [PROFILO_AVATAR]: null,
      [PROFILO_BIOGRAFIA]: "",
      [PROFILO_CELLULARE]: "",
      [PROFILO_EMAIL]: "",
      [PROFILO_SOCIAL]: "",
      [PROFILO_ISCRIZIONE_NEWSLETTER]: false,
      [PROFILO_CODICE_SCUOLA]: null,
      [PROFILO_NOME_SCUOLA]: null,
      [PROFILO_CODICE_INCARICO]: "",
      [PROFILO_INCARICO]: null,
    },
    validationSchema: validationSchema,
    onSubmit: () => setShowModificheModal(true),
  });

  useEffect(() => {
    if (profiloAggiornato === true || profiloAggiornato === false || profiloDisabilitato === true || profiloDisabilitato === false) {
      setSpinningAggiornamento(false);
      if (profiloAggiornato === true) {
        navigate(PATH_HOME);
        dispatch(resetSpinningProfilo());
      }
    }
    // eslint-disable-next-line
  }, [profiloAggiornato, profiloDisabilitato, fetchRuoloEnded]);

  useEffect(() => {
    if (ruoloLibero && ruoloLibero.nome != null && ruoloLibero.cognome != null) {
      setSpinningAggiornamento(false);
      setShowRuoloLiberoModal(true);
    }
  }, [ruoloLibero]);

  useEffect(() => {
    if (profilo) popolaFormik();
    // eslint-disable-next-line
  }, [profilo]);

  const popolaFormik = () => {
    for (const [key, value] of Object.entries(profilo)) {
      formik.setFieldValue(key, value);
    }
  };

  const onDatiProfiloChange = (modifiche) => {
    // eslint-disable-next-line
    modifiche.map((modifica) => {
      const { fieldName, fieldValue } = modifica;
      formik.setFieldValue(fieldName, fieldValue);
    });
  };

  const profiloProps = {
    datiProfilo: formik.values,
    onChange: onDatiProfiloChange,
  };

  return !profilo || !fetchIncarichiEnded || !fetchIstitutiEnded ? (
    <SkeletonProfilo />
  ) : (
    <Box className="profilo">
      <HeaderPage icon={<ProfiloIcon />} title={MENU_GESTISCI_PROFILO} color={"#105b70"} />
      <form onSubmit={formik.handleSubmit} id="formProfilo">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <DatiMultimedia {...profiloProps} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Grid container>
                  <DatiPersonali {...profiloProps} />
                  <DatiIstituto {...profiloProps} />
                  <DisabilitaButton onClick={() => setShowDisabilitaModal(true)} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {profilo && <FooterButtons profilo={profilo} tmpProfilo={formik.values} onSave={formik.handleSubmit} onAnnulla={() => popolaFormik()} />}
        </Grid>
      </form>
      <ModalDisabilitazione
        open={showDisabilitaModal}
        onYes={() => {
          setShowDisabilitaModal(false);
          setSpinningAggiornamento(true);
          dispatch(disabilitaProfilo());
        }}
        onNo={() => setShowDisabilitaModal(false)}
      />
      <ModalConfermaModifiche
        open={showModificheModal}
        onYes={() => {
          const cambiRuoloScuola =
            formik.values[PROFILO_CODICE_INCARICO] !== profilo[PROFILO_CODICE_INCARICO] ||
            formik.values[PROFILO_CODICE_SCUOLA] !== profilo[PROFILO_CODICE_SCUOLA];
          setShowModificheModal(false);
          setSpinningAggiornamento(true);
          if (formik.values[PROFILO_CODICE_INCARICO] !== profilo[PROFILO_CODICE_INCARICO])
            dispatch(verificaRuoloLibero({ payload: formik.values, cambiRuoloScuola }));
          else dispatch(aggiornaProfilo({ payload: formik.values, cambiRuoloScuola }));
        }}
        onNo={() => setShowModificheModal(false)}
      />
      <ModalRuoloLibero
        incaricato={ruoloLibero ? ruoloLibero.nome + " " + ruoloLibero.cognome : ruoloLibero}
        open={showRuoloLiberoModal}
        onYes={() => {
          const cambiRuoloScuola =
            formik.values[PROFILO_CODICE_INCARICO] !== profilo[PROFILO_CODICE_INCARICO] ||
            formik.values[PROFILO_CODICE_SCUOLA] !== profilo[PROFILO_CODICE_SCUOLA];
          setShowRuoloLiberoModal(false);
          setSpinningAggiornamento(true);
          dispatch(aggiornaProfilo({ payload: formik.values, cambiRuoloScuola }));
        }}
        onNo={() => {
          setSpinningAggiornamento(false);
          setShowRuoloLiberoModal(false);
        }}
      />
      <LoadingSpinner loading={!fetchRuoliEnded || !fetchIstitutiEnded || spinningAggiornamento} />
    </Box>
  );
};
