import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, InputAdornment, Typography } from "@mui/material";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { validate as validateMail } from "react-email-validator";
import { labels } from "../../../labels";
import { PATH_RISORSE } from "../../../path";
import { Input } from "../../";
import { PROFILO_CELLULARE, PROFILO_EMAIL, PROFILO_SOCIAL, PROFILO_ISCRIZIONE_NEWSLETTER } from "../../../modules/profilo";
import { validateCellulare, validateUrl } from "../../../utility/utils";

const {
  REQUIRED_FIELD,
  LINK_FORMAT_ERROR,
  PROFILO_DATI_PERSONALI,
  CELLULARE_LABEL,
  CELLULARE_PLACEHOLDER,
  MAIL_LABEL,
  MAIL_PLACEHOLDER,
  SOCIAL_LABEL,
  SOCIAL_PLACEHOLDER,
  PROFILO_CELLULARE_ERROR,
  PROFILO_CELLULARE_ERROR_LENGTH,
  PROFILO_MAIL_ERROR,
  PROFILO_NEWSLETTER,
  PROFILO_PRIVACY,
  PROFILO_AUTOCERTIFICAZIONE,
} = labels;

export const DatiPersonali = ({ datiProfilo, onChange }) => {
  const cellulare = datiProfilo[PROFILO_CELLULARE];
  const mail = datiProfilo[PROFILO_EMAIL];
  const social = datiProfilo[PROFILO_SOCIAL];
  const newsletter = datiProfilo[PROFILO_ISCRIZIONE_NEWSLETTER];

  return (
    <Grid item xs={12} className="datiPersonali">
      <Grid container spacing={2}>
        <Grid item xs={12} className="headerSezione">
          <AccountCircleIcon />
          <Typography>{PROFILO_DATI_PERSONALI}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            required
            label={CELLULARE_LABEL}
            placeholder={CELLULARE_PLACEHOLDER}
            value={cellulare}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9]*$/i;
              if (regex.test(value) && value.length < 11) onChange([{ fieldName: PROFILO_CELLULARE, fieldValue: value }]);
            }}
            startAdornment={
              <InputAdornment position="start" sx={{ "& .MuiTypography-root": { color: "black" } }}>
                + 39
              </InputAdornment>
            }
            error={cellulare === "" || (cellulare.length === 10 && !validateCellulare(cellulare)) || cellulare.length < 10}
            {...(cellulare === "" && { errorText: REQUIRED_FIELD })}
            {...(cellulare !== "" && cellulare.length < 10 && { errorText: PROFILO_CELLULARE_ERROR_LENGTH })}
            {...(cellulare !== "" && cellulare.length === 10 && !validateCellulare(cellulare) && { errorText: PROFILO_CELLULARE_ERROR })} //prettier-ignore
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            required
            label={MAIL_LABEL}
            placeholder={MAIL_PLACEHOLDER}
            value={mail}
            onChange={(e) => {
              const value = e.target.value?.toLowerCase();
              onChange([{ fieldName: PROFILO_EMAIL, fieldValue: value }]);
            }}
            error={mail === "" || (mail !== "" && !validateMail(mail))}
            {...(mail === "" && { errorText: REQUIRED_FIELD })}
            {...(mail !== "" && !validateMail(mail) && { errorText: PROFILO_MAIL_ERROR })} //prettier-ignore
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label={SOCIAL_LABEL}
            placeholder={SOCIAL_PLACEHOLDER}
            value={social}
            numeroCaratteri={200}
            onChange={(e) => onChange([{ fieldName: PROFILO_SOCIAL, fieldValue: e.target.value }])}
            {...(social !== "" && { error: !validateUrl(social), errorText: LINK_FORMAT_ERROR })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <Typography className="MuiFormLabel-root fieldLabel" sx={{ opacity: 0 }}>
              {PROFILO_ISCRIZIONE_NEWSLETTER}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletter}
                  onChange={(e) => onChange([{ fieldName: PROFILO_ISCRIZIONE_NEWSLETTER, fieldValue: e.target.checked }])}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                />
              }
              label={PROFILO_NEWSLETTER}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <a href={window.origin + PATH_RISORSE + "/gdpr.pdf"} target="_blank" rel="noreferrer">
            <Typography>{PROFILO_PRIVACY}</Typography>
          </a>
        </Grid>
        <Grid item xs={12}>
          <a href={window.origin + PATH_RISORSE + "/autocert.pdf"} target="_blank" rel="noreferrer">
            <Typography>{PROFILO_AUTOCERTIFICAZIONE}</Typography>
          </a>
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
};
