export const REDUX_COMPOSIZIONE_GRUPPO_STUDENTI = "composizioneGruppoStudenti";
export const REDUX_TEMA_PREVALENTE = "temaPrevalente";
export const REDUX_ORDINE_SCUOLA = "ordineScuola";
export const REDUX_TEMPO_REALIZZAZIONE = "tempoRealizzazione";
export const REDUX_ANNO_SCOLASTICO_REALIZZAZIONE = "annoScolasticoRealizzazione";
export const REDUX_ATTIVITA_DIGITALE = "attivitaDigitale";
export const REDUX_CO_RESPONSABILE_PROGETTO = "coResponsabileProgetto";
export const REDUX_AREA_COINVOLTA = "areaCoinvolta";
export const REDUX_FONDI_CONTESTO = "fondiEcontesto";
export const REDUX_TIPO_BENEFICIARI = "tipoBeneficiari";
export const REDUX_DISCIPLINE_COINVOLTE = "disciplineCoinvolte";
export const REDUX_INTERDISCIPLINARIETA = "Interdisciplinarieta";
export const REDUX_ATTIVITA_CLIL = "attivitaClil";
export const REDUX_ACCOMPAGNAMENTO = "accompagnamento";
export const REDUX_ATTIVITA_CURRICOLARI = "attivitaCurricolari";
export const REDUX_ATTIVITA_INNOVAZIONE = "attivitaInnovazione";
export const REDUX_DOCUMENTAZIONE_PROGETTO = "documentazioneProgetto";
export const REDUX_AZIONE_DIFFUSIONE_DISSEMINAZIONE = "azioneDiffusioneDisseminazione";
export const REDUX_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO = "attivitaValutazioneEfficaciaProgetto";
export const REDUX_TITOLO = "titolo";
export const REDUX_DATA_INIZIO = "dataInizio";
export const REDUX_DENOMINAZIONE = "denominazione";
export const REDUX_DATA_INSERIMENTO = "dataInserimento";
export const REDUX_PROVINCIA = "provincia";
export const REDUX_PUBBLICATO = "pubblicato";
export const REDUX_REFERENTE = "referente";
export const REDUX_VALUTAZIONE = "valutazione";
export const REDUX_CODICE_MECCANOGRAFICO = "codiceMeccanografico";
export const REDUX_STUDENTI_BES = "studentiBes";
export const REDUX_CONTESTO_ACCOMPAGNAMENTO = "contestoAccompagnamento";
export const REDUX_TIPOLOGIA_ISTITUTO = "tipologiaIstituto";
export const REDUX_COMUNE = "comune";
export const REDUX_PROGETTI_BOZZA = "progettiBozza";
export const REDUX_PROGETTI_CONCLUSI = "progettiConclusi";
export const REDUX_PROGETTI_ISTITUTO = "progettiIstituto";
export const REDUX_ISTITUTI_SCOLASTICI = "istitutiScolastici";
export const REDUX_PROGETTI_CURRICOLARI_EXTRACURRICOLARI = "progettiCurricolariExtraCurricolari";
export const REDUX_ISTITUTO = "istituto";
export const REDUX_PLESSI_ISTITUTO = "plessiIstituto";
export const REDUX_PROFILI_USCITA = "profiliInUscita";
export const REDUX_MEMBRI_TEAM = "membriTeam";
export const REDUX_PROFILO = "profilo";
export const REDUX_TIPOLOGIA_PROGETTO = "tipologiaProgetto";
export const REDUX_SUPPORTO_DAL_PROGETTO = "supportoDalProgetto";
export const REDUX_RUOLO = "ruolo";
export const REDUX_DETTAGLI_PROGETTO = "dettagliProgetto";
export const REDUX_INDIRIZZI_ISTITUTI_SCOLASTICI = "indirizziIstitutiScol";
export const REDUX_UUID_PROGETTO = "uuidProgetto";
export const REDUX_ID_PROGETTO = "idProgetto";
export const REDUX_CODICE_PROGETTO = "codiceProgetto";
export const REDUX_SCHEDA_PROFILO = "schedaProfilo";
