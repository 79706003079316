import { Grid, IconButton, Typography, Box } from "@mui/material";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../../labels";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
export const AvvisoUtDisabilitato = (props) => {
  const { AVVISO_UTENTE_DISABILITATO } = labels;

  let setAvvisoUtente = props.setAvvisoUtente;

  return (
    <Box className="gestisciProgetti">
      <Grid container className="deleteModal">
        <Grid item xs={12}>
          <IconButton disableRipple aria-label="delete" className="iconClose" onClick={() => setAvvisoUtente(false)}>
            <CancelOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "-40px" }}>
          <ReportProblemIcon sx={{ color: "red", height: "40px", width: "40px" }} />
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-40px" }}>
          <Typography className="textTitleModalDelete">{AVVISO_UTENTE_DISABILITATO}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
