import { Grid, Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIndex,
  getButtonType,
  getEdit,
  resetFiltriProgetti,
  resetIndex,
  getDatiProgetto,
  saveValueProgetto,
  titleSet,
  getProgressAttivita,
  getProgressContesto,
  setIdProgetto,
  getIdProgetto,
  getIndexTabSpostamento,
  changetabs,
  saveProgressBar,
} from "../../../../modules/configuration";
import { FooterInserisciProgetti } from "../FooterInserisciProgetti";
import { PATH_HOME } from "../../../../path";
import { useNavigate } from "react-router-dom";
import { getCommonStore } from "../../../../modules/common";
import { PROFILO_TEAM, getInfoProfilo } from "../../../../modules/profilo";
import { Autocomplete, Calendar, Input, Select } from "../../../shared";
import { labels } from "../../../../labels";
import { fetchDisciplineCoinvolte } from "../../../../modules/datiOsservatorio";
import { valProgrCurr } from "../progressFunction";
import { POST_ANAGRAFICA_CURRICOLARE, PUT_ANAGRAFICA_CURRICOLARE, PUT_BLOCCO_MODIFICA, PUT_SBLOCCO_MODIFICA } from "../../../../apiUrl";
import { notificationCodes, setNotification } from "../../../../modules/notification";
import { createRequest } from "../../../../utility";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { ModaleSpostamentoTab } from "../ModaleSpostamentoTab";

const {
  DATA_INIZIO_PROGETTO,
  PLACEHOLDER_DATA,
  TIPOLOGIA_PROGETTO,
  PLACEHOLDER_TIPOLOGIA_PROGETTO,
  CO_RESPONSABILE,
  PLACEHOLDER_TEAM_DIGITALE,
  ORDINE_SCUOLA,
  PLACEHOLDER_ORDINE_SCUOLA,
  COMPOSIZIONE_GRUPPO_STUDENTI,
  PLACEHOLDER_COMPOSIZIONE_STUDENTI,
  TITOLO_PROGETTO,
  ATTIVITA_DIGITALE,
  PLACEHOLDER_ATTIVITA_DIGITALE,
  STUDENTI_BES,
  PLACEHOLDER_STUDENTI_BES,
  ABSTRACT,
  NOTE,
  PLACEHOLDER_DESCRIVI,
  PLACEHOLDER_ABSTRACT,
  ANNO_REALIZZAZIONE,
  TEMA_PREVALENTE,
  PLACEHOLDER_TEMA_PREVALENTE,
  TEMPO_REALIZZAZIONE,
  PLACEHOLDER_TEMPO_REALIZZAZIONE,
  PLACEHOLDER_ANNO_REALIZZAZIONE,
  PLACEHOLDER_TITOLO_PROGETTO,
  NUMERO_STUDENTI,
  PLACEHOLDER_NUMERO,
  REQUIRED_FIELD,
  ERROR_DATE,
} = labels;

export const Anagrafica = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allOptions = useSelector(getCommonStore);
  let allOptionsFiltered = {};

  for (const [key, value] of Object.entries(allOptions)) {
    allOptionsFiltered[key] = value.data !== null ? value.data : [];
  }

  let allOptionsFilteredFetchEnded = {};

  for (const [key, value] of Object.entries(allOptions)) {
    allOptionsFilteredFetchEnded[key] = value.fetchEnded !== null ? value.fetchEnded : [];
  }
  const datiProg = useSelector(getDatiProgetto);
  const attivitaProgress = useSelector(getProgressAttivita);
  const attcontprogr = useSelector(getProgressContesto);
  let progress = Math.floor(valProgrCurr(datiProg) + attivitaProgress + attcontprogr);
  progress = progress > 100 ? 100 : progress;
  dispatch(saveProgressBar(progress));

  const datiProgetto = datiProg.anagrafica;
  const buttonType = useSelector(getButtonType);
  const edit = useSelector(getEdit);
  let progetto = props.progetto;
  const idProg = useSelector(getIdProgetto);
  const profilo = useSelector(getInfoProfilo);
  const codiceScuola = profilo.codiceScuola;
  const idReferente = profilo[PROFILO_TEAM];
  const index = useSelector(getIndexTabSpostamento);

  //controllo redirect home se valori options obbligatorie vuote
  if (
    allOptionsFilteredFetchEnded.tipologieProgetto === false ||
    allOptionsFilteredFetchEnded.composizioneGruppoStudenti === false ||
    allOptionsFilteredFetchEnded.tematicheProgetto === false ||
    allOptionsFilteredFetchEnded.ordiniScuola === false ||
    allOptionsFilteredFetchEnded.anniScolasticiRealizzazione === false ||
    allOptionsFilteredFetchEnded.tempoRealizzazioneProgetto === false
  ) {
    navigate(PATH_HOME);
  }

  //se mi trovo in fase di inserimento mi creo e salvo uuid progetto
  useEffect(() => {
    if (edit !== true) {
      dispatch(setIdProgetto(uuidv4()));
    }
    // eslint-disable-next-line
  }, []);

  //se mi trovo in fase di modifica setto i valori di formik con i valori progetto corrispondenti
  useEffect(() => {
    if (progetto.length === undefined) {
      formik.setFieldValue("titolo_progetto", datiProgetto["titolo_progetto"]);
      formik.setFieldValue("descr_progetto", datiProgetto["descr_progetto"]);
      formik.setFieldValue("data_inizio", datiProgetto["data_inizio"]);
      formik.setFieldValue("descr_tempo_realiz", datiProgetto["descr_tempo_realiz"]);
      formik.setFieldValue("descr_anno_realiz", datiProgetto["descr_anno_realiz"]);
      formik.setFieldValue("descr_grado_scuola", datiProgetto["descr_grado_scuola"]);
      formik.setFieldValue("CoResponsabile", datiProgetto["CoResponsabile"]);
      formik.setFieldValue("nome_mentor", datiProgetto["nome_mentor"]);
      formik.setFieldValue("annote", datiProgetto["annote"]);
      formik.setFieldValue("descr_t_progetto", datiProgetto["descr_t_progetto"]);
      formik.setFieldValue("num_alunni_imp", datiProgetto["num_alunni_imp"]);
      formik.setFieldValue("descr_composizione_gruppo", datiProgetto["descr_composizione_gruppo"]);
      formik.setFieldValue("descr_tipo_attributo", datiProgetto["descr_tipo_attributo"]);
      formik.setFieldValue("descr_studenti_bes", datiProgetto["descr_studenti_bes"]);
      formik.setFieldValue("descr_attivita_digitale", datiProgetto["descr_attivita_digitale"]);
    }
    // eslint-disable-next-line
  }, [progetto]);

  //controllo su valore data inserita
  const checkDate = () => {
    let currentTime = new Date();
    let year = currentTime.getFullYear();
    if (dayjs(datiProgetto["data_inizio"]) > dayjs("2013-01-01") && dayjs(datiProgetto["data_inizio"]) < dayjs(year + 1 + "-12-31")) {
      return true;
    }
    if (datiProgetto["data_inizio"] === "") {
      return true;
    } else return false;
  };

  const validationSchema = yup.object({
    descr_t_progetto: yup.string().required("is required"),
    titolo_progetto: yup.string().required("is required"),
    num_alunni_imp: yup.string().required("is required"),
    descr_composizione_gruppo: yup.string().required("is required"),
    descr_tipo_attributo: yup.string().required("is required"),
    descr_grado_scuola: yup.string().required("is required"),
    descr_tempo_realiz: yup.string().required("is required"),
    descr_anno_realiz: yup.string().required("is required"),
    descr_attivita_digitale: yup.string().required("is required"),
    descr_progetto: yup.string().required("is required"),
    data_inizio: yup.string().required("is required"),
  });

  const formik = useFormik({
    initialValues: {
      descr_t_progetto: datiProgetto["descr_t_progetto"],
      descr_progetto: datiProgetto["descr_progetto"],
      data_inizio: datiProgetto["data_inizio"],
      titolo_progetto: datiProgetto["titolo_progetto"],
      num_alunni_imp: datiProgetto["num_alunni_imp"],
      descr_composizione_gruppo: datiProgetto["descr_composizione_gruppo"],
      descr_tipo_attributo: datiProgetto["descr_tipo_attributo"],
      descr_grado_scuola: datiProgetto["descr_grado_scuola"],
      descr_tempo_realiz: datiProgetto["descr_tempo_realiz"],
      descr_anno_realiz: datiProgetto["descr_anno_realiz"],
      descr_studenti_bes: datiProgetto["descr_studenti_bes"],
      descr_attivita_digitale: datiProgetto["descr_attivita_digitale"],
      CoResponsabile: datiProgetto["CoResponsabile"],
      annote: datiProgetto["annote"],
    },

    validationSchema: validationSchema,
    onSubmit: (event) => {
      if (checkDate() === true) {
        let dataPost = {
          codice_scuola: codiceScuola,
          uuid_progetto: progetto.data === undefined ? progetto.uuid_progetto : idProg,
          id_tipo_progetto: formik.values.descr_t_progetto,
          studenti_bes: formik.values.descr_studenti_bes,
          descr_progetto: formik.values.descr_progetto,
          data_inizio: formik.values.data_inizio,
          titolo_progetto: formik.values.titolo_progetto,
          num_alunni_imp: parseInt(formik.values.num_alunni_imp),
          id_composizione_gruppo: formik.values.descr_composizione_gruppo,
          id_tipo_attributo: formik.values.descr_tipo_attributo,
          id_grado_scuola: formik.values.descr_grado_scuola,
          id_tempo_realiz: formik.values.descr_tempo_realiz,
          id_anno_realiz: formik.values.descr_anno_realiz,
          id_attivita_digitale: formik.values.descr_attivita_digitale,
          annote: formik.values.annote,
          id_referente: idReferente,
          id_coreferente: formik.values.CoResponsabile,
          completamento: progress,
        };
        if (edit !== true) {
          createRequest(POST_ANAGRAFICA_CURRICOLARE.method, POST_ANAGRAFICA_CURRICOLARE.baseUrl, POST_ANAGRAFICA_CURRICOLARE.apiUrl, null, dataPost)
            .then((response) => {
              if (response.status === 200) {
                if (buttonType === "AVANTI") {
                  createRequest(PUT_BLOCCO_MODIFICA.method, PUT_BLOCCO_MODIFICA.baseUrl, PUT_BLOCCO_MODIFICA.apiUrl, null, { uuid_progetto: idProg })
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(checkIndex("4"));
                      }
                    })
                    .catch((e) => {
                      dispatch(
                        setNotification({
                          tipo: notificationCodes.ERROR,
                          message: "Errore durante il cambio stato del progetto in modifica",
                          description: "Errore durante il cambio stato del progetto in modifica",
                        })
                      );
                    });
                } else {
                  navigate(PATH_HOME);
                  dispatch(resetFiltriProgetti());
                  dispatch(resetIndex());
                }
              }
            })
            .catch((e) => {
              dispatch(
                setNotification({
                  tipo: notificationCodes.ERROR,
                  message: "Errore durante il salvataggio dei dati della sezione anagrafica ",
                  description: "Si è verificato un errore imprevisto nel salvataggio dei dati della sezione anagrafica",
                })
              );
            });
        } else {
          createRequest(PUT_ANAGRAFICA_CURRICOLARE.method, PUT_ANAGRAFICA_CURRICOLARE.baseUrl, PUT_ANAGRAFICA_CURRICOLARE.apiUrl, null, dataPost)
            .then((response) => {
              if (response.status === 200) {
                if (buttonType === "AVANTI") {
                  if (index !== null) {
                    let versione = true;
                    dispatch(changetabs({ index, versione }));
                  } else {
                    dispatch(checkIndex("4"));
                  }
                } else {
                  createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
                    uuid_progetto: progetto.length === undefined ? progetto.uuid_progetto : idProg,
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        navigate(PATH_HOME);
                        dispatch(resetFiltriProgetti());
                        dispatch(resetIndex());
                      }
                    })
                    .catch((e) => {
                      dispatch(
                        setNotification({
                          tipo: notificationCodes.ERROR,
                          message: "Errore durante il cambio stato del progetto in modifica",
                          description: "Errore durante il cambio stato del progetto in modifica",
                        })
                      );
                    });
                }
              }
            })
            .catch((e) => {
              dispatch(
                setNotification({
                  tipo: notificationCodes.ERROR,
                  message: "Errore durante il salvataggio dei dati della sezione anagrafica ",
                  description: "Si è verificato un errore imprevisto nel salvataggio dei dati della sezione anagrafica",
                })
              );
            });
        }
      }
    },
  });

  //ONCHANGE DATI FORM
  const onDatiChange = (modifiche) => {
    // eslint-disable-next-line
    modifiche.map((modifica) => {
      const { fieldName, fieldValue } = modifica;
      formik.setFieldValue(fieldName, fieldValue);
      dispatch(saveValueProgetto(modifica));
    });
  };

  return (
    <form onSubmit={formik.handleSubmit} id="myform">
      <ModaleSpostamentoTab
        setTabSpostamentoAttivita={null}
        stateModal={props.modal}
        setModalSpostamentoTab={props.setModalSpostamentoTab}
        onSave={formik.handleSubmit}
        uuid={progetto.data === undefined ? progetto.uuid_progetto : idProg}
      />
      <Grid container spacing={2}>
        {/* Form */}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} xl={4}>
              <Input
                label={TITOLO_PROGETTO + "*"}
                placeholder={PLACEHOLDER_TITOLO_PROGETTO}
                value={formik.values.titolo_progetto}
                numeroCaratteri={200}
                infoNumeroCaratteri={true}
                onChange={(e) => {
                  onDatiChange([{ fieldSection: "anagrafica", fieldName: "titolo_progetto", fieldValue: e.target.value }]);
                }}
                {...(formik.touched["titolo_progetto"] &&
                  Boolean(formik.errors["titolo_progetto"]) && {
                    error: true,
                    errorText: REQUIRED_FIELD,
                  })}
              />
              <Input
                label={ABSTRACT + "*"}
                rows={5.3}
                placeholder={PLACEHOLDER_ABSTRACT}
                numeroCaratteri={500}
                infoNumeroCaratteri={true}
                value={formik.values.descr_progetto}
                multiline={true}
                onChange={(e) => {
                  onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_progetto", fieldValue: e.target.value }]);
                }}
                {...(formik.touched["descr_progetto"] &&
                  Boolean(formik.errors["descr_progetto"]) && {
                    error: true,
                    errorText: REQUIRED_FIELD,
                  })}
              />
              <Box>
                <Input
                  label={NOTE}
                  numeroCaratteri={500}
                  infoNumeroCaratteri={true}
                  rows={5.3}
                  placeholder={PLACEHOLDER_DESCRIVI}
                  value={formik.values.annote}
                  multiline={true}
                  onChange={(e) => {
                    onDatiChange([{ fieldSection: "anagrafica", fieldName: "annote", fieldValue: e.target.value }]);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={4}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Calendar
                    tipo={"curricolare"}
                    label={DATA_INIZIO_PROGETTO + "*"}
                    placeholder={PLACEHOLDER_DATA}
                    name={"data_inizio"}
                    errorDateText={ERROR_DATE}
                    errorText={REQUIRED_FIELD}
                    formik={formik}
                    section={"anagrafica"}
                    errorDate={() => checkDate()}
                    handle={formik.handleChange("data_inizio")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Select
                    label={TIPOLOGIA_PROGETTO + "*"}
                    placeholder={PLACEHOLDER_TIPOLOGIA_PROGETTO}
                    options={allOptionsFiltered.tipologieProgetto}
                    value={
                      formik.values.descr_t_progetto !== ""
                        ? allOptionsFiltered.tipologieProgetto.find((e) => e.id === formik.values.descr_t_progetto)?.id
                        : null
                    }
                    onChange={(e, v) => {
                      const { value: id, children: descrizione } = v.props;
                      onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_t_progetto", fieldValue: id }]);
                      dispatch(titleSet(descrizione));
                    }}
                    {...(formik.touched["descr_t_progetto"] &&
                      Boolean(formik.errors["descr_t_progetto"]) && {
                        error: true,
                        errorText: REQUIRED_FIELD,
                      })}
                  />
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} xl={6}>
                      <Input
                        numeroCaratteri={3}
                        label={NUMERO_STUDENTI + "*"}
                        placeholder={PLACEHOLDER_NUMERO}
                        value={formik.values.num_alunni_imp}
                        onChange={(e) => {
                          onDatiChange([
                            { fieldSection: "anagrafica", fieldName: "num_alunni_imp", fieldValue: isNaN(e.target.value) ? "" : e.target.value },
                          ]);
                        }}
                        {...(formik.touched["num_alunni_imp"] &&
                          Boolean(formik.errors["num_alunni_imp"]) && {
                            error: true,
                            errorText: REQUIRED_FIELD,
                          })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6}>
                      <Select
                        label={STUDENTI_BES}
                        placeholder={PLACEHOLDER_STUDENTI_BES}
                        value={
                          formik.values.descr_studenti_bes !== ""
                            ? allOptionsFiltered.tipologieStudentiBes.find((e) => e.id === formik.values.descr_studenti_bes)?.id
                            : null
                        }
                        options={allOptionsFiltered.tipologieStudentiBes}
                        onChange={(e, v) => {
                          const { value: id } = v.props;
                          onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_studenti_bes", fieldValue: id }]);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Select
                    label={COMPOSIZIONE_GRUPPO_STUDENTI + "*"}
                    placeholder={PLACEHOLDER_COMPOSIZIONE_STUDENTI}
                    options={allOptionsFiltered.composizioneGruppoStudenti}
                    value={
                      formik.values.descr_composizione_gruppo !== ""
                        ? allOptionsFiltered.composizioneGruppoStudenti.find((e) => e.id === formik.values.descr_composizione_gruppo)?.id
                        : null
                    }
                    onChange={(e, v) => {
                      const { value: id } = v.props;
                      onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_composizione_gruppo", fieldValue: id }]);
                    }}
                    {...(formik.touched["descr_composizione_gruppo"] &&
                      Boolean(formik.errors["descr_composizione_gruppo"]) && {
                        error: true,
                        errorText: REQUIRED_FIELD,
                      })}
                  />
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Select
                    label={TEMA_PREVALENTE + "*"}
                    placeholder={PLACEHOLDER_TEMA_PREVALENTE}
                    options={allOptionsFiltered.tematicheProgetto}
                    value={
                      formik.values.descr_tipo_attributo !== ""
                        ? allOptionsFiltered.tematicheProgetto.find((e) => e.id === formik.values.descr_tipo_attributo)?.id
                        : null
                    }
                    onChange={(e, v) => {
                      const { value: id } = v.props;
                      onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_tipo_attributo", fieldValue: id }]);
                    }}
                    {...(formik.touched["descr_tipo_attributo"] &&
                      Boolean(formik.errors["descr_tipo_attributo"]) && {
                        error: true,
                        errorText: REQUIRED_FIELD,
                      })}
                  />
                </Grid>{" "}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={12} xl={12}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          "@media (max-width:900px)": { marginTop: "-100px" },
                          "@media (max-width:600px)": { marginTop: "0px" },
                        }}
                      >
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <Autocomplete
                            label={ORDINE_SCUOLA + "*"}
                            placeholder={PLACEHOLDER_ORDINE_SCUOLA}
                            options={allOptionsFiltered.ordiniScuola}
                            value={
                              formik.values.descr_grado_scuola !== ""
                                ? allOptionsFiltered.ordiniScuola.find((e) => e.id === formik.values.descr_grado_scuola)
                                : null
                            }
                            onChange={(e, v) => {
                              const { descrizione, id } = v;
                              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_grado_scuola", fieldValue: id }]);
                              if (descrizione !== PLACEHOLDER_ORDINE_SCUOLA) {
                                dispatch(fetchDisciplineCoinvolte("/" + id));
                              }
                            }}
                            {...(formik.touched["descr_grado_scuola"] &&
                              Boolean(formik.errors["descr_grado_scuola"]) && {
                                error: true,
                                errorText: REQUIRED_FIELD,
                              })}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <Select
                            label={TEMPO_REALIZZAZIONE + "*"}
                            placeholder={PLACEHOLDER_TEMPO_REALIZZAZIONE}
                            options={allOptionsFiltered.tempoRealizzazioneProgetto}
                            value={
                              formik.values.descr_tempo_realiz !== ""
                                ? allOptionsFiltered.tempoRealizzazioneProgetto.find((e) => e.id === formik.values.descr_tempo_realiz)?.id
                                : null
                            }
                            onChange={(e, v) => {
                              const { value: id } = v.props;
                              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_tempo_realiz", fieldValue: id }]);
                            }}
                            {...(formik.touched["descr_tempo_realiz"] &&
                              Boolean(formik.errors["descr_tempo_realiz"]) && {
                                error: true,
                                errorText: REQUIRED_FIELD,
                              })}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <Select
                            label={ANNO_REALIZZAZIONE + "*"}
                            placeholder={PLACEHOLDER_ANNO_REALIZZAZIONE}
                            options={allOptionsFiltered.anniScolasticiRealizzazione}
                            value={
                              formik.values.descr_anno_realiz !== ""
                                ? allOptionsFiltered.anniScolasticiRealizzazione.find((e) => e.id === formik.values.descr_anno_realiz)?.id
                                : null
                            }
                            onChange={(e, v) => {
                              const { value: id } = v.props;
                              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_anno_realiz", fieldValue: id }]);
                            }}
                            {...(formik.touched["descr_anno_realiz"] &&
                              Boolean(formik.errors["descr_anno_realiz"]) && {
                                error: true,
                                errorText: REQUIRED_FIELD,
                              })}
                          />
                        </Grid>{" "}
                      </Grid>{" "}
                    </Grid>{" "}
                    <Grid item xs={12} sm={6} md={12} xl={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <Autocomplete
                            label={ATTIVITA_DIGITALE + "*"}
                            placeholder={PLACEHOLDER_ATTIVITA_DIGITALE}
                            options={allOptionsFiltered.attivitaDigitali}
                            value={
                              formik.values.descr_attivita_digitale !== ""
                                ? allOptionsFiltered.attivitaDigitali.find((e) => e.id === formik.values.descr_attivita_digitale)
                                : null
                            }
                            onChange={(e, v) => {
                              const { id } = v;
                              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_attivita_digitale", fieldValue: id }]);
                            }}
                            {...(formik.touched["descr_attivita_digitale"] &&
                              Boolean(formik.errors["descr_attivita_digitale"]) && {
                                error: true,
                                errorText: REQUIRED_FIELD,
                              })}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                          <Select
                            label={CO_RESPONSABILE}
                            placeholder={PLACEHOLDER_TEAM_DIGITALE}
                            options={allOptionsFiltered.coResponsabili}
                            value={
                              formik.values.CoResponsabile !== ""
                                ? allOptionsFiltered.coResponsabili.find((e) => e.id === formik.values.CoResponsabile)?.id
                                : null
                            }
                            onChange={(e, v) => {
                              const { value: id } = v.props;
                              onDatiChange([{ fieldSection: "anagrafica", fieldName: "CoResponsabile", fieldValue: id }]);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FooterInserisciProgetti onSave={formik.handleSubmit} percentuale={Math.floor(progress)} />
      <Button type="submit" sx={{ display: "none" }}>
        Salva
      </Button>
    </form>
  );
};
