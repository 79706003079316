import { useNavigate } from "react-router-dom";
import { Box, Grid, Button, Typography } from "@mui/material";
import { PATH_ISTITUTI_SCOLASTICI, PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI, PATH_PROGETTI_INNOVAZIONE_SCUOLA } from "../../../path";
import { CercaIcon } from "../../../styles/icons";
import { labels } from "../../../labels";
import { HeaderPage } from "../../shared/Header";
import { useDispatch } from "react-redux";
import { resetFiltriProgetti, setThemeColor } from "../../../modules/configuration";
import { useEffect } from "react";

const { ISTITUTI_SCOLASTICI, PROGETTI_CURRICOLARE, PROGETTI_INNOVAZIONE, BUTTON_RICERCA } = labels;

export const Ricerca = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetFiltriProgetti());
    // eslint-disable-next-line
  }, []);

  //setto il colore del tema da seguire nelle pagine successive
  useEffect(() => {
    dispatch(setThemeColor({ color1: "#8E2155 !important", color2: "#DEA4BD !important" }));
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="ricerca">
      <HeaderPage icon={<CercaIcon />} title={BUTTON_RICERCA} color={"#8E2155"} />
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <Button variant="outlined" disableRipple className="buttonsSezioni" onClick={() => navigate(PATH_ISTITUTI_SCOLASTICI)}>
          <Typography className="textButtonsSezioni">{ISTITUTI_SCOLASTICI}</Typography>
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} xl={6} sx={{ marginTop: "20px" }}>
            <Button
              variant="outlined"
              disableRipple
              className="buttonsSezioni"
              onClick={() => navigate(PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI)}
            >
              <Typography className="textButtonsSezioni">{PROGETTI_CURRICOLARE}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6} xl={6} sx={{ marginTop: "10px" }}>
            <Button variant="outlined" disableRipple className="buttonsSezioni" onClick={() => navigate(PATH_PROGETTI_INNOVAZIONE_SCUOLA)}>
              <Typography className="textButtonsSezioni">{PROGETTI_INNOVAZIONE}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
