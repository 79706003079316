import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Table, Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Link } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { KeyboardArrowDown as KeyboardArrowDownIcon, MobileScreenShareOutlined } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import { Tipologia, Titolo, DataInserimento } from "../shared";
import {
  setDropDown,
  setDropDown2,
  setDropDown3,
  getDropDown,
  getDropDown2,
  getDropDown3,
  getCountPagination,
  aggiornaValoriSlice,
  setCountPagination,
  getDropDownCompletameto,
  setDropDownCompletamento,
  getFilterSort,
  getFilterDataInizio,
  getFilterTipologia,
  getFilterDataInizioOrdine,
  getFilterCompletamento,
  getFilterTitle,
} from "../../modules/configuration";
import { Progress } from "../shared/Progress";
import { PATH_SCHEDA_ISTITUTO, PATH_SCRIVANIA } from "../../path";
import { useEffect, useState } from "react";
import { PaginationShared } from "../shared/Pagination";
import { Completamento } from "../shared/componentiDropDown/Completamento";
import { labels } from "../../labels";
import { DettagliLink } from "../pages/dettagliProgetto/DettagliLink";

const {
  COMPLETAMENTO,
  TIPOLOGIA_PROGETTO,
  TITOLO_PROGETTO,
  DATA_INSERIMENTO,
  DETTAGLI,
  AZIONI,
  AVVISO_ASSENZA_PROGETTI,
  BUTTON_CONDIVIDI,
  BUTTON_SCARICA,
} = labels;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: "10px",
  },
}));

export const format = function date2str(x, y) {
  var z = {
    M: x.getMonth() + 1,
    d: x.getDate(),
    h: x.getHours(),
    m: x.getMinutes(),
    s: x.getSeconds(),
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
    return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
  });

  return y.replace(/(y+)/g, function (v) {
    return x.getFullYear().toString().slice(-v.length);
  });
};

export const TableSchedaProfilo = (props) => {
  const dispatch = useDispatch();
  const dropdownCompletamento = useSelector(getDropDownCompletameto);
  const dropdown = useSelector(getDropDown);
  const dropdown2 = useSelector(getDropDown2);
  const dropdown3 = useSelector(getDropDown3);
  let progetti = props.progetti;
  let istituti = props.istituto;
  let rows;
  const [page, setPage] = useState(1);
  const countPagination = useSelector(getCountPagination);
  const filterSort = useSelector(getFilterSort);
  const filterDataInizio = useSelector(getFilterDataInizio);
  const filterTipologia = useSelector(getFilterTipologia);
  const filterDataInizioOrdine = useSelector(getFilterDataInizioOrdine);
  const filterCompletamento = useSelector(getFilterCompletamento);
  const filterTitle = useSelector(getFilterTitle);

  function handlechange(e, value) {
    dispatch(aggiornaValoriSlice(value));
    setPage(value);
  }

  //in base al numero di progetti stabilisco numero pagine
  useEffect(() => {
    //set count pagination
    if (progetti !== null) dispatch(setCountPagination(Math.ceil(progetti.length / 5)));
    // eslint-disable-next-line
  }, [progetti]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: props.color2,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  //filtro tipologia
  if (filterTipologia !== "") {
    progetti = progetti.filter((opt) => opt.descr_t_progetto === filterTipologia);
  }

  //filtro riordinamento alfabetico Denominazione scuola
  if (filterSort === true) {
    function SortArray(x, y) {
      return x.denominazione_istituto.localeCompare(y.denominazione_istituto, "fr", {
        ignorePunctuation: true,
      });
    }
    progetti = [...progetti].sort(SortArray);
  }

  //filtro riordinamento alfabetico titolo
  if (filterTitle === true) {
    function SortArray(x, y) {
      return x.titolo_progetto.localeCompare(y.titolo_progetto, "fr", {
        ignorePunctuation: true,
      });
    }
    if (progetti !== null) progetti = [...progetti].sort(SortArray);
  }

  //filtro riordinamento completamento
  if (filterCompletamento === "Ordina dal più completo") {
    function SortArray(x, y) {
      let varx = x.completamento;
      let vary = y.completamento;
      return vary - varx;
    }
    progetti = [...progetti].sort(SortArray);
  }

  if (filterCompletamento === "Ordina dal meno completo") {
    function SortArray(x, y) {
      let varx = x.completamento;
      let vary = y.completamento;
      return varx - vary;
    }
    progetti = [...progetti].sort(SortArray);
  }

  //filtro riordinamento data inserimento dec e crescente
  if (filterDataInizio === true) {
    function SortArray(x, y) {
      let varx = x.data_inserimento;
      let vary = y.data_inserimento;
      if (x.data_inserimento === null) {
        varx = "1900-01-01 00:00:00";
      }
      return new Date(varx) - new Date(vary);
    }
    progetti = [...progetti].sort(SortArray);

    if (filterDataInizioOrdine !== "dataMenoRec") {
      progetti = progetti.reverse();
    }
  }

  let createData = (completamento, denominazioneIstituto, tipologia, titolo, dataInserimento, dettagli, azioni) => {
    return {
      completamento,
      denominazioneIstituto,
      tipologia,
      titolo,
      dataInserimento,
      dettagli,
      azioni,
    };
  };

  rows =
    progetti !== null
      ? progetti.map((elem, index) =>
          createData(
            <Progress progress={elem.completamento} />,
            elem.descr_t_progetto,
            elem.titolo_progetto,
            format(new Date(elem.data_inserimento), "dd-MM-yyyy"),
            <DettagliLink indexTabs={props.indexTabs} path={istituti === true ? PATH_SCHEDA_ISTITUTO : PATH_SCRIVANIA} id={elem.uuid_progetto} />,
            <Grid container>
              <Grid item xs={6}>
                <Link
                  sx={{
                    color: "black",
                    cursor: "pointer",
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=true", "_blank")}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <PrintIcon sx={{ color: "black" }} />
                    </Grid>

                    <Grid item xs={12}>
                      {BUTTON_SCARICA}
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  sx={{
                    color: "black",
                    cursor: "pointer",
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=false", "_blank")}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <MobileScreenShareOutlined sx={{ color: "black" }} />
                    </Grid>
                    <Grid item xs={12}>
                      {BUTTON_CONDIVIDI}
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          )
        )
      : null;

  return (
    <Grid container className="gestisciProgetti">
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{
            "@media (max-width:1024px)": { display: "none" },
          }}
        >
          <Table sx={{ minWidth: 700, cursor: "default" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" sx={{ width: "180px" }}>
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Typography className="titleColumnTable">{COMPLETAMENTO}</Typography>
                      {dropdownCompletamento ? <Completamento /> : null}
                    </Grid>
                    <Grid item>
                      <KeyboardArrowDownIcon onClick={() => dispatch(setDropDownCompletamento())} sx={{ cursor: "pointer" }} />
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "190px" }}>
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Typography className="titleColumnTable">{TIPOLOGIA_PROGETTO}</Typography>
                      {dropdown ? <Tipologia /> : null}
                    </Grid>
                    <Grid item>
                      <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown())} sx={{ cursor: "pointer" }} />
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "400px" }}>
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Typography className="titleColumnTable">{TITOLO_PROGETTO}</Typography>
                      {dropdown2 ? <Titolo dropDown={setDropDown2()} /> : null}
                    </Grid>
                    <Grid item>
                      <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown2())} sx={{ cursor: "pointer" }} />
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "190px" }}>
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Typography className="titleColumnTable">{DATA_INSERIMENTO}</Typography>
                      {dropdown3 ? <DataInserimento dropDown={setDropDown3()} /> : null}
                    </Grid>
                    <Grid item>
                      <KeyboardArrowDownIcon onClick={() => dispatch(setDropDown3())} sx={{ cursor: "pointer" }} />
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "150px" }}>
                  <Typography className="titleColumnTable">{DETTAGLI}</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography className="titleColumnTable"> {AZIONI}</Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows !== null
                ? rows.slice(props.valore1slice, props.valore2slice).map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">{row.completamento}</StyledTableCell>
                      <StyledTableCell align="center">{row.denominazioneIstituto}</StyledTableCell>
                      <StyledTableCell align="center">{row.tipologia}</StyledTableCell>
                      <StyledTableCell align="center">{row.titolo}</StyledTableCell>
                      <StyledTableCell align="center">{row.dataInserimento}</StyledTableCell>
                      <StyledTableCell align="center">{row.dettagli}</StyledTableCell>
                      <StyledTableCell align="center">{row.azioni}</StyledTableCell>
                    </StyledTableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", "@media (max-width:900px)": { display: "none" } }}>
        {progetti === null ? (
          <Typography sx={{ fontSize: "22px", fontWeight: "Bold", marginTop: "20px" }}>{AVVISO_ASSENZA_PROGETTI}</Typography>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ justifyContent: "center", display: "flex", marginTop: "20px", "@media (max-width:900px)": { display: "none" } }}>
          {countPagination > 1 ? <PaginationShared page={page} handlechange={handlechange} color={props.color1} count={countPagination} /> : null}
        </Box>
      </Grid>
    </Grid>
  );
};
