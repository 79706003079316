import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";

import { REDUX_DISCIPLINE_COINVOLTE, REDUX_COMUNE, REDUX_ATTIVITA_INNOVAZIONE, REDUX_UUID_PROGETTO, REDUX_ID_PROGETTO, REDUX_SCHEDA_PROFILO } from "../../constants";
import {
  fetchAttivitaInnovazioneFailed,
  fetchAttivitaInnovazioneSuccess,
  fetchCodiceProgettoFailed,
  fetchCodiceProgettoSuccess,
  fetchComuneFailed,
  fetchComuneSuccess,
  fetchDisciplineCoinvolteFailed,
  fetchDisciplineCoinvolteSuccess,
  fetchIdNuovoProgettoFailed,
  fetchIdNuovoProgettoSuccess,
  fetchSchedaProfiloFailed,
  fetchSchedaProfiloSuccess,
} from "./";

const initialState = {
  [REDUX_DISCIPLINE_COINVOLTE]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_ATTIVITA_INNOVAZIONE]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_COMUNE]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_UUID_PROGETTO]: {
    data: "",
    fetchEnded: false,
  },

  [REDUX_ID_PROGETTO]: {
    data: "",
    fetchEnded: false,
  },

  [REDUX_SCHEDA_PROFILO]: {
    data: "",
    fetchEnded: false,
  },
};

const fetchComuneSucc = (state, action) => {
  return update(state, {
    [REDUX_COMUNE]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchComuneFail = (state, action) => {
  return update(state, {
    [REDUX_COMUNE]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchDisciplineCoinvolteSucc = (state, action) => {
  return update(state, {
    [REDUX_DISCIPLINE_COINVOLTE]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchDisciplineCoinvolteFail = (state, action) => {
  return update(state, {
    [REDUX_DISCIPLINE_COINVOLTE]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCodiceProgettoSucc = (state, action) => {
  return update(state, {
    [REDUX_UUID_PROGETTO]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCodiceProgettoFail = (state, action) => {
  return update(state, {
    [REDUX_UUID_PROGETTO]: {
      data: { $set: "" },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaInnovazioneSucc = (state, action) => {
  return update(state, {
    [REDUX_ATTIVITA_INNOVAZIONE]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaInnovazioneFail = (state, action) => {
  return update(state, {
    [REDUX_ATTIVITA_INNOVAZIONE]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIdNuovoProgettoSuccessHandler = (state, action) => {
  return update(state, {
    [REDUX_ID_PROGETTO]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIdNuovoProgettoFailedHandler = (state, action) => {
  return update(state, {
    [REDUX_ID_PROGETTO]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchSchedaProfiloSuccessHandler = (state, action) => {
  return update(state, {
    [REDUX_SCHEDA_PROFILO]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchSchedaProfiloFailedHandler = (state, action) => {
  return update(state, {
    [REDUX_SCHEDA_PROFILO]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

export const datiOsservatorioSlice = createSlice({
  name: "datiOsservatorio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchComuneSuccess, (state, action) => fetchComuneSucc(state, action));
    builder.addCase(fetchComuneFailed, (state, action) => fetchComuneFail(state, action));
    builder.addCase(fetchDisciplineCoinvolteSuccess, (state, action) => fetchDisciplineCoinvolteSucc(state, action));
    builder.addCase(fetchDisciplineCoinvolteFailed, (state, action) => fetchDisciplineCoinvolteFail(state, action));
    builder.addCase(fetchCodiceProgettoSuccess, (state, action) => fetchCodiceProgettoSucc(state, action));
    builder.addCase(fetchCodiceProgettoFailed, (state, action) => fetchCodiceProgettoFail(state, action));
    builder.addCase(fetchAttivitaInnovazioneSuccess, (state, action) => fetchAttivitaInnovazioneSucc(state, action));
    builder.addCase(fetchAttivitaInnovazioneFailed, (state, action) => fetchAttivitaInnovazioneFail(state, action));
    builder.addCase(fetchIdNuovoProgettoSuccess, (state, action) => fetchIdNuovoProgettoSuccessHandler(state, action));
    builder.addCase(fetchIdNuovoProgettoFailed, (state, action) => fetchIdNuovoProgettoFailedHandler(state, action));
    builder.addCase(fetchSchedaProfiloSuccess, (state, action) => fetchSchedaProfiloSuccessHandler(state, action));
    builder.addCase(fetchSchedaProfiloFailed, (state, action) => fetchSchedaProfiloFailedHandler(state, action));
  },
});

export const datiOsservatorioReducer = datiOsservatorioSlice.reducer;
