import { GET, POST, PUT } from "./utility";

const GATEWAY_URL = "/auth";
// const PRIVATE_BASE_URL = GATEWAY_URL + "/api";
export const PRIVATE_BASE_URL = GATEWAY_URL + "/api/scuoladigitaleliguria/osservatorio/v1";
// export const PRIVATE_BASE_URL = "http://localhost:8080/v1";

// Modulo auth
export const USER_INFO_API = {
  method: GET,
  baseUrl: GATEWAY_URL,
  apiUrl: "/userInfo",
};

export const USER_AUTHENTICATED_API = {
  method: GET,
  baseUrl: GATEWAY_URL,
  apiUrl: "/isAuthenticated",
};

export const SESSION_INFO_API = {
  method: GET,
  baseUrl: GATEWAY_URL,
  apiUrl: "/sessionInfo",
};

// Modulo profilo
export const PROFILO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/getProfilo",
};

export const CHECK_PRIVACY_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/checkPrivacy",
};

export const SET_PRIVACY_API = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/setPrivacy",
};

export const VERIFICA_RUOLO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/checkUnicita",
};

export const AGGIORNA_PROFILO_API = {
  method: PUT,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/putProfilo",
};

export const AGGIORNA_RUOLO_ISTITUTO_API = {
  method: PUT,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/putRuoloIstituto",
};

export const DISABILITA_PROFILO_API = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/disabilita",
};

// Modulo common
export const VALUTAZIONE_EFFICACIA_PROGETTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/valutazioneEfficaciaProgetto",
};

export const TIPOLOGIA_PROGETTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/tipologiaProgetto",
};

export const TIPOLOGIA_ISTITUTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/tipologiaIstituto",
};

export const DOCUMENTAZIONE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/documentazione",
};

export const TIPOLOGIA_STUDENTI_BES_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/tipologiaStudentiBes",
};

export const STATO_PUBBLICAZIONE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/statoPubblicazione",
};

export const PROVINCE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/province",
};

export const ISTITUTI_SCOLASTICI_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/istitutiScolastici",
};

export const TIPOLOGIA_DOCUMENTAZIONE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/tipologiaDocumentazione",
};

export const CONTESTO_ACCOMPAGNAMENTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/contestoAccompagnamento",
};

export const INCARICHI_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/incarichi",
};

export const INDIRIZZO_QUALIFICA_ISTITUTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/indirizzoQualificaIstituto",
};

export const ATTIVITA_RICERCA_CURRICOLARI_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/attivitaCurricolariRicerca ",
};

export const ATTIVITA_CURRICOLARI_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/attivitaCurricolari",
};

export const ATTIVITA_CLIL_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/attivitaClil",
};

export const TIPOLOGIA_BENEFICIARIO_PROGETTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/tipologiaBeneficiarioProgetto",
};

export const TEMPO_REALIZZAZIONE_PROGETTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/tempoRealizzazioneProgetto",
};

export const TEMATICA_PROGETTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/tematicaProgetto",
};

export const ORDINE_SCUOLA_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/ordineScuola",
};

export const REFERENTI_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/referente",
};

export const FONDI_CONTESTO_PROGETTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/fondiContestoProgetto",
};

export const COMPOSIZIONE_GRUPPO_STUDENTI_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/composizioneGruppoStudenti",
};

export const ATTIVITA_DIGITALE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/attivitaDigitale",
};

export const CO_RESPONSABILE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/coResponsabileProgetto",
};

export const CO_REFERENTI_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/coReferente",
};

export const AREA_COINVOLTA_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/areaCoinvolta",
};

export const ANNO_SCOLASTICO_REALIZZAZIONE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/annoScolasticoRealizzazione",
};

export const TITOLO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/titolo",
};

export const AZIONI_DIFFUSIONE_DISSEMINAZIONE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/azioniDiffusioneDisseminazione",
};

export const ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/valutazioneEfficaciaProgetto",
};

// Modulo datiOsservatorio
export const ID_NUOVO_PROGETTO_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/idNuovoProgetto",
};

export const ATTIVITA_INNOVAZIONE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/attivitaInnovazione",
};

export const COMUNE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/comune",
};

export const DISCIPLINE_COINVOLTE_API = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/disciplineCoinvolte",
};

//api istituto
export const GET_ISTITUTO = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/istituto",
};
export const GET_PLESSI_ISTITUTO = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/plessiIstituto",
};
export const GET_PROFILI_IN_USCITA = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/profiliInUscita",
};
export const GET_MEMBRI_TEAM = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/membriTeam",
};
//Api Gestisci Progetti

export const GET_PROGETTI_BOZZA = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/progettiBozza",
};

export const GET_PROGETTI_CONCLUSI = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/progettiConclusi",
};

export const GET_PROGETTI_ISTITUTO = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/progettiIstituto",
};

// Api ricerca Istituti Scolastici

export const GET_PROVINCIA = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/provincia",
};

export const GET_TIPOLOGIA_ISTITUTO = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/tipologiaIstituto",
};

export const GET_COMUNE = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/comune",
};

export const GET_ISTITUTI_SCOLASTICI = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/istitutiScolastici",
};

export const GET_PROGETTI_CURRICOLARI = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/progetti",
};

export const GET_PROGETTI_INNOVAZIONE = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/progettiInnovazioneScuola",
};

export const GET_DETTAGLI_PROGETTI = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/dettagliProgetto",
};

export const GET_INDIRIZZI_ISTSCOLASTICI = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/getIndirizzi",
};

export const DELETE_PROGETTO = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/deleteProgetto/",
};

export const POST_ANAGRAFICA_CURRICOLARE = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/setAnagraficaCurricolareExtraCurricolare",
};

export const PUT_ANAGRAFICA_CURRICOLARE = {
  method: PUT,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/putAnagraficaCurricolareExtraCurricolare",
};
export const POST_ANAGRAFICA_INNOVAZIONE = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/setAnagraficaInnovazione",
};

export const PUT_ANAGRAFICA_INNOVAZIONE = {
  method: PUT,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/putAnagraficaInnovazione",
};

export const PUT_BLOCCO_MODIFICA = {
  method: PUT,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/bloccoModificaProgetto",
};

export const PUT_SBLOCCO_MODIFICA = {
  method: PUT,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/sbloccoModificaProgetto",
};

export const POST_ATTIVITA = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/attivitaCurricolare",
};
export const POST_ATTIVITA_INNOVAZIONE = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/attivitaInnovazione",
};

export const POST_CONTESTO = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/setContesto",
};

export const POST_DOCUMENTAZIONE = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/setDocumentazione",
};

export const POST_PUBBLICAZIONE = {
  method: POST,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/setPubblicazione",
};


//servizio scheda profilo
export const GET_SCHEDA_PROFILO = {
  method: GET,
  baseUrl: PRIVATE_BASE_URL,
  apiUrl: "/getProfiloCliccato",
};
