import { Grid, Link, Typography } from "@mui/material";
import { labels } from "../../../labels";

const { DOCUMENTAZIONE, LINK_DOCUMENTAZIONE, VALUTAZIONE, AZIONE_DIFF_DISSEMINAZIONE, PUNTI_DEBOLEZZA, PUNTI_FORZA } = labels;

export const DocumentazioneDett = (props) => {
  let progetto = props.progetto;
  let attivita = progetto.attivita.filter((elem) => elem.id_campo === props.tipologiaProg);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} xl={6}>
        <Typography className="textTitle">{DOCUMENTAZIONE}</Typography>
        {progetto.documentazione.length !== 0 ? (
          progetto.documentazione.map((elem, index) => (
            <Typography key={index} className="dato">
              {elem.descrizione}
            </Typography>
          ))
        ) : (
          <Typography className="dato">-</Typography>
        )}
        <Typography className="textTitle">{LINK_DOCUMENTAZIONE}</Typography>
        <Link
          sx={{ fontSize: "18px", wordWrap: "break-word" }}
          onClick={() => (progetto.link_documentazione !== null ? window.open(progetto.link_documentazione) : null)}
        >
          <Typography className="dato" sx={{ cursor: "pointer !important" }}>
            {progetto.link_documentazione !== null ? progetto.link_documentazione : "-"}
          </Typography>{" "}
        </Link>
        <Typography className="textTitle">{AZIONE_DIFF_DISSEMINAZIONE}</Typography>
        {attivita.length !== 0 ? (
          attivita.map((elem, index) => (
            <Typography className="dato" key={index}>
              {elem.descr_valore}
            </Typography>
          ))
        ) : (
          <Typography className="dato">-</Typography>
        )}{" "}
      </Grid>
      <Grid item xs={12} sm={12} md={6} xl={6} sx={{ "@media (max-width:460px)": { marginTop: "40px" } }}>
        <Typography className="textTitle">{VALUTAZIONE}</Typography>
        <Typography className="dato">
          {progetto.descr_valutazione === null ? <Typography className="dato">-</Typography> : progetto.descr_valutazione}{" "}
        </Typography>
        <Typography className="textTitle">{PUNTI_FORZA}</Typography>
        <Typography className="dato">
          {progetto.punti_forza === null ? <Typography className="dato">-</Typography> : progetto.punti_forza}{" "}
        </Typography>
        <Typography className="textTitle">{PUNTI_DEBOLEZZA}</Typography>
        <Typography className="dato">
          {progetto.punti_debolezza === null ? <Typography className="dato">-</Typography> : progetto.punti_debolezza}{" "}
        </Typography>
      </Grid>
    </Grid>
  );
};
