import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  fetchSezioneMieiProgetti,
  fetchSezioneProgettiIstituto,
  fetchSezioneProgettiCurricolariExtraCurricolari,
  fetchDettagliProgetto,
  fetchProgettiIstitutoSuccess,
  fetchProgettiIstitutoFailed,
  fetchDettagliProgettoSuccess,
  fetchDettagliProgettoFiled,
  fetchProgettiBozzaSuccess,
  fetchProgettiBozzaFailed,
  fetchProgettiConclusiSuccess,
  fetchProgettiConclusiFailed,
  fetchProgettiCurricolariExtraCurricolariSuccess,
  fetchProgettiCurricolariExtraCurricolariFailed,
  changeDataProgetto,
  changeDataProgettoCurr,
} from "./";

import { GET_DETTAGLI_PROGETTI, GET_PROGETTI_BOZZA, GET_PROGETTI_CONCLUSI, GET_PROGETTI_CURRICOLARI, GET_PROGETTI_ISTITUTO } from "../../apiUrl";
import { createRequest } from "../../utility/createRequest";
import { labels } from "../../labels";
import { notificationCodes, setNotification } from "../notification";
import { setProgettoCurr, setProgettoInn } from "../configuration";

const {
  SAGAS_PROGETTI_TITLE,
  SAGAS_PROGETTI_DESCRIPTION,
  SAGAS_PROGETTI_BOZZA_TITLE,
  SAGAS_PROGETTI_BOZZA_DESCRIPTION,
  SAGAS_PROGETTI_CONCLUSI_TITLE,
  SAGAS_PROGETTI_CONCLUSI_DESCRIPTION,
  SAGAS_PROGETTI_ISTITUTO_TITLE,
  SAGAS_PROGETTI_ISTITUTO_DESCRIPTION,
  SAGAS_DETTAGLI_PROGETTO_TITLE,
  SAGAS_DETTAGLI_PROGETTO_DESCRIPTION,
} = labels;

const fetchProgettiCurricolariExtraCurricolari = (listenerApi) => {
  createRequest(GET_PROGETTI_CURRICOLARI.method, GET_PROGETTI_CURRICOLARI.baseUrl, GET_PROGETTI_CURRICOLARI.apiUrl, {
    responseType: "json",
    decompress: true,
  })
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchProgettiCurricolariExtraCurricolariSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_PROGETTI_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_PROGETTI_TITLE , description: SAGAS_PROGETTI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchProgettiCurricolariExtraCurricolariFailed());
    });
};

const fetchProgettiBozza = (action, listenerApi) => {
  createRequest(GET_PROGETTI_BOZZA.method, GET_PROGETTI_BOZZA.baseUrl, GET_PROGETTI_BOZZA.apiUrl, {
    responseType: "json",
    decompress: true,
  })
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchProgettiBozzaSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_PROGETTI_BOZZA_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_PROGETTI_BOZZA_TITLE, description: SAGAS_PROGETTI_BOZZA_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchProgettiBozzaFailed());
    });
};

const fetchProgettiConclusi = (action, listenerApi) => {
  createRequest(GET_PROGETTI_CONCLUSI.method, GET_PROGETTI_CONCLUSI.baseUrl, GET_PROGETTI_CONCLUSI.apiUrl, { responseType: "json", decompress: true })
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchProgettiConclusiSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_PROGETTI_CONCLUSI_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_PROGETTI_CONCLUSI_TITLE, description: SAGAS_PROGETTI_CONCLUSI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchProgettiConclusiFailed());
    });
};
const fetchProgettiIstituto = (action, listenerApi) => {
  createRequest(GET_PROGETTI_ISTITUTO.method, GET_PROGETTI_ISTITUTO.baseUrl, GET_PROGETTI_ISTITUTO.apiUrl + "/" + action.payload, {
    responseType: "json",
    decompress: true,
  })
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchProgettiIstitutoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_PROGETTI_ISTITUTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_PROGETTI_ISTITUTO_TITLE, description: SAGAS_PROGETTI_ISTITUTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchProgettiIstitutoFailed());
    });
};
const fetchDettagli = (action, listenerApi) => {
  createRequest(GET_DETTAGLI_PROGETTI.method, GET_DETTAGLI_PROGETTI.baseUrl, GET_DETTAGLI_PROGETTI.apiUrl + action.payload, {
    responseType: "json",
    decompress: true,
  })
    .then((response) => {
      if (response.status === 200) {
        listenerApi.dispatch(fetchDettagliProgettoSuccess(response.data));
        if (response.data != null) {
          const tipoProgetto = response.data.descr_t_progetto;

          if (tipoProgetto != null) {
            if (tipoProgetto === "Innovazione della Scuola") listenerApi.dispatch(setProgettoInn(changeDataProgetto(response.data)));
            else listenerApi.dispatch(setProgettoCurr(changeDataProgettoCurr(response.data)));
          }
        }
      } else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_DETTAGLI_PROGETTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DETTAGLI_PROGETTO_TITLE, description: SAGAS_DETTAGLI_PROGETTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchDettagliProgettoFiled());
    });
};

export const progettiMiddleware = createListenerMiddleware();

progettiMiddleware.startListening({
  actionCreator: fetchSezioneMieiProgetti,
  effect: async (action, listenerApi) => {
    fetchProgettiBozza(action, listenerApi);
    fetchProgettiConclusi(action, listenerApi);
  },
});

progettiMiddleware.startListening({
  actionCreator: fetchSezioneProgettiIstituto,
  effect: async (action, listenerApi) => {
    fetchProgettiIstituto(action, listenerApi);
  },
});

progettiMiddleware.startListening({
  actionCreator: fetchSezioneProgettiCurricolariExtraCurricolari,
  effect: async (action, listenerApi) => {
    fetchProgettiCurricolariExtraCurricolari(listenerApi);
  },
});

progettiMiddleware.startListening({
  actionCreator: fetchDettagliProgetto,
  effect: async (action, listenerApi) => {
    fetchDettagli(action, listenerApi);
  },
});
