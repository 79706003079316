import { useDispatch, useSelector } from "react-redux";
import { getAttivitaInnovazione } from "../../../../modules/datiOsservatorio";
import {
  changetabs,
  checkIndex,
  getButtonType,
  getDatiProgettoInnovazione,
  getIdProgetto,
  getIndexTabSpostamento,
  getSottoAttivitaId,
  resetFiltriProgetti,
  resetIndex,
  saveProgress,
} from "../../../../modules/configuration";
import { useFormik } from "formik";
import { PATH_HOME } from "../../../../path";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { FooterInserisciProgetti } from "../FooterInserisciProgetti";
import { WarningAmber } from "@mui/icons-material";
import { BinocoloIcon } from "../../../../styles/icons";
import { PageAttivita } from "../pageAttivita/PageAttivita";
import { valProgrInn } from "../progressFunction";
import { getCommonStore } from "../../../../modules/common";
import { POST_ATTIVITA_INNOVAZIONE, PUT_SBLOCCO_MODIFICA } from "../../../../apiUrl";
import { notificationCodes, setNotification } from "../../../../modules/notification";
import { createRequest } from "../../../../utility";
import { useEffect, useState } from "react";
import { ModaleSpostamentoTab } from "../ModaleSpostamentoTab";

export const AttivitaInnovazione = (props) => {
  const AttivitaInnovazione = useSelector(getAttivitaInnovazione);
  const dispatch = useDispatch();
  const attivitaData = AttivitaInnovazione.data;
  const buttonType = useSelector(getButtonType);
  const index = useSelector(getIndexTabSpostamento);
  const navigate = useNavigate();
  let attivita = attivitaData;
  const datiProg = useSelector(getDatiProgettoInnovazione);
  const datiProgetto = datiProg.anagrafica;
  const datiProgettoAtt = datiProg.attivita;
  const allOptions = useSelector(getCommonStore);
  const [avvisoAttObb, setAvvisoAttObb] = useState(false);
  const [checkAttObb, setCheckAttObb] = useState(false);
  const idProg = useSelector(getIdProgetto);
  let progetto = props.progetto;

  let allOptionsFiltered = {};
  for (const [key, value] of Object.entries(allOptions)) {
    allOptionsFiltered[key] = value.data !== null ? value.data : [];
  }

  let areaCoinv = allOptionsFiltered.areeCoinvolte.find((e) => e.id === datiProgetto["descr_ambiente"])?.descrizione;

  //MAP AATIIVITA E SOTTOATTIVITA RELATIVE INSERITE DALL'UTENTE
  const attivitaMap = useSelector(getSottoAttivitaId);
  let attMap = new Map(JSON.parse(attivitaMap));
  //verifico obbligatorietà campi
  let checkAtt = 0;
  let sizeAttObb = 0;
  let checkAttivita = 0;
  let checkAttNonObb = true;
  attivita.map((opt) => (opt.obbligatorieta === 1 ? (sizeAttObb = sizeAttObb + 1) : null));

  useEffect(() => {
    // eslint-disable-next-line
    for (let [key, value] of attMap.entries()) {
      // eslint-disable-next-line
      attivita.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkAtt = checkAtt + 1) : null));
      if (checkAtt === sizeAttObb) {
        // eslint-disable-next-line
        setCheckAttObb(true);
        setAvvisoAttObb(false);
      } else {
        setCheckAttObb(false);
      }
    }
    // eslint-disable-next-line
  }, [attMap]);

  // eslint-disable-next-line
  for (let [key, value] of attMap.entries()) {
    // eslint-disable-next-line
    attivita.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkAttivita = checkAttivita + 1) : null));

    if (checkAttivita === attivita.length) {
      checkAttNonObb = false;
    }
  }
  const percentualeAttivita = attivita.length !== 0 ? 50 / attivita.length : 0;
  // //calcolo valore progress bar
  const progressAttivita = attMap.size * percentualeAttivita;
  let progress = Math.floor(valProgrInn(datiProg) + Math.floor(progressAttivita));
  progress = progress > 100 ? 100 : progress;

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      if (checkAttObb === true) {
        dispatch(saveProgress(progressAttivita));
        createRequest(POST_ATTIVITA_INNOVAZIONE.method, POST_ATTIVITA_INNOVAZIONE.baseUrl, POST_ATTIVITA_INNOVAZIONE.apiUrl, null, datiProgettoAtt)
          .then((response) => {
            if (response.status === 200) {
              if (buttonType === "AVANTI") {
                if (index !== null) {
                  let versione = true;
                  dispatch(changetabs({ index, versione }));
                } else {
                  dispatch(checkIndex("3"));
                }
              } else {
                createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
                  uuid_progetto: datiProg.attivita[0].uuid_progetto,
                })
                  .then((response) => {
                    if (response.status === 200) {
                      navigate(PATH_HOME);
                      dispatch(resetFiltriProgetti());
                      dispatch(resetIndex());
                    }
                  })
                  .catch((e) => {
                    dispatch(
                      setNotification({
                        tipo: notificationCodes.ERROR,
                        message: "Errore durante il cambio stato del progetto in modifica",
                        description: "Errore durante il cambio stato del progetto in modifica",
                      })
                    );
                  });
              }
            }
          })
          .catch((e) => {
            dispatch(
              setNotification({
                tipo: notificationCodes.ERROR,
                message: "Errore durante il salvataggio dei dati della sezione attività ",
                description: "Si è verificato un errore imprevisto nel salvataggio dei dati della sezione attività",
              })
            );
          });
      } else {
        setAvvisoAttObb(true);
      }
    },
  });

  return (
    <Box>
      <ModaleSpostamentoTab
        setTabSpostamentoAttivita={null}
        stateModal={props.modal}
        setModalSpostamentoTab={props.setModalSpostamentoTab}
        onSave={formik.handleSubmit}
        uuid={progetto.data === undefined ? progetto.uuid_progetto : idProg}
      />
      <Grid
        container
        sx={{
          marginLeft: "40px",
        }}
      >
        <Grid item xs={7} sm={4} md={3} xl={2.5}>
          <Typography
            sx={{
              fontWeight: "Bold",
              fontSize: "30px",
              color: avvisoAttObb === false ? "black" : "red !important",
            }}
          >
            {checkAttObb === true
              ? checkAttNonObb
                ? "Hai ancora attività da inserire"
                : "Hai inserito tutte le attivita"
              : "Inserisci attività obbligatorie"}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {checkAttObb === true ? (
            checkAttNonObb ? (
              <BinocoloIcon />
            ) : null
          ) : (
            <WarningAmber sx={{ color: avvisoAttObb === false ? "black" : "red !important" }} />
          )}
        </Grid>
      </Grid>
      <PageAttivita
        formik={formik}
        avvisoAttObb={avvisoAttObb}
        attivita={attivita}
        modal={"normal"}
        color2={"#FABE10"}
        color1={"#FABE10"}
        nameTab={areaCoinv}
        section={"attivita"}
        tipo={"progettoInnovazione"}
        progetto={progetto}
        progress={progress}
      />
      <FooterInserisciProgetti onSave={formik.handleSubmit} percentuale={progress} />
    </Box>
  );
};
