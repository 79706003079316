import { Box, Button, Grid, List } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { OptionsCheckModale } from "../../shared/OptionsCheckModale";
import {
  changetabs,
  checkIndex,
  deleteOptAzioneDiffusione,
  deleteOptDocumentazioneProgetto,
  getAzzOptions,
  getButtonType,
  getDatiProgetto,
  getDatiProgettoInnovazione,
  getDocOptions,
  getIdProgetto,
  getIndexTabSpostamento,
  getProgressAttivita,
  getProgressContesto,
  resetDatiPost,
  resetFiltriProgetti,
  resetIndex,
  saveProgressBar,
  saveValueProgetto,
  saveValueProgettoInn,
} from "../../../modules/configuration";
import { FooterInserisciProgetti } from "./FooterInserisciProgetti";
import { PATH_HOME } from "../../../path";
import { useNavigate } from "react-router-dom";
import { getInfoProfilo } from "../../../modules/profilo";
import { SelectCheckList } from "../../shared/form/SelectCheckList";
import { getCommonStore } from "../../../modules/common";
import { Input, Select } from "../../shared/form";
import { labels } from "../../../labels";
import { valProgrCurr, valProgrInn } from "./progressFunction";
import { POST_DOCUMENTAZIONE, PUT_SBLOCCO_MODIFICA } from "../../../apiUrl";
import { notificationCodes, setNotification } from "../../../modules/notification";
import { createRequest } from "../../../utility";
import { validateUrl } from "../../../utility/utils";
import { ModaleSpostamentoTab } from "./ModaleSpostamentoTab";
import { useEffect } from "react";

const {
  LINK_FORMAT_ERROR,
  LINK_DOCUMENTAZIONE,
  PLACEHOLDER_LINK,
  DOCUMENTAZIONE,
  PLACEHOLDER_DOCUMENTAZIONE,
  AZIONE_DIFF_DISSEMINAZIONE,
  PLACEHOLDER_INSERISCI,
  VALUTAZIONE,
  PLACEHOLDER_VALUTAZIONE,
  PUNTI_DEBOLEZZA,
  PUNTI_FORZA,
  PLACEHOLDER_DESCRIVI_OPPORTUNITA,
  PLACEHOLDER_DESCRIVI_VINCOLI,
  REQUIRED_FIELD,
} = labels;

export const Documentazione = (props) => {
  const dispatch = useDispatch();
  const allOptions = useSelector(getCommonStore);

  let allOptionsFiltered = {};
  for (const [key, value] of Object.entries(allOptions)) {
    allOptionsFiltered[key] = value.data !== null ? value.data : [];
  }
  const datiProgCurr = useSelector(getDatiProgetto);
  const datiProgettoInn = useSelector(getDatiProgettoInnovazione);
  let datiProg;
  if (props.idCampo === 29) {
    datiProg = datiProgCurr;
  } else {
    datiProg = datiProgettoInn;
  }

  let opt;
  if (props.idCampo === 29) {
    opt = allOptionsFiltered.tipologieDocumentazione.curricolari;
  } else {
    opt = allOptionsFiltered.tipologieDocumentazione.innovazione;
  }
  let optAzzDiff;
  if (props.idCampo === 29) {
    optAzzDiff = allOptionsFiltered.azioniDiffusioneDisseminazione.curricolari;
  } else {
    optAzzDiff = allOptionsFiltered.azioniDiffusioneDisseminazione.innovazione;
  }
  const datiProgetto = datiProg.documentazione;
  const optionsDocSelected = useSelector(getDocOptions);
  let progetto = props.progetto;
  //  non essendo nella home dove riempo i campi di campi select non salva dati in campi select ,quindi vi accedo direttamente
  const buttonType = useSelector(getButtonType);
  const navigate = useNavigate();
  let idCampo = props.tipo === "curr" ? 39 : 40;
  const idProg = useSelector(getIdProgetto);
  const optionsSelected = useSelector(getAzzOptions);
  const profilo = useSelector(getInfoProfilo);
  const idProgNuovo = useSelector(getIdProgetto);
  const codiceScuola = profilo.codiceScuola;
  const attcontprogr = useSelector(getProgressContesto);
  const index = useSelector(getIndexTabSpostamento);

  let listObj = [];
  let object;
  let idValore;
  let progress;

  //CALCOLO PROGRESS BAR
  const attivitaProgress = useSelector(getProgressAttivita);
  if (props.idCampo === 29) {
    progress = Math.floor(valProgrCurr(datiProg) + attivitaProgress + attcontprogr);
    progress = progress > 100 ? 100 : progress;
  } else {
    progress = Math.floor(valProgrInn(datiProg) + attivitaProgress);
    progress = progress > 100 ? 100 : progress;
  }

  //MI DEVO FILTRARE LE ATTIVITA E CRARE OGGETTO CON ID
  // creo oggetto per dati post
  idValore = datiProgetto["azioneDiDiffusioneDisseminazione"];
  for (let i = 0; i < idValore.length; i++) {
    object = {
      id_campo: props.idCampo,
      id_valore: idValore[i],
    };

    listObj.push(object);
  }

  const validationSchema = yup.object({
    descr_documentazione: yup.array().min(1).required(),
    azioneDiDiffusioneDisseminazione: yup.array().min(1).required(),
    descr_valutazione: yup.string().required(),
  });
  const formik = useFormik({
    initialValues: {
      descr_documentazione: datiProgetto["descr_documentazione"],
      link: datiProgetto["link"],
      azioneDiDiffusioneDisseminazione: datiProgetto["azioneDiDiffusioneDisseminazione"],
      descr_valutazione: datiProgetto["descr_valutazione"],
      descr_punti_forza: datiProgetto["descr_punti_forza"],
      descr_punti_debole: datiProgetto["descr_punti_debole"],
    },

    validationSchema: validationSchema,

    onSubmit: () => {
      if (formik.values.link !== "" ? validateUrl(formik.values.link) : true) {
        dispatch(saveProgressBar(progress));
        dispatch(resetDatiPost());

        createRequest(POST_DOCUMENTAZIONE.method, POST_DOCUMENTAZIONE.baseUrl, POST_DOCUMENTAZIONE.apiUrl, null, {
          codice_scuola: codiceScuola,
          uuid_progetto: progetto.data === undefined ? progetto.uuid_progetto : idProgNuovo,
          id_campo: idCampo,
          id_valore: datiProgetto["descr_documentazione"],
          link_documentazione: formik.values.link,
          id_valutazione: formik.values.descr_valutazione,
          punti_forza: formik.values.descr_punti_forza,
          punti_debolezza: formik.values.descr_punti_debole,
          azioni_diffusione_disseminazione: listObj,
          completamento: progress > 100 ? 100 : progress,
        })
          .then((response) => {
            if (response.status === 200) {
              if (buttonType === "AVANTI") {
                if (index !== null) {
                  let versione = true;
                  dispatch(changetabs({ index, versione }));
                } else {
                  if (props.idCampo === 29) dispatch(checkIndex("4"));
                  else dispatch(checkIndex("3"));
                }
              } else {
                createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
                  uuid_progetto: progetto.data === undefined ? progetto.uuid_progetto : idProgNuovo,
                })
                  .then((response) => {
                    if (response.status === 200) {
                      navigate(PATH_HOME);
                      dispatch(resetFiltriProgetti());
                      dispatch(resetIndex());
                    }
                  })
                  .catch((e) => {
                    dispatch(
                      setNotification({
                        tipo: notificationCodes.ERROR,
                        message: "Errore durante il cambio stato del progetto in modifica",
                        description: "Errore durante il cambio stato del progetto in modifica",
                      })
                    );
                  });
              }
            }
          })
          .catch((e) => {
            dispatch(
              setNotification({
                tipo: notificationCodes.ERROR,
                message: "Errore durante il salvataggio dei dati della sezione documentazione ",
                description: "Si è verificato un errore imprevisto nel salvataggio dei dati della sezione documentazione",
              })
            );
          });
      }
    },
  });
  let onDatiChange;
  if (props.idCampo === 29) {
    onDatiChange = (modifiche) => {
      // eslint-disable-next-line
      modifiche.map((modifica) => {
        const { fieldName, fieldValue } = modifica;
        formik.setFieldValue(fieldName, fieldValue);
        dispatch(saveValueProgetto(modifica));
      });
    };
  } else {
    onDatiChange = (modifiche) => {
      // eslint-disable-next-line
      modifiche.map((modifica) => {
        const { fieldName, fieldValue } = modifica;
        formik.setFieldValue(fieldName, fieldValue);
        dispatch(saveValueProgettoInn(modifica));
      });
    };
  }
  useEffect(() => {
    formik.setFieldValue("descr_documentazione", optionsDocSelected.length === 0 ? optionsDocSelected : optionsDocSelected[0]);
    formik.setFieldValue("azioneDiDiffusioneDisseminazione", optionsSelected.length === 0 ? optionsSelected : optionsSelected[0]);
    // eslint-disable-next-line
  }, [optionsDocSelected, optionsSelected]);

  return (
    <form onSubmit={formik.handleSubmit} id="myform">
      <ModaleSpostamentoTab
        setTabSpostamentoAttivita={null}
        stateModal={props.modal}
        setModalSpostamentoTab={props.setModalSpostamentoTab}
        onSave={formik.handleSubmit}
        uuid={progetto.data === undefined ? progetto.uuid_progetto : idProg}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <SelectCheckList
                tipo={props.tipo}
                optionsSelected={optionsDocSelected}
                options={allOptionsFiltered.tipologieDocumentazione}
                name={"descr_documentazione"}
                formik={formik}
                title={DOCUMENTAZIONE + "*"}
                placeholder={PLACEHOLDER_DOCUMENTAZIONE}
                errorText={REQUIRED_FIELD}
              />

              {optionsDocSelected.length !== 0 ? (
                <List
                  sx={{
                    meginbottom: "20px",
                    overflow: "auto",
                    maxWidth: "800px",
                    maxHeight: "80px",
                    backgroundColor: "#F0F0F0",
                    borderRadius: "20px",
                    "@media (max-width:480px)": {
                      width: "270px",
                      height: "400px",
                    },
                  }}
                >
                  {optionsDocSelected[0] !== undefined
                    ? optionsDocSelected[0].map((elemento, index) =>
                        opt.map((e) =>
                          elemento === e.id ? (
                            <Box sx={{ marginLeft: "10px", marginTop: "-20px" }} key={index}>
                              <OptionsCheckModale
                                color={"white"}
                                border={"solid 3px #FABE10"}
                                width={"98%"}
                                elemento={e.descrizione}
                                index={index}
                                formik={formik}
                                click={(e) => {
                                  dispatch(deleteOptDocumentazioneProgetto(elemento));
                                }}
                              />
                            </Box>
                          ) : null
                        )
                      )
                    : null}
                </List>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Input
                label={LINK_DOCUMENTAZIONE}
                placeholder={PLACEHOLDER_LINK}
                numeroCaratteri={300}
                value={formik.values.link}
                onChange={(e) => {
                  onDatiChange([{ fieldSection: "documentazione", fieldName: "link", fieldValue: e.target.value }]);
                }}
                {...(formik.values.link !== "" && { error: !validateUrl(formik.values.link), errorText: LINK_FORMAT_ERROR })}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <SelectCheckList
                tipo={props.tipo}
                optionsSelected={optionsSelected}
                options={allOptionsFiltered.azioniDiffusioneDisseminazione}
                name={"azioneDiDiffusioneDisseminazione"}
                formik={formik}
                title={AZIONE_DIFF_DISSEMINAZIONE + "*"}
                placeholder={PLACEHOLDER_INSERISCI}
                errorText={REQUIRED_FIELD}
              />
              {optionsSelected.length !== 0 ? (
                <List
                  sx={{
                    overflow: "auto",
                    maxWidth: "800px",
                    maxHeight: "80px",
                    backgroundColor: "#F0F0F0",
                    borderRadius: "20px",
                    "@media (max-width:480px)": {
                      width: "270px",
                      height: "400px",
                    },
                  }}
                >
                  {optionsSelected[0] !== undefined
                    ? optionsSelected[0].map((elemento, index) =>
                        optAzzDiff.map((e) =>
                          elemento === e.id ? (
                            <Box sx={{ marginLeft: "10px", marginTop: "-20px" }} key={index}>
                              <OptionsCheckModale
                                color={"white"}
                                border={"solid 3px #FABE10"}
                                width={"98%"}
                                elemento={e.descrizione}
                                index={index}
                                formik={formik}
                                click={(e) => {
                                  dispatch(deleteOptAzioneDiffusione(elemento));
                                }}
                              />
                            </Box>
                          ) : null
                        )
                      )
                    : null}
                </List>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={16} sm={12} md={6} xl={6}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Select
                label={VALUTAZIONE + "*"}
                placeholder={PLACEHOLDER_VALUTAZIONE}
                options={
                  props.idCampo === 29
                    ? allOptionsFiltered.attivitaValutazioneEfficaciaProgetto.curricolari
                    : allOptionsFiltered.attivitaValutazioneEfficaciaProgetto.innovazione
                }
                value={
                  formik.values.descr_valutazione !== ""
                    ? props.idCampo === 29
                      ? allOptionsFiltered.attivitaValutazioneEfficaciaProgetto.curricolari.find((e) => e.id === formik.values.descr_valutazione)?.id
                      : allOptionsFiltered.attivitaValutazioneEfficaciaProgetto.innovazione.find((e) => e.id === formik.values.descr_valutazione)?.id
                    : ""
                }
                onChange={(e, v) => {
                  const { value: id } = v.props;
                  onDatiChange([{ fieldSection: "documentazione", fieldName: "descr_valutazione", fieldValue: id }]);
                }}
                {...(formik.touched["descr_valutazione"] &&
                  Boolean(formik.errors["descr_valutazione"]) && {
                    error: true,
                    errorText: REQUIRED_FIELD,
                  })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} xl={6}>
                  <Input
                    label={PUNTI_FORZA}
                    numeroCaratteri={500}
                    infoNumeroCaratteri={true}
                    rows={5.5}
                    multiline={true}
                    placeholder={PLACEHOLDER_DESCRIVI_OPPORTUNITA}
                    value={formik.values.descr_punti_forza}
                    onChange={(e) => {
                      onDatiChange([{ fieldSection: "documentazione", fieldName: "descr_punti_forza", fieldValue: e.target.value }]);
                    }}
                  />
                </Grid>{" "}
                <Grid item xs={6} sm={6} md={6} xl={6}>
                  <Input
                    label={PUNTI_DEBOLEZZA}
                    numeroCaratteri={500}
                    infoNumeroCaratteri={true}
                    rows={5.5}
                    multiline={true}
                    placeholder={PLACEHOLDER_DESCRIVI_VINCOLI}
                    value={formik.values.descr_punti_debole}
                    onChange={(e) => {
                      onDatiChange([{ fieldSection: "documentazione", fieldName: "descr_punti_debole", fieldValue: e.target.value }]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FooterInserisciProgetti onSave={formik.handleSubmit} percentuale={Math.floor(progress)} />
      {/* finto per accessibilità */}
      <Button type="submit" sx={{ display: "none" }}>
        Salva
      </Button>
    </form>
  );
};
