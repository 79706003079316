import {
  changetabs,
  deleteAttivitaTab,
  getIndexTabAttivita,
  getIndexTabAttivitaNormalPrecedente,
  resetAttPost,
  resetAttivitaId,
  setIdProgetto,
  setIndexTabAttivita,
  setIndexTabSpostamento,
  setProgettoCurr,
  setProgettoInn,
} from "../../../modules/configuration";
import { Box, IconButton, Typography, Button, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../../labels";
import { notificationCodes, setNotification } from "../../../modules/notification";
import { GET_DETTAGLI_PROGETTI } from "../../../apiUrl";
import { createRequest } from "../../../utility";

import { changeDataProgetto, changeDataProgettoCurr, fetchDettagliProgettoFiled, fetchDettagliProgettoSuccess } from "../../../modules/datiProgetti";
import { tabsAttivita } from "./curricolareExtraCurricolare/AttivitaNormal";

const { SAGAS_DETTAGLI_PROGETTO_TITLE, SAGAS_DETTAGLI_PROGETTO_DESCRIPTION, TESTO_MODALE_SPOSTAMENTO_TAB } = labels;

export const ModaleSpostamentoTab = (props) => {
  const dispatch = useDispatch();
  let onSave = props.onSave;
  let set = props.setModalSpostamentoTab;
  let id = props.uuid;
  //nel caso in cui chiudo la modale senza spostarmi devo tornare al tab precedente
  let indexTabAttivitaPrecedente = useSelector(getIndexTabAttivitaNormalPrecedente);
  let indexTabattivita = useSelector(getIndexTabAttivita);
  const datiProg = (id) => {
    dispatch(setIdProgetto(id));
    createRequest(GET_DETTAGLI_PROGETTI.method, GET_DETTAGLI_PROGETTI.baseUrl, GET_DETTAGLI_PROGETTI.apiUrl + "/" + id, {
      responseType: "json",
      decompress: true,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data != null) {
            dispatch(fetchDettagliProgettoSuccess(response.data));
            const tipoProgetto = response.data.descr_t_progetto;
            if (tipoProgetto != null) {
              if (tipoProgetto === "Innovazione della Scuola") dispatch(setProgettoInn(changeDataProgetto(response.data)));
              else dispatch(setProgettoCurr(changeDataProgettoCurr(response.data)));
            }
          }
        } else throw response.message;
      })
      .catch((e) => {
        console.error(SAGAS_DETTAGLI_PROGETTO_TITLE, ":", e);
        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DETTAGLI_PROGETTO_TITLE, description: SAGAS_DETTAGLI_PROGETTO_DESCRIPTION })); //prettier-ignore
        dispatch(fetchDettagliProgettoFiled());
      });
  };

  return (
    <Modal
      open={props.stateModal}
      onClose={() => set(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="inserimentoProgetto"
    >
      <Box className="modaleHome" sx={{ height: "300px !important", "@media (max-width:640px)": { height: "450px !important" } }}>
        <IconButton
          disableRipple
          aria-label="delete"
          className="closeIcon"
          onClick={() => {
            dispatch(setIndexTabAttivita(indexTabAttivitaPrecedente));
            dispatch(setIndexTabSpostamento(null));
            set(false);
          }}
        >
          <CancelOutlinedIcon />
        </IconButton>
        <Box sx={{ marginTop: "75px", textAlign: "center" }}>
          <Typography className="textModaleHome">{TESTO_MODALE_SPOSTAMENTO_TAB} </Typography>
          <Button
            variant="contained"
            className="buttonEsci"
            onClick={() => {
              datiProg(id);
              if (props.attivita === true) {
                dispatch(resetAttPost());
                dispatch(deleteAttivitaTab(tabsAttivita[indexTabattivita].testo.toUpperCase()));
              }
              if (props.attivita !== true) {
                dispatch(resetAttPost());
                dispatch(resetAttivitaId());
              }
              if (props.attivita === true) {
                //cambio indice del tab delle attività
                let index = 2;
                let versione = true;
                dispatch(changetabs({ index, versione }));
              }
              set(false);
            }}
            sx={{ width: "180px !important" }}
          >
            <Typography className="textButtonModaleHome">Si, senza salvare</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onSave();
              set(false);
            }}
            className="buttonAnnulla"
          >
            <Typography className="textButtonModaleHome">Si, salva i dati</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
