import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { ArrowCircleRightOutlined as ArrowCircleRightOutlinedIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Anagrafica } from "./Anagrafica";
import { Attivita } from "./Attivita";
import { useSelector, useDispatch } from "react-redux";
import {
  changeTabsDettagli,
  changetabs,
  getDatiProgetto,
  getIndexTabSpostamento,
  getIndexTabs,
  getModaleSpostamentoTab,
  getSottoAttivitaId,
  saveOptionSelect,
  saveProgress,
  saveProgressCont,
  saveSottoAttivita,
  setAttivitaSelezionata,
  setAzDiffDiss,
  setButtTypeFooterInsProg,
  setDescrizioniSottoAttivita,
  setDocumentazioneOptions,
  setEdit,
  setIndexTabSpostamento,
} from "../../../../modules/configuration";
import { TabPanel } from "../../../shared/TabPanelCustom";
import { Pubblicazione } from "../Pubblicazione";
import { labels } from "../../../../labels";
import { Contesto } from "./Contesto";
import { Documentazione } from "../Documentazione";
import { useEffect, useState } from "react";
import { fetchDisciplineCoinvolte } from "../../../../modules/datiOsservatorio";
import { Binocolo } from "../../../../styles/icons/Binocolo";
import { WarningAmber as WarningAmberIcon } from "@mui/icons-material";
import { getAttivitaCurricolari } from "../../../../modules/common";

const {
  ANAGRAFICA,
  CONTESTO,
  ATTIVITA,
  DOCUMENTAZIONE,
  PUBBLICAZIONE,
  DIDATTICA,
  SICUREZZA,
  CONTENUTI,
  PROBLEM_SOLVING,
  LAVORO,
  WEB,
  COMUNICAZIONE,
} = labels;

export const gestisciprogettibutton = [
  //button curr,extracurr
  {
    id: "0",
    testo: ANAGRAFICA,
  },
  {
    id: "1",
    testo: CONTESTO,
  },
  {
    id: "2",
    testo: ATTIVITA,
  },
  {
    id: "3",
    testo: DOCUMENTAZIONE,
  },
  {
    id: "4",
    testo: PUBBLICAZIONE,
  },
];

let tabsAttivita = [
  {
    id: "0",
    testo: DIDATTICA,
    priorità: true,
    binocolo: false,
  },
  {
    id: "1",
    testo: LAVORO,
    priorità: true,
    binocolo: false,
  },
  {
    id: "2",
    testo: WEB,
    priorità: true,
    binocolo: false,
  },
  {
    id: "3",
    testo: COMUNICAZIONE,
    priorità: true,
    binocolo: false,
  },
  {
    id: "4",
    testo: CONTENUTI,
    priorità: true,
    binocolo: false,
  },
  {
    id: "5",
    testo: SICUREZZA,
    priorità: true,
    binocolo: false,
  },
  {
    id: "6",
    testo: PROBLEM_SOLVING,
    priorità: true,
    binocolo: false,
  },
];

export const MainCurrExtraC = (props) => {
  const indexTabs = useSelector(getIndexTabs);
  let indexString = indexTabs.toString();
  const attivitaCurr = useSelector(getAttivitaCurricolari);
  const attivitaC = attivitaCurr;
  const dispatch = useDispatch();
  let progetto = props.progetto;
  const [versione, setVersione] = useState(false);
  const datiProg = useSelector(getDatiProgetto);
  const datiProgettoAtt = datiProg.contesto.attivita;
  const datiProgetto = datiProg.anagrafica;
  const datiProgettoDoc = datiProg.documentazione;
  const [modalSpostamentoTab, setModalSpostamentoTab] = useState(false);
  const [sbloccoTab, setSbloccoTab] = useState(0);
  const index = useSelector(getIndexTabSpostamento);
  const [checkIconAttivita, setCheckIconAttivita] = useState(false);
  const [checkSezioneContestoAttivita, setCheckSezioneAttivita] = useState("");
  const [iconAnagrafica, setIconAnagrafica] = useState(<WarningAmberIcon sx={{ display: "none !important" }} />);
  const [iconContesto, setIconContesto] = useState(<WarningAmberIcon sx={{ display: "none !important" }} />);
  const [iconAttivita, setIconAttivita] = useState(<WarningAmberIcon sx={{ display: "none !important" }} />);
  const [iconDocumentazione, setIconDocumentazione] = useState(<WarningAmberIcon sx={{ display: "none !important" }} />);
  let iconPubblicazione = <WarningAmberIcon sx={{ display: "none !important" }} />;
  const attivita = useSelector(getAttivitaCurricolari);
  const sottoAttivitaId = useSelector(getSottoAttivitaId);
  let sottAtt = new Map(JSON.parse(sottoAttivitaId));
  let checkAttOb = false;
  let checkAtt = 0;
  //MAP AATIIVITA E SOTTOATTIVITA RELATIVE INSERITE DALL'UTENTE
  const attivitaMap = useSelector(getSottoAttivitaId);
  let attMap = new Map(JSON.parse(attivitaMap));
  const modaleSpostamentoAttivita = useSelector(getModaleSpostamentoTab);

  //resetto i campi delle icone
  useEffect(() => {
    tabsAttivita[0].priorità = true;
    tabsAttivita[1].priorità = true;
    tabsAttivita[2].priorità = true;
    tabsAttivita[3].priorità = true;
    tabsAttivita[4].priorità = true;
    tabsAttivita[5].priorità = true;
    tabsAttivita[6].priorità = true;
    // eslint-disable-next-line
  }, []);

  //verifico attivita obbligatorie per settare le icone sulle macrosequenze
  useEffect(() => {
    const checkAttivitaDidattica = (attivita) => {
      const checkCAD = attivita.descr_sottotab === "DIDATTICA";
      return checkCAD;
    };

    const attivitaDidattica = attivita.filter(checkAttivitaDidattica);

    const checkLavoro = (attivita) => {
      const checkCL = attivita.descr_sottotab === "LAVORO";
      return checkCL;
    };

    const attivitaLavoro = attivita.filter(checkLavoro);

    const checkWeb = (attivita) => {
      const checkCW = attivita.descr_sottotab === "WEB";
      return checkCW;
    };

    const attivitaWeb = attivita.filter(checkWeb);

    const checkComunicazione = (attivita) => {
      const checkCC = attivita.descr_sottotab === "COMUNICAZIONE";
      return checkCC;
    };

    const attivitaComunicazione = attivita.filter(checkComunicazione);

    const checkContenuti = (attivita) => {
      const checkCont = attivita.descr_sottotab === "CONTENUTI";
      return checkCont;
    };

    const attivitaContenuti = attivita.filter(checkContenuti);

    const checkSicurezza = (attivita) => {
      const checkCS = attivita.descr_sottotab === "SICUREZZA";
      return checkCS;
    };

    const attivitaSicurezza = attivita.filter(checkSicurezza);

    const checkProblemSolving = (attivita) => {
      const checkCPB = attivita.descr_sottotab === "PROBLEM SOLVING";
      return checkCPB;
    };

    const attivitaPB = attivita.filter(checkProblemSolving);

    //VERIFICO CI SIAN ATTIVITA PER TAB OBBLIGATORIE
    let checkAttDidObb = false;
    let checkAttDid = 0;
    for (let [key] of attMap.entries()) {
      //per eliminare segno obbligatorietà
      // eslint-disable-next-line
      attivitaDidattica.map((opt) =>
        opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkAttDidObb = checkAttDidObb + 1) : null
      );
      //per eliminare segno presenza attivita non inserite
      // eslint-disable-next-line
      attivitaDidattica.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkAttDid = checkAttDid + 1) : null));
      if (checkAttDidObb === 2) {
        tabsAttivita[0].priorità = false;
      } else {
        tabsAttivita[0].priorità = true;
      }
      if (checkAttDid === 6) {
        tabsAttivita[0].binocolo = false;
      } else {
        tabsAttivita[0].binocolo = true;
      }
    }

    let checkLavObb = 0;
    let checkLav = 0;
    for (let [key] of attMap.entries()) {
      // eslint-disable-next-line
      attivitaLavoro.map((opt) =>
        opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkLavObb = checkLavObb + 1) : null
      );
      // eslint-disable-next-line
      attivitaLavoro.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkLav = checkLav + 1) : null));

      if (checkLavObb === 1) {
        tabsAttivita[1].priorità = false;
      } else {
        tabsAttivita[1].priorità = true;
      }

      if (checkLav === 3) {
        tabsAttivita[1].binocolo = false;
      } else {
        tabsAttivita[1].binocolo = true;
      }
    }

    let checkWebObb = 0;
    for (let [key] of attMap.entries()) {
      // eslint-disable-next-line
      attivitaWeb.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkWebObb = checkWebObb + 1) : null));
      if (checkWebObb === 3) {
        tabsAttivita[2].priorità = false;
      } else {
        tabsAttivita[2].priorità = true;
      }
    }

    let checkComObb = 0;
    for (let [key] of attMap.entries()) {
      // eslint-disable-next-line
      attivitaComunicazione.map(
        // eslint-disable-next-line
        (opt) =>
          // eslint-disable-next-line
          opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkComObb = checkComObb + 1) : null
        // eslint-disable-next-line
      );

      if (checkComObb === 2) {
        tabsAttivita[3].priorità = false;
      } else {
        tabsAttivita[3].priorità = true;
      }
    }

    let checkCont = 0;
    for (let [key] of attMap.entries()) {
      // eslint-disable-next-line
      attivitaContenuti.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkCont = checkCont + 1) : null));

      if (checkCont === 3) {
        tabsAttivita[4].priorità = false;
      } else {
        tabsAttivita[4].priorità = true;
      }
    }

    let checkSic = 0;
    for (let [key] of attMap.entries()) {
      // eslint-disable-next-line
      attivitaSicurezza.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkSic = checkSic + 1) : null));

      if (checkSic === 1) {
        tabsAttivita[5].priorità = false;
      } else {
        tabsAttivita[5].priorità = true;
      }
    }
    let checkPB = 0;
    for (let [key] of attMap.entries()) {
      // eslint-disable-next-line
      attivitaPB.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkPB = checkPB + 1) : null));

      if (checkPB === 1) {
        tabsAttivita[6].priorità = false;
      } else {
        tabsAttivita[6].priorità = true;
      }
    }
    tabsAttivita[0].priorità === false &&
    tabsAttivita[1].priorità === false &&
    tabsAttivita[2].priorità === false &&
    tabsAttivita[3].priorità === false &&
    tabsAttivita[4].priorità === false &&
    tabsAttivita[5].priorità === false &&
    tabsAttivita[6].priorità === false
      ? (checkAttOb = true) && setCheckIconAttivita(true)
      : (checkAttOb = false) && setCheckIconAttivita(false);
    if (checkAttOb === true) {
      tabsAttivita[0].binocolo === false &&
      tabsAttivita[0].priorità === false &&
      tabsAttivita[1].binocolo === false &&
      tabsAttivita[1].priorità === false &&
      tabsAttivita[2].binocolo === false &&
      tabsAttivita[2].priorità === false &&
      tabsAttivita[3].binocolo === false &&
      tabsAttivita[3].priorità === false &&
      tabsAttivita[4].binocolo === false &&
      tabsAttivita[4].priorità === false &&
      tabsAttivita[5].binocolo === false &&
      tabsAttivita[5].priorità === false &&
      tabsAttivita[6].binocolo === false &&
      tabsAttivita[6].priorità === false
        ? setCheckIconAttivita(null)
        : setCheckIconAttivita(true);
    }
    // eslint-disable-next-line
  }, [tabsAttivita, attMap, attivita]);

  //verifico attivita obbligatorie per settare le icone sulle macrosequenze
  useEffect(() => {
    const checkContesto = (attivitaC) => {
      const checkC = attivitaC.descr_sottotab === "CONTESTO";
      return checkC;
    };

    let attivitaContesto = attivitaC.filter(checkContesto);
    let checkAttObbNum = 0;

    for (let [key] of sottAtt.entries()) {
      // eslint-disable-next-line
      attivitaContesto.map((opt) =>
        opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkAttObbNum = checkAttObbNum + 1) : null
      );
      // eslint-disable-next-line
      attivitaContesto.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkAtt = checkAtt + 1) : null));
    }

    if (checkAttObbNum < 3 && checkAtt < 6) {
      setCheckSezioneAttivita(false);
    } else {
      if (checkAttObbNum === 3 && checkAtt < 6) {
        setCheckSezioneAttivita("Binocolo");
      } else {
        if (checkAttObbNum === 3 && checkAtt == 6) {
          setCheckSezioneAttivita(null);
        } else {
          if (checkAttObbNum === 3 && checkAtt > 6) setCheckSezioneAttivita(null);
        }
      }
    }

    // eslint-disable-next-line
  }, [sottAtt]);

  //CONTROLLARE NELLA MODIFICA COME FAR PARTIRE I CONTROLLI SULLE ATTIVITA APPENA SI APRE LA PAGINA DI MODIFICA
  useEffect(() => {
    let attivitaInserite = [];
    // eslint-disable-next-line
    datiProgettoAtt.map((e) => {
      attivitaInserite.push(e.id_campo);
      attivitaInserite = attivitaInserite.filter(function (e, el) {
        return attivitaInserite.indexOf(e) === el;
      });
    });
    let progrCont = attivitaInserite.length * 2;

    dispatch(saveProgressCont(progrCont));
    //per versione modifica siccome chiamata non parte con onchange della scelta nella select
    if (datiProgetto["descr_grado_scuola"]) {
      dispatch(fetchDisciplineCoinvolte("/" + datiProgetto["descr_grado_scuola"])); //devi passar id
    }
    dispatch(setDocumentazioneOptions(datiProg.documentazione["descr_documentazione"]));
    dispatch(setAzDiffDiss(datiProg.documentazione["azioneDiDiffusioneDisseminazione"]));
    dispatch(saveOptionSelect(datiProg.contesto["discipline"]));

    for (let i = 0; i < datiProg.attivita.length; i++) {
      let elementoAtt = datiProg.attivita[i].finalita + " " + datiProg.attivita[i].descr_campo;
      let elementoSottoAtt = datiProg.attivita[i].descr_valore;
      dispatch(saveSottoAttivita({ elementoSottoAtt, elementoAtt }));
      dispatch(setAttivitaSelezionata("in"));
    }

    for (let i = 0; i < datiProg.contesto.attivita.length; i++) {
      let elementoAtt = datiProg.contesto.attivita[i].finalita + " " + datiProg.contesto.attivita[i].descr_campo;
      let elementoSottoAtt = datiProg.contesto.attivita[i].descr_valore;
      dispatch(saveSottoAttivita({ elementoSottoAtt, elementoAtt }));
      dispatch(setAttivitaSelezionata("in"));
    }
    for (let i = 0; i < datiProg.attivita.length; i++) {
      let elementoSottoAtt = datiProg.attivita[i].descr_valore;
      let elementoSottoAttDesc = datiProg.attivita[i].descr_valore_progetto;
      dispatch(setDescrizioniSottoAttivita({ elementoSottoAtt, elementoSottoAttDesc }));
    }
    for (let i = 0; i < datiProg.contesto.attivita.length; i++) {
      let elementoSottoAtt = datiProg.contesto.attivita[i].descr_valore;
      let elementoSottoAttDesc = datiProg.contesto.attivita[i].descr_valore_progetto;
      dispatch(setDescrizioniSottoAttivita({ elementoSottoAtt, elementoSottoAttDesc }));
    }
    const percentualeAttivita = 40 / 19;
    let progressAttivita = datiProg.attivita.length * percentualeAttivita;

    dispatch(saveProgress(progressAttivita));

    // eslint-disable-next-line
  }, [progetto]);

  //changeTabs
  useEffect(() => {
    if (index !== null) {
      let versione = true;
      dispatch(changetabs({ index, versione }));
    }
    if (modaleSpostamentoAttivita === true) {
      //cambio indice del tab delle attività
      let index = 2;
      let versione = true;
      dispatch(changetabs({ index, versione }));
    }
    // eslint-disable-next-line
  }, [progetto]);

  //setto nuovo sblocco tab
  useEffect(() => {
    if (indexTabs > sbloccoTab) {
      setSbloccoTab(indexTabs);
    }
    // eslint-disable-next-line
  }, [indexTabs]);

  //cambio colore del tab in base allo stato di completamento e al selezionamento
  const color = (index) => {
    if (indexTabs === index) return "#FABE10";
    else {
      if (progetto.data === undefined) {
        if (index <= sbloccoTab) return "#FFE7A4";
        else return "#FFFFFF";
      } else {
        if (index <= sbloccoTab) return "#FFE7A4";
        else return "#FFFFFF";
      }
    }
  };

  //testo sottolineato nel tab che ho selezionato
  const decoration = (index) => {
    if (indexTabs === index) return "underline";
    else return "none";
  };

  //verifica se va mostrata la freccia tra tab
  const visible = (id) => {
    if (id > 3) return false;
    else return true;
  };

  //funzione per cambio del cursore in base a se il tab è sbloccato o no
  const cursor = (index) => {
    if (progetto.data === undefined) {
      if (index > sbloccoTab) return "default";
    } else {
      if (index > indexTabs) return "default";
    }
  };
  //controlli per icone binocolo/danger che informano l'utente sullo stato del completamento della macrosezione
  useEffect(() => {
    if (
      datiProgetto["descr_t_progetto"] !== "" &&
      datiProgetto["titolo_progetto"] !== "" &&
      datiProgetto["num_alunni_imp"] !== "" &&
      datiProgetto["descr_composizione_gruppo"] !== "" &&
      datiProgetto["descr_tipo_attributo"] !== "" &&
      datiProgetto["descr_grado_scuola"] !== "" &&
      datiProgetto["descr_tempo_realiz"] !== "" &&
      datiProgetto["descr_anno_realiz"] !== "" &&
      datiProgetto["descr_attivita_digitale"] !== "" &&
      datiProgetto["descr_progetto"] !== "" &&
      datiProgetto["data_inizio"] !== "" &&
      datiProgetto["descr_studenti_bes"] !== ""
    ) {
      setIconAnagrafica(<WarningAmberIcon sx={{ display: "none !important" }} />);
    } else {
      if (
        datiProgetto["descr_t_progetto"] !== "" &&
        datiProgetto["titolo_progetto"] !== "" &&
        datiProgetto["num_alunni_imp"] !== "" &&
        datiProgetto["descr_composizione_gruppo"] !== "" &&
        datiProgetto["descr_tipo_attributo"] !== "" &&
        datiProgetto["descr_grado_scuola"] !== "" &&
        datiProgetto["descr_tempo_realiz"] !== "" &&
        datiProgetto["descr_anno_realiz"] !== "" &&
        datiProgetto["descr_attivita_digitale"] !== "" &&
        datiProgetto["descr_progetto"] !== "" &&
        datiProgetto["data_inizio"] !== ""
      ) {
        setIconAnagrafica(<Binocolo />);
      } else {
        setIconAnagrafica(<WarningAmberIcon />);
      }
    }

    if (
      datiProg.contesto["interdisciplinarieta"] !== null &&
      datiProg.contesto["descr_contesto_accompagnamento"] !== "" &&
      datiProg.contesto["descr_attivita_clil"] !== "" &&
      checkSezioneContestoAttivita === null
    ) {
      setIconContesto(<WarningAmberIcon sx={{ display: "none !important" }} />);
    } else {
      if (
        (datiProg.contesto["interdisciplinarieta"] !== null &&
          datiProg.contesto["descr_contesto_accompagnamento"] !== "" &&
          checkSezioneContestoAttivita === "Binocolo") ||
        checkSezioneContestoAttivita === null
      ) {
        setIconContesto(<Binocolo />);
      } else {
        if (checkSezioneContestoAttivita === false) {
          setIconContesto(<WarningAmberIcon />);
        }
      }
    }

    tabsAttivita[0].priorità === false &&
    tabsAttivita[1].priorità === false &&
    tabsAttivita[2].priorità === false &&
    tabsAttivita[3].priorità === false &&
    tabsAttivita[4].priorità === false &&
    tabsAttivita[5].priorità === false &&
    tabsAttivita[6].priorità === false
      ? (checkAttOb = true)
      : (checkAttOb = false);
    tabsAttivita[0].priorità === false &&
    tabsAttivita[1].priorità === false &&
    tabsAttivita[2].priorità === false &&
    tabsAttivita[3].priorità === false &&
    tabsAttivita[4].priorità === false &&
    tabsAttivita[5].priorità === false &&
    tabsAttivita[6].priorità === false
      ? setCheckIconAttivita(true)
      : setCheckIconAttivita(false);

    if (checkAttOb === true) {
      tabsAttivita[0].binocolo === false &&
      tabsAttivita[0].priorità === false &&
      tabsAttivita[1].binocolo === false &&
      tabsAttivita[1].priorità === false &&
      tabsAttivita[2].binocolo === false &&
      tabsAttivita[2].priorità === false &&
      tabsAttivita[3].binocolo === false &&
      tabsAttivita[3].priorità === false &&
      tabsAttivita[4].binocolo === false &&
      tabsAttivita[4].priorità === false &&
      tabsAttivita[5].binocolo === false &&
      tabsAttivita[5].priorità === false &&
      tabsAttivita[6].binocolo === false &&
      tabsAttivita[6].priorità === false
        ? setCheckIconAttivita(null)
        : setCheckIconAttivita(true);
    }

    if (checkIconAttivita === true) {
      setIconAttivita(<Binocolo />);
    } else {
      if (checkIconAttivita === null) {
        setIconAttivita(<WarningAmberIcon sx={{ display: "none !important" }} />);
      } else {
        if (checkIconAttivita === false) {
          setIconAttivita(<WarningAmberIcon />);
        }
      }
    }

    if (
      datiProgettoDoc["descr_documentazione"] !== undefined &&
      datiProgettoDoc["descr_documentazione"].length > 0 &&
      datiProgettoDoc["azioneDiDiffusioneDisseminazione"] !== undefined &&
      datiProgettoDoc["azioneDiDiffusioneDisseminazione"].length > 0 &&
      datiProgettoDoc["descr_valutazione"] !== "" &&
      datiProgettoDoc["link"] !== "" &&
      datiProgettoDoc["descr_punti_forza"] !== "" &&
      datiProgettoDoc["descr_punti_debole"] !== ""
    ) {
      setIconDocumentazione(<WarningAmberIcon sx={{ display: "none !important" }} />);
    } else {
      if (
        datiProgettoDoc["descr_documentazione"] !== undefined &&
        datiProgettoDoc["descr_documentazione"].length > 0 &&
        datiProgettoDoc["azioneDiDiffusioneDisseminazione"] !== undefined &&
        datiProgettoDoc["azioneDiDiffusioneDisseminazione"].length > 0 &&
        datiProgettoDoc["descr_valutazione"] !== ""
      ) {
        setIconDocumentazione(<Binocolo />);
      } else {
        setIconDocumentazione(<WarningAmberIcon />);
      }
    }

    // eslint-disable-next-line
  }, [checkSezioneContestoAttivita, datiProgettoDoc, checkIconAttivita, datiProg, datiProgetto, tabsAttivita]);

  useEffect(() => {
    // versione modifica setto tab dove trovo la prima sezione incompilata e sblocco lo spostamento tra i tab precedenti
    if (progetto.data === undefined) {
      dispatch(setEdit(true));
      setVersione(false);
      if (progetto.descr_t_progetto === "") {
        dispatch(changeTabsDettagli(0));
        setSbloccoTab(5);
      } else {
        if (progetto.descr_contesto_accompagnamento === null) {
          dispatch(changeTabsDettagli(1));
          setSbloccoTab(1);
        } else {
          let tabsAttivita = ["DIDATTICA", "LAVORO", "WEB", "COMUNICAZIONE", "CONTENUTI", "SICUREZZA", "PROBLEM SOLVING"];
          for (let j = 0; j < tabsAttivita.length; j++) {
            for (let i = 0; i < progetto.attivita.length; i++) {
              if (progetto.attivita[i].descr_sottotab === tabsAttivita[j]) {
                if (j === tabsAttivita.length - 1) {
                  if (progetto.descr_valutazione === null) {
                    dispatch(changeTabsDettagli(3));
                    setSbloccoTab(3);
                  } else {
                    dispatch(changeTabsDettagli(0));
                    setSbloccoTab(5);
                  }
                }
              } else {
                dispatch(changeTabsDettagli(2));
                setSbloccoTab(2);
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  
  return (
    <TabContext value={indexString}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={visible(0) ? (0 === 3 ? 3 : 0 === 2 ? 2 : 2.5) : 2} xl={visible(0) ? 2.5 : 2} key={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={visible(0) ? 10 : 12} sm={visible(0) ? 10 : 12} xl={visible(0) ? 10 : 12}>
              <Tab
                label={gestisciprogettibutton[0].testo}
                value={0}
                icon={iconAnagrafica}
                iconPosition="end"
                disableRipple
                disableFocusRipple
                onClick={() => {
                  if (indexTabs != 2) {
                    dispatch(setButtTypeFooterInsProg("AVANTI"));
                  } else {
                    dispatch(setButtTypeFooterInsProg(""));
                  }
                  if (0 <= sbloccoTab && indexTabs !== 0) {
                    dispatch(setIndexTabSpostamento(0));
                    setModalSpostamentoTab(true);
                    dispatch(setEdit(true));
                    setVersione(true);
                  }
                }}
                sx={{
                  bgcolor: color(0),
                  textDecoration: decoration(0),
                  cursor: cursor(0),
                }}
                className="tabSezioni"
              />
            </Grid>
            {visible(0) && (
              <Grid item xs={0} md={2} sm={2} xl={2}>
                <ArrowCircleRightOutlinedIcon className="arrowCircleIconTab" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={visible(1) ? (1 === 3 ? 3 : 1 === 2 ? 2 : 2.5) : 2} xl={visible(1) ? 2.5 : 2} key={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={visible(1) ? 10 : 12} sm={visible(1) ? 10 : 12} xl={visible(1) ? 10 : 12}>
              <Tab
                label={gestisciprogettibutton[1].testo}
                value={1}
                icon={iconContesto}
                iconPosition="end"
                disableRipple
                disableFocusRipple
                onClick={() => {
                  if (indexTabs != 2) {
                    dispatch(setButtTypeFooterInsProg("AVANTI"));
                  } else {
                    dispatch(setButtTypeFooterInsProg(""));
                  }
                  if (1 <= sbloccoTab && indexTabs !== 1) {
                    dispatch(setIndexTabSpostamento(1));
                    setModalSpostamentoTab(true);
                    dispatch(setEdit(true));
                    setVersione(true);
                  }
                }}
                sx={{
                  bgcolor: color(1),
                  textDecoration: decoration(1),
                  cursor: cursor(1),
                }}
                className="tabSezioni"
              />
            </Grid>
            {visible(1) && (
              <Grid item xs={0} md={2} sm={2} xl={2}>
                <ArrowCircleRightOutlinedIcon className="arrowCircleIconTab" />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={visible(2) ? (2 === 3 ? 3 : 2 === 2 ? 2 : 2.5) : 2} xl={visible(2) ? 2.5 : 2} key={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={visible(2) ? 10 : 12} sm={visible(2) ? 10 : 12} xl={visible(2) ? 10 : 12}>
              <Tab
                label={gestisciprogettibutton[2].testo}
                value={2}
                icon={iconAttivita}
                iconPosition="end"
                disableRipple
                disableFocusRipple
                onClick={() => {
                  if (indexTabs != 2) {
                    dispatch(setButtTypeFooterInsProg("AVANTI"));
                  } else {
                    dispatch(setButtTypeFooterInsProg(""));
                  }
                  if (2 <= sbloccoTab && indexTabs !== 2) {
                    dispatch(setIndexTabSpostamento(2));
                    setModalSpostamentoTab(true);
                    dispatch(setEdit(true));
                    setVersione(true);
                  }
                }}
                sx={{
                  bgcolor: color(2),
                  textDecoration: decoration(2),
                  cursor: cursor(2),
                }}
                className="tabSezioni"
              />
            </Grid>
            {visible(2) && (
              <Grid item xs={0} md={2} sm={2} xl={2}>
                <ArrowCircleRightOutlinedIcon className="arrowCircleIconTab" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={visible(3) ? (3 === 3 ? 3 : 3 === 2 ? 2 : 2.5) : 2} xl={visible(3) ? 2.5 : 2} key={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={visible(3) ? 10 : 12} sm={visible(3) ? 10 : 12} xl={visible(3) ? 10 : 12}>
              <Tab
                label={gestisciprogettibutton[3].testo}
                value={3}
                icon={iconDocumentazione}
                iconPosition="end"
                disableRipple
                disableFocusRipple
                onClick={() => {
                  if (indexTabs != 2) {
                    dispatch(setButtTypeFooterInsProg("AVANTI"));
                  } else {
                    dispatch(setButtTypeFooterInsProg(""));
                  }
                  if (3 <= sbloccoTab && indexTabs !== 3) {
                    dispatch(setIndexTabSpostamento(3));
                    setModalSpostamentoTab(true);
                    dispatch(setEdit(true));
                    setVersione(true);
                  }
                }}
                sx={{
                  bgcolor: color(3),
                  textDecoration: decoration(3),
                  cursor: cursor(3),
                }}
                className="tabSezioni"
              />
            </Grid>
            {visible(3) && (
              <Grid item xs={0} md={2} sm={2} xl={2}>
                <ArrowCircleRightOutlinedIcon className="arrowCircleIconTab" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={visible(4) ? (4 === 3 ? 3 : 4 === 2 ? 2 : 2.5) : 2} xl={visible(4) ? 2.5 : 2} key={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={visible(4) ? 10 : 12} sm={visible(4) ? 10 : 12} xl={visible(4) ? 10 : 12}>
              <Tab
                label={gestisciprogettibutton[4].testo}
                value={4}
                icon={iconPubblicazione}
                iconPosition="end"
                disableRipple
                disableFocusRipple
                onClick={() => {
                  if (indexTabs != 2) {
                    dispatch(setButtTypeFooterInsProg("AVANTI"));
                  } else {
                    dispatch(setButtTypeFooterInsProg(""));
                  }
                  if (4 <= sbloccoTab && indexTabs !== 4) {
                    dispatch(setIndexTabSpostamento(4));
                    setModalSpostamentoTab(true);
                    dispatch(setEdit(true));
                    setVersione(true);
                  }
                }}
                sx={{
                  bgcolor: color(4),
                  textDecoration: decoration(4),
                  cursor: cursor(4),
                }}
                className="tabSezioni"
              />
            </Grid>
            {visible(4) && (
              <Grid item xs={0} md={2} sm={2} xl={2}>
                <ArrowCircleRightOutlinedIcon className="arrowCircleIconTab" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <TabPanel value={"0"} index={indexString}>
        <Anagrafica progetto={progetto} modal={modalSpostamentoTab} setModalSpostamentoTab={setModalSpostamentoTab} versione={versione} />
      </TabPanel>
      <TabPanel value={"1"} index={indexString}>
        <Contesto progetto={progetto} modal={modalSpostamentoTab} setModalSpostamentoTab={setModalSpostamentoTab} versione={versione} />
      </TabPanel>
      <TabPanel value={"2"} index={indexString}>
        <Attivita
          Dettagli={false}
          progetto={progetto}
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
        />
      </TabPanel>
      <TabPanel value={"3"} index={indexString}>
        <Documentazione
          tipo={"curr"}
          progetto={progetto}
          idCampo={29}
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
        />
      </TabPanel>
      <TabPanel value={"4"} index={indexString}>
        <Pubblicazione
          numindex={4}
          progetto={progetto}
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
        />
      </TabPanel>
    </TabContext>
  );
};
