import { Grid, Typography, Link, Box } from "@mui/material";
import { aggiornaValoriSlice, getCountPagination, getVal1Slice, getVal2Slice } from "../../modules/configuration";
import { useDispatch, useSelector } from "react-redux";
import { DettagliLink } from "../pages/dettagliProgetto/DettagliLink";
import { labels } from "../../labels";
import { format } from "../pages/gestisciProgetti/TableProgetti";
import PrintIcon from "@mui/icons-material/Print";
import { MobileScreenShareOutlined } from "@mui/icons-material";
import { PATH_SCHEDA_ISTITUTO, PATH_SCRIVANIA } from "../../path";
import { PaginationShared } from "./Pagination";
import { useState } from "react";

const { COMPLETAMENTO, TIPOLOGIA_PROGETTO, DATA_INSERIMENTO, DETTAGLI_TABLE_RESPONSIVE, AVVISO_ASSENZA_PROGETTI, BUTTON_CONDIVIDI, BUTTON_SCARICA } =
  labels;

export const TableSchedaProfiloResponsive = (props) => {
  let progetti = props.progetti;
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  const dispatch = useDispatch();
  let istituti = props.istituto;
  const countPagination = useSelector(getCountPagination);
  const [page, setPage] = useState(1);

  function handlechange(e, value) {
    dispatch(aggiornaValoriSlice(value));
    setPage(value);
  }
  function SortArray(x, y) {
    let varx = x.data_inserimento;
    let vary = y.data_inserimento;
    if (x.data_inserimento === null) {
      varx = "1900-01-01 00:00:00";
    }
    return new Date(varx) - new Date(vary);
  }

  progetti = progetti !== null ? [...progetti].sort(SortArray) : null;
  progetti = progetti !== null ? progetti.reverse() : null;

  return progetti !== null ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container className="tableResponsive">
          {progetti.slice(valore1slice, valore2slice).map((elem, index) => (
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ marginTop: "30px", marginBottom: "30px" }} key={index}>
              <Grid container sx={{ display: "block" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    borderColor: props.borderColor,
                    bgcolor: props.color,
                  }}
                  className="center borderTableResponsive"
                >
                  <Typography className="titleTableResponsive">{elem.titolo_progetto}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      xl={6}
                      sx={{
                        borderColor: props.borderColor,
                      }}
                      className="borderTableResponsive"
                    >
                      <Typography className="campiTableResponsive">{COMPLETAMENTO}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      xl={6}
                      sx={{
                        borderColor: props.borderColor,
                      }}
                      className="borderTableResponsive"
                    >
                      {" "}
                      <Typography
                        sx={{
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                        {elem.completamento === null ? "non inserito" : elem.completamento + "%"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      xl={6}
                      sx={{
                        borderColor: props.borderColor,
                      }}
                      className="borderTableResponsive"
                    >
                      <Typography className="campiTableResponsive">{TIPOLOGIA_PROGETTO}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      xl={6}
                      sx={{
                        borderColor: props.borderColor,
                      }}
                      className="borderTableResponsive"
                    >
                      {" "}
                      <Typography
                        sx={{
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                        {elem.descr_t_progetto}{" "}
                      </Typography>{" "}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      xl={6}
                      sx={{
                        borderColor: props.borderColor,
                      }}
                      className="borderTableResponsive"
                    >
                      <Typography className="campiTableResponsive">{DATA_INSERIMENTO}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      xl={6}
                      sx={{
                        borderColor: props.borderColor,
                      }}
                      className="borderTableResponsive"
                    >
                      {" "}
                      <Typography
                        sx={{
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {format(new Date(elem.data_inserimento), "dd-MM-yyyy")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    borderColor: props.borderColor,
                  }}
                  className="borderTableResponsive"
                >
                  <Grid
                    container
                    sx={{
                      borderColor: props.borderColor,
                      marginTop: "10px",
                    }}
                  >
                    <Grid item xs={4}>
                      <Grid container sx={{ justifyContent: "center", display: "flex" }}>
                        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-20px" }}>
                          <DettagliLink path={istituti === true ? PATH_SCHEDA_ISTITUTO : PATH_SCRIVANIA} id={elem.uuid_progetto} />
                        </Grid>
                        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-20px" }}>
                          <Typography> {DETTAGLI_TABLE_RESPONSIVE}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} sx={{ justifyContent: "center", display: "flex" }}>
                      <Link
                        onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=true", "_blank")}
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          color: "black !important",
                          cursor: "pointer",
                          textDecoration: "none",
                          ":hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Grid container sx={{ justifyContent: "center", display: "flex" }}>
                          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                            <PrintIcon sx={{ color: "black" }} />
                          </Grid>

                          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                            <Typography> {BUTTON_SCARICA}</Typography>
                          </Grid>
                        </Grid>
                      </Link>
                    </Grid>
                    <Grid item xs={4} sx={{ justifyContent: "center", display: "flex" }}>
                      <Link
                        onClick={() => window.open(window.origin + "/shared/v1/projects/" + elem.uuid_progetto + "?print=false", "_blank")}
                        sx={{
                          color: "black !important",
                          cursor: "pointer",
                          textDecoration: "none",
                          ":hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                            <MobileScreenShareOutlined sx={{ color: "black" }} />
                          </Grid>
                          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                            <Typography>{BUTTON_CONDIVIDI}</Typography>
                          </Grid>
                        </Grid>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", "@media (max-width:900px)": { display: "none" } }}>
        {progetti === null ? (
          <Typography sx={{ fontSize: "22px", fontWeight: "Bold", marginTop: "20px" }}>{AVVISO_ASSENZA_PROGETTI}</Typography>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            justifyContent: "center !important",
            display: "none",
            marginTop: "20px",
            "@media (max-width:900px)": { display: "flex !important" },
          }}
        >
          {countPagination > 1 ? (
            <PaginationShared page={page} handlechange={handlechange} color={props.borderColor} count={countPagination} />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  ) : null;
};
