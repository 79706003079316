import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Box, TextField, Autocomplete } from "@mui/material";
import { TabPanel } from "../../shared/TabPanelCustom";
import { Search as SearchIcon } from "@mui/icons-material";
import { labels } from "../../../labels";
import { ScrivaniaIcon } from "../../../styles/icons";
import {
  resetDropDown,
  getVal1Slice,
  getVal2Slice,
  resetValoriSlice,
  getFilterTipologia,
  getFilterDataInizio,
  getFilterDataInizioOrdine,
  getFilterImieiP,
  getFilterPubblicato,
  getFilterTitle,
  getFilterCompletamento,
  getIndexGestisciProgetti,
  setIndexGestisciProgetti,
  getIdProgetto,
  setThemeColor,
} from "../../../modules/configuration";
import { PATH_SCRIVANIA } from "../../../path";
import { TableProgetti } from "./TableProgetti";
import { HeaderPage } from "../../shared/Header";
import {
  getProgettiBozza,
  getProgettiConclusi,
  getProgettiIstituto,
  fetchSezioneMieiProgetti,
  fetchSezioneProgettiIstituto,
} from "../../../modules/datiProgetti";
import { PROFILO_CODICE_SCUOLA, getInfoProfilo } from "../../../modules/profilo";
import { LoadingSpinner } from "../../shared";
import { TableGestisciProgResp } from "../../shared/TableGestisciProgResp";
import { NavigationType, useNavigationType } from "react-router-dom";
import { PUT_SBLOCCO_MODIFICA } from "../../../apiUrl";
import { createRequest } from "../../../utility";
import { notificationCodes, setNotification } from "../../../modules/notification";

const { PROGETTI_BOZZA, PROGETTI_PUBBLICATI, PROGETTI_ISTITUTO, GESTIONE_PROGETTI, NO_DATA } = labels;

const tabs = [{ label: PROGETTI_BOZZA }, { label: PROGETTI_PUBBLICATI }, { label: PROGETTI_ISTITUTO }];

export const GestisciProgetti = () => {
  const navigationType = useNavigationType(); //per button indietro da browser
  const [navigation, setNavigation] = useState(navigationType);
  const dispatch = useDispatch();
  const progettiBozza = useSelector(getProgettiBozza);
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  let indexTabsGestProg = useSelector(getIndexGestisciProgetti);
  const [value, setValue] = useState(indexTabsGestProg);
  const progettiConclusi = useSelector(getProgettiConclusi);
  const progettiIstituto = useSelector(getProgettiIstituto);
  const [search, setSearch] = useState("");
  let progettiTitleFiltered = [];
  let progettiTitle = [];
  let title = [];
  const filterCompletamento = useSelector(getFilterCompletamento);
  const filterSort = useSelector(getFilterTitle);
  const filterDataInizio = useSelector(getFilterDataInizio);
  const filterTipologia = useSelector(getFilterTipologia);
  const filterDataInizioOrdine = useSelector(getFilterDataInizioOrdine);
  const filterImieiP = useSelector(getFilterImieiP);
  const filterPubblicato = useSelector(getFilterPubblicato);
  const profilo = useSelector(getInfoProfilo);
  let codiceScuola = profilo !== null ? profilo[PROFILO_CODICE_SCUOLA] : null;
  let uuid_progetto = useSelector(getIdProgetto);

  //controllo sul bottone indietro da browser invece che da app per cambiare flag a db del progetto indicandolo come non sessione apertura progetto chiusa
  useEffect(() => {
    return () => {
      if (navigation === NavigationType.Pop) {
        createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
          uuid_progetto: uuid_progetto,
        })
          .then((response) => {
            if (response.status === 200) {
              console.log("sblocco");
            }
          })
          .catch((e) => {
            dispatch(
              setNotification({
                tipo: notificationCodes.ERROR,
                message: "Errore durante il cambio stato del progetto in modifica",
                description: "Errore durante il cambio stato del progetto in modifica",
              })
            );
          });
        setNavigation(null);
      }
    };
    // eslint-disable-next-line
  });

  // Fix accessibilità
  useEffect(() => {
    const autocomplete = document.getElementsByClassName("MuiAutocomplete-input");
    if (autocomplete.length > 0) {
      for (let i = 0; i < autocomplete.length; i++) {
        autocomplete[i].removeAttribute("role");
      }
    }
    const input = document.getElementById("ricercaTitolo");
    if (input != null) input.setAttribute("aria-label", "ricerca_per_titolo");

    const tabs = document.getElementsByClassName("MuiTab-root");
    if (tabs.length > 0) {
      for (let i = 0; i < tabs.length; i++) {
        tabs[i].removeAttribute("aria-controls");
      }
    }
    // eslint-disable-next-line
  }, []);

  //dati progetti

  useEffect(() => {
    if (codiceScuola != null) dispatch(fetchSezioneProgettiIstituto(codiceScuola));
    // eslint-disable-next-line
  }, [codiceScuola]);

  useEffect(() => {
    dispatch(fetchSezioneMieiProgetti());
    // eslint-disable-next-line
  }, []);

  //setto il colore del tema da seguire nelle pagine successive
  useEffect(() => {
    dispatch(setThemeColor({ color1: "#105B70 !important", color2: "#98BCBD !important" }));
    // eslint-disable-next-line
  }, []);

  // handleChange cambio Tab
  const handleChange = (event, newValue) => {
    dispatch(setIndexGestisciProgetti(newValue));
    setValue(newValue);
    dispatch(resetDropDown()); //azzero i valori delle dropdown
    dispatch(resetValoriSlice()); //azzero i valori della pagina di tabella da visualizzare
  };

  //in base al tab setto i progetti da mostrare in tabella
  if (value === 0) {
    progettiTitle = progettiBozza.data;
  }
  if (value === 1) {
    progettiTitle = progettiConclusi.data;
  }
  if (value === 2) {
    progettiTitle = progettiIstituto.data;
  }

  //estraggo valori dei titoli dei progetti per le opzioni dell'autocomplete di ricerca
  progettiTitle.map((elem, index) => (title[index] = elem.titolo_progetto));

  //filtro dati se ricerca attiva
  if (search !== "" && search !== undefined) {
    progettiTitleFiltered = progettiTitle.filter((opt) => opt.titolo_progetto === search);
  } else progettiTitleFiltered = progettiTitle;

  //filtro tipologia
  if (filterTipologia !== "") {
    progettiTitleFiltered = progettiTitleFiltered.filter((opt) => opt.descr_t_progetto === filterTipologia);
  }

  //FILTRO PER STATO PUBBLICAZIONE
  if (filterPubblicato !== "") {
    progettiTitleFiltered = progettiTitleFiltered.filter((opt) => opt.descr_stato_progetto === filterPubblicato);
  }

  //filtro riordinamento completamento
  if (filterCompletamento === "Ordina dal più completo") {
    function SortArray(x, y) {
      let varx = x.completamento;
      let vary = y.completamento;
      return vary - varx;
    }
    progettiTitleFiltered = [...progettiTitleFiltered].sort(SortArray);
  }
  if (filterCompletamento === "Ordina dal meno completo") {
    function SortArray(x, y) {
      let varx = x.completamento;
      let vary = y.completamento;
      return varx - vary;
    }
    progettiTitleFiltered = [...progettiTitleFiltered].sort(SortArray);
  }

  //filtro riordinamento alfabetico
  if (filterSort === true) {
    function SortArray(x, y) {
      return x.titolo_progetto.localeCompare(y.titolo_progetto, "fr", {
        ignorePunctuation: true,
      });
    }
    progettiTitleFiltered = [...progettiTitleFiltered].sort(SortArray);
  }

  //filtro riordinamento data inserimento dec e crescente
  if (filterDataInizio === true) {
    function SortArray(x, y) {
      let varx = x.data_inserimento;
      let vary = y.data_inserimento;
      if (x.data_inserimento === null) {
        varx = "1900-01-01 00:00:00";
      }
      return new Date(varx) - new Date(vary);
    }
    progettiTitleFiltered = [...progettiTitleFiltered].sort(SortArray);

    if (filterDataInizioOrdine !== "dataMenoRec") {
      progettiTitleFiltered = progettiTitleFiltered.reverse();
    }
  }

  //filtro referente miei progetti

  if (filterImieiP === true) {
    function SortArray(x, y) {
      return x.referente_cogn.localeCompare(y.referente_cogn, "fr", {
        ignorePunctuation: true,
      });
    }
    progettiTitleFiltered = [...progettiTitleFiltered].sort(SortArray);
  }

  return (
    <Box className="gestisciProgetti">
      <HeaderPage icon={<ScrivaniaIcon />} title={GESTIONE_PROGETTI} color={"#105B70"} />
      <Box className="center">
        <Autocomplete
          id="ricercaTitolo"
          disablePortal
          role="search"
          options={title}
          popupIcon={<SearchIcon />}
          noOptionsText={NO_DATA}
          onChange={(e) => setSearch(e.target.outerText)}
          className="autocompleteSearch"
          renderInput={(params) => <TextField {...params} placeholder="Ricerca per titolo" className="textfieldSearch" />}
        />
      </Box>
      <Box>
        <Tabs
          TabIndicatorProps={{
            sx: { display: "none" },
          }}
          value={value}
          onChange={handleChange}
          color="black"
          variant="fullWidth"
          aria-label="full width tabs example"
          className="tabs"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} id={"full-width-tab-" + index} aria-controls={"full-width-tabpanel-" + index} className="textTab" />
          ))}
        </Tabs>
        <TabPanel value={value} index={0}>
          <TableGestisciProgResp
            indexTabs={value}
            azioni={false}
            tabIstituti={false}
            borderColor={"#105B70"}
            color2={"#98BCBD"}
            color1={"#105B70 !important"}
            progetti={progettiTitleFiltered}
            path={PATH_SCRIVANIA}
          />
          <TableProgetti
            indexTabs={value}
            valore1slice={valore1slice} //VALORI SLICE PER DECIDERE QUANTI DATI VISUALIZZARE NELLA PAGINA
            valore2slice={valore2slice}
            azioni={false}
            progetti={progettiTitleFiltered}
            tabIstituti={false}
            color2={"#98BCBD"}
            color1={"#105B70 !important"}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableGestisciProgResp
            indexTabs={value}
            tabIstituti={false}
            azioni={true}
            borderColor={"#105B70"}
            color2={"#98BCBD"}
            color1={"#105B70 !important"}
            progetti={progettiTitleFiltered}
            path={PATH_SCRIVANIA}
          />
          <TableProgetti
            indexTabs={value}
            valore1slice={valore1slice}
            valore2slice={valore2slice}
            azioni={true}
            progetti={progettiTitleFiltered}
            tabIstituti={false}
            color2={"#98BCBD"}
            color1={"#105B70 !important"}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TableGestisciProgResp
            indexTabs={value}
            azioni={true}
            tabIstituti={true}
            borderColor={"#105B70"}
            color2={"#98BCBD"}
            color1={"#105B70 !important"}
            progetti={progettiTitleFiltered}
            path={PATH_SCRIVANIA}
          />
          <TableProgetti
            indexTabs={value}
            valore1slice={valore1slice}
            valore2slice={valore2slice}
            azioni={true}
            progetti={progettiTitleFiltered}
            tabIstituti={true}
            color2={"#98BCBD"}
            color1={"#105B70 !important"}
          />
        </TabPanel>
      </Box>
      <LoadingSpinner loading={!progettiBozza.fetchEnded || !progettiIstituto.fetchEnded || !progettiConclusi.fetchEnded} />
    </Box>
  );
};
