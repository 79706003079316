import { Box } from "@mui/material";
import { DettagliIcon } from "../../../styles/icons";
import { PATH_ISTITUTI_SCOLASTICI, PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI, PATH_PROGETTI_INNOVAZIONE_SCUOLA } from "../../../path";
import { useDispatch, useSelector } from "react-redux";
import { getIdProgetto, getPath, getTheme } from "../../../modules/configuration/selectors.js";
import { fetchDettagliProgetto, getDettagliProgetto } from "../../../modules/datiProgetti";
import { saveSottoAttivita, setAttivitaSelezionata } from "../../../modules/configuration/actions.js";
import { useEffect } from "react";
import { labels } from "../../../labels.jsx";
import { TabDettagliCurr } from "./TabDettagliCurr.jsx";
import { TabDettagliInnovazione } from "./TabDettagliInnovazione.jsx";
import { HeaderPage } from "../../shared/Header.jsx";
import { LoadingSpinner } from "../../shared/LoadingSpinner.jsx";

const { DETTAGLI } = labels;

export const DettagliPage = () => {
  const dispatch = useDispatch();
  const path = useSelector(getPath);
  const dettagliProgetto = useSelector(getDettagliProgetto);
  let progetto = dettagliProgetto.data;
  let id = useSelector(getIdProgetto);

  useEffect(() => {
    dispatch(fetchDettagliProgetto("/" + id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //per sezione dettagli creo map in redux con attivita dati
    if (dettagliProgetto.fetchEnded === true) {
      if (progetto.length !== 0) {
        for (let i = 0; i < progetto.attivita.length; i++) {
          let elementoAtt = progetto.attivita[i].finalita + " " + progetto.attivita[i].descr_campo;
          let elementoSottoAtt = progetto.attivita[i].descr_valore;
          dispatch(saveSottoAttivita({ elementoSottoAtt, elementoAtt }));
          dispatch(setAttivitaSelezionata("in"));
        }
      }
    }
    // eslint-disable-next-line
  }, [progetto]);

  //stabilisco colore predominante nel tema della visualizzazione dei dettagli in base alla sezione dalla quale arrivo
  let theme = useSelector(getTheme);
  let color1 = theme.color1;
  let color2 = theme.color2;

  return (
    <Box className="dettagli">
      <HeaderPage
        linkBack={true}
        color={color1}
        title={DETTAGLI}
        titleProg={dettagliProgetto.fetchEnded ? "- " + progetto.titolo_progetto : null}
        icon={<DettagliIcon />}
        resetFiltri={false}
      />
      {progetto.descr_t_progetto !== undefined ? (
        progetto.descr_t_progetto === "Curricolare" || progetto.descr_t_progetto === "Extracurricolare" ? (
          <TabDettagliCurr progetto={progetto} color1={color1} color2={color2} />
        ) : (
          <TabDettagliInnovazione progetto={progetto} color1={color1} color2={color2} />
        )
      ) : null}
      <LoadingSpinner loading={!dettagliProgetto.fetchEnded} />
    </Box>
  );
};
