import { Grid, List, Paper, Typography } from "@mui/material";
import { labels } from "../../../labels";

const { DISCIPLINE_COINVOLTE, ATTIVITA_CLIL, INTERDISCIPLINARIETA, CONTESTO_ACCOMPAGNAMENTO, DESCRIZIONE_ACCOMPAGNAMENTO, TEMPI_SPAZI_COMPETENZE } =
  labels;

export const ContestoDettCurr = (props) => {
  let progetto = props.progetto;
  let discipline = progetto.discipline;
  let attivitaTot = progetto.attivita;
  let tempiSpaziComp = [];

  //estraggo dalle attività quelle appartenenti alla sezione contesto

  const checkSezioneAtt = (attivitaTot) => {
    const checkSezioneAtt = attivitaTot.descr_sottotab === "CONTESTO";
    return checkSezioneAtt;
  };

  let attivita = attivitaTot.filter(checkSezioneAtt);

  //creo array di attività per la renderizzazione di queste

  const tempiSpCom = () => {
    let array = [];
    for (let i = 0; i < attivita.length; i++) {
      if (tempiSpaziComp.includes(attivita[i].finalita + " " + attivita[i].descr_campo) !== true) {
        tempiSpaziComp.push(attivita[i].finalita + " " + attivita[i].descr_campo);
        array.push(
          <Grid item xs={12} md={12} sm={12} xl={4} key={i}>
            <Grid
              container
              sx={{
                bgcolor: props.color1,
              }}
              className="attivitaDettagli"
            >
              <Typography sx={{ "@media (max-width:360px)": { fontSize: "16px !important" } }}>
                {" "}
                {attivita[i].finalita + " " + attivita[i].descr_campo}{" "}
              </Typography>
            </Grid>

            {attivita.map(
              (elem, index) =>
                attivita[i].descr_campo === elem.descr_campo && (
                  <Grid
                    container
                    className="attivitaDettagli"
                    sx={{
                      bgcolor: "white",
                      border: "solid 3px",
                      borderColor: props.color1,
                    }}
                    key={index}
                  >
                    <Typography sx={{ color: "black", "@media (max-width:360px)": { fontSize: "16px !important" } }}>
                      {" "}
                      {elem.descr_valore_progetto !== null ? elem.descr_valore + " (" + elem.descr_valore_progetto + ")" : elem.descr_valore}
                    </Typography>
                  </Grid>
                )
            )}
          </Grid>
        );
      }
    }
    return <>{array}</>;
  };

  return (
    <Grid container className="dettagli">
      <Grid item xs={12} md={4} sm={12} xl={4}>
        <Typography className="textTitle">{DISCIPLINE_COINVOLTE}</Typography>
        {discipline.length === 0 ? (
          <Typography className="dato">-</Typography>
        ) : (
          <Grid container spacing={1}>
            {discipline.map((elemento, index) => (
              <Grid item key={index}>
                <Paper
                  className="disciplineDettagli"
                  sx={{
                    border: "solid 3px" + props.color1,
                    bgcolor: "white",
                    ":hover": {
                      border: "solid 3px" + props.color1,
                      bgcolor: "white",
                    },
                  }}
                  key={{ index }}
                >
                  <Grid container sx={{ marginTop: "10px", cursor: "default" }}>
                    <Grid item xs={12} sx={{ textAlign: "left", verticalAlign: "middle" }}>
                      <Typography sx={{ margin: "10px", marginLeft: "40px !important" }}> {elemento.descrizione} </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}{" "}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={4} sm={12} xl={8}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <Typography className="textTitle">{INTERDISCIPLINARIETA}</Typography>
            <Typography className="dato">
              {progetto.interdisciplinarieta === null ? <Typography className="dato">-</Typography> : progetto.interdisciplinarieta}{" "}
            </Typography>
            <Typography className="textTitle">{ATTIVITA_CLIL}</Typography>
            <Typography className="dato">
              {progetto.descr_attivita_clil === null ? <Typography className="dato">-</Typography> : progetto.descr_attivita_clil}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <Typography className="textTitle">{CONTESTO_ACCOMPAGNAMENTO}</Typography>
            <Typography className="dato">
              {progetto.descr_contesto_accompagnamento === null ? (
                <Typography className="dato">-</Typography>
              ) : (
                progetto.descr_contesto_accompagnamento
              )}{" "}
            </Typography>
            <Typography className="textTitle">{DESCRIZIONE_ACCOMPAGNAMENTO}</Typography>
            <Typography className="dato">
              {progetto.nome_mentor === null ? <Typography className="dato">-</Typography> : progetto.nome_mentor}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography className="textTitle">{TEMPI_SPAZI_COMPETENZE}</Typography>
        {attivita.length === 0 ? (
          <Typography className="dato">-</Typography>
        ) : (
          <List className="list">
            {" "}
            <Grid container>{tempiSpCom()} </Grid>
          </List>
        )}
      </Grid>
    </Grid>
  );
};
