import { styled } from "@mui/material/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid, Box } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { getProfiliInUscita } from "../../../../modules/istituti";
import { PaginationShared } from "../../../shared/Pagination";
import { aggiornaValoriSlice, getFilterSort, setCountPagination } from "../../../../modules/configuration";
import { useState } from "react";
import { labels } from "../../../../labels";
import { TableResponsiveProfUscita } from "../../../shared/TableResponsiveProfUscita";

const { ORDINAMENTO, INDIRIZZO_QUALIFICA, SETTORE, ARTICOLAZIONE, AVVISO_ASSENZA_PROFILI_USCITA } = labels;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const createData = (ordinamento, settore, indirizzo, articolazione) => ({
  ordinamento,
  settore,
  indirizzo,
  articolazione,
});

export const ProfiliInUscita = (props) => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: props.color2,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const filterSort = useSelector(getFilterSort);

  //aggiorno valori paginazione
  function handlechange(e, value) {
    dispatch(aggiornaValoriSlice(value));
    setPage(value);
  }

  const profiliUscita = useSelector(getProfiliInUscita);
  let progetti = profiliUscita.data;

  let rows = [""];
  if (progetti.length !== 0) {
    rows = progetti.map((elem, index) =>
      createData(elem.descr_ordinamento, elem.descr_settore, elem.descr_indirizzo_qualifica, elem.descr_articolazione)
    );
  }

  //filtro riordinamento alfabetico Denominazione scuola
  if (filterSort === ORDINAMENTO) {
    function SortArray(x, y) {
      return x.descr_ordinamento.localeCompare(y.descr_ordinamento, "fr", {
        ignorePunctuation: true,
      });
    }
    progetti = [...progetti].sort(SortArray);
  }

  if (filterSort === SETTORE) {
    function SortArray(x, y) {
      return x.descr_settore.localeCompare(y.descr_settore, "fr", {
        ignorePunctuation: true,
      });
    }
    progetti = [...progetti].sort(SortArray);
  }

  if (filterSort === INDIRIZZO_QUALIFICA) {
    function SortArray(x, y) {
      return x.descr_indirizzo_qualifica.localeCompare(y.descr_indirizzo_qualifica, "fr", {
        ignorePunctuation: true,
      });
    }
    progetti = [...progetti].sort(SortArray);
  }

  if (filterSort === ARTICOLAZIONE) {
    function SortArray(x, y) {
      return x.descr_articolazione.localeCompare(y.descr_articolazione, "fr", {
        ignorePunctuation: true,
      });
    }
    progetti = [...progetti].sort(SortArray);
  }

  //set count pagination
  dispatch(setCountPagination(Math.ceil(progetti.length / 5)));

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            "@media (max-width:1020px)": {
              display: "none",
            },
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" sx={{ width: "200px" }}>
                    <Grid container justifyContent={"center"}>
                      <Grid item>
                        <Typography className="fontBold">{ORDINAMENTO}</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>{" "}
                  <StyledTableCell align="center" sx={{ width: "200px" }}>
                    <Grid container justifyContent={"center"}>
                      <Grid item>
                        <Typography className="fontBold">{SETTORE}</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>{" "}
                  <StyledTableCell align="center" sx={{ width: "200px" }}>
                    <Grid container justifyContent={"center"}>
                      <Grid item>
                        <Typography className="fontBold">{INDIRIZZO_QUALIFICA}</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>{" "}
                  <StyledTableCell align="center" sx={{ width: "200px" }}>
                    <Grid container justifyContent={"center"}>
                      <Grid item>
                        <Typography className="fontBold">{ARTICOLAZIONE}</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(props.valore1slice, props.valore2slice).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">{row.ordinamento}</StyledTableCell>
                    <StyledTableCell align="center">{row.settore}</StyledTableCell>
                    <StyledTableCell align="center">{row.indirizzo}</StyledTableCell>
                    <StyledTableCell align="center">{row.articolazione}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <TableResponsiveProfUscita
            sx={{
              display: "none",
              "@media (max-width:960px)": {
                display: "block",
              },
            }}
            borderColor={props.color1}
            color={props.color1}
          />
        </Grid>
        <Grid item xs={12} className="center" sx={{ marginTop: "30px" }}>
          {Math.ceil(progetti.length / 5) > 1 ? (
            <PaginationShared page={page} handlechange={handlechange} color={props.color1} count={Math.ceil(progetti.length / 5)} />
          ) : null}
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginBottom: "30px" }}>
          {progetti.length === 0 ? <Typography sx={{ fontSize: "22px", fontWeight: "Bold" }}>{AVVISO_ASSENZA_PROFILI_USCITA}</Typography> : null}
        </Grid>
      </Grid>
    </Box>
  );
};
