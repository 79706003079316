import { Button, Card, Grid, Typography } from "@mui/material";
import { AttachFile as AttachFileIcon, Settings as SettingsIcon } from "@mui/icons-material/";
import { PATH_STRUMENTI_SCHEDA_INNOVAZIONE, PATH_STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE, PATH_STRUMENTI_TUTORIAL, PATH_STRUMENTI_FAQ_OSSERVATORIO, PATH_STRUMENTI_PIATTAFORMA_MOODLE } from "../../path.jsx";
import { labels } from "../../labels";
import { HeaderPage } from "../shared/Header.jsx";

const {
  STRUMENTI_IN_LABEL,
  STRUMENTI_INTRO,
  STRUMENTI_SCHEDA_INNOVAZIONE,
  STRUMENTI_SCHEDA_INNOVAZIONE_HELP,
  STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE,
  STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE_HELP,
  STRUMENTI_TUTORIAL,
  STRUMENTI_TUTORIAL_HELP,
  MENU_STRUMENTI,
  STRUMENTI_FAQ,
  STRUMENTI_FAQ_HELP,
  STRUMENTI_PIATTAFORMA_MOODLE,
  STRUMENTI_PIATTAFORMA_MOODLE_HELP
} = labels;

const LinkButton = ({ link, label }) => (
  <Button startIcon={<AttachFileIcon />} onClick={() => window.open(link, "_blank")}>
    {label}
  </Button>
);

export const Strumenti = () => (
  <>
    <HeaderPage icon={<SettingsIcon sx={{ fontSize: "55px" }} />} title={MENU_STRUMENTI} color={"#105b70"} />
    <Grid container spacing={2} className="strumenti">
      <Grid item xs={12}>
        <Typography>{STRUMENTI_INTRO}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <Grid container sx={{ padding: "20px" }}>
            {" "}
            <Grid item xs={12}>
              <LinkButton link={PATH_STRUMENTI_SCHEDA_INNOVAZIONE} label={STRUMENTI_SCHEDA_INNOVAZIONE} />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {STRUMENTI_IN_LABEL}
                <strong>{STRUMENTI_SCHEDA_INNOVAZIONE}</strong>
                {STRUMENTI_SCHEDA_INNOVAZIONE_HELP}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <Grid container sx={{ padding: "20px" }}>
            {" "}
            <Grid item xs={12}>
              <LinkButton link={PATH_STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE} label={STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE} />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {STRUMENTI_IN_LABEL}
                <strong>{STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE}</strong>
                {STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE_HELP}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>{" "}
      <Grid item xs={12} sm={6}>
        <Card>
          <Grid container sx={{ padding: "20px" }}>
            {" "}
            <Grid item xs={12}>
              <LinkButton link={PATH_STRUMENTI_FAQ_OSSERVATORIO} label={STRUMENTI_FAQ} />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {STRUMENTI_IN_LABEL}
                <strong>{STRUMENTI_FAQ}</strong>
                {STRUMENTI_FAQ_HELP}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <Grid container sx={{ padding: "20px" }}>
            {" "}
            <Grid item xs={12}>
              <LinkButton link={PATH_STRUMENTI_PIATTAFORMA_MOODLE} label={STRUMENTI_PIATTAFORMA_MOODLE} />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {STRUMENTI_IN_LABEL}
                <strong>{STRUMENTI_PIATTAFORMA_MOODLE}</strong>
                {STRUMENTI_PIATTAFORMA_MOODLE_HELP}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Grid container sx={{ padding: "20px" }}>
            {" "}
            <Grid item xs={12}>
              <LinkButton link={PATH_STRUMENTI_TUTORIAL} label={STRUMENTI_TUTORIAL} />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {STRUMENTI_IN_LABEL}
                <strong>{STRUMENTI_TUTORIAL}</strong>
                {STRUMENTI_TUTORIAL_HELP}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>
);
