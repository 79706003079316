const common = {
  SCEGLI_FILTRI_PROGETTI: "Scegli filtri",
  DATI_PROGETTO: "Dati progetto",
  CHIUDI: "Chiudi",
  SALVA_CHIUDI: "Salva e chiudi",
  ANNULLA_MODIFICHE: "Annulla modifiche",
  APRI: "Apri",
  CANCELLA: "Cancella",
  NO_DATA: "Nessun risultato trovato",
  CELLULARE_LABEL: "Cellulare",
  CELLULARE_PLACEHOLDER: "Inserisci numero di cellulare",
  MAIL_LABEL: "Mail",
  MAIL_PLACEHOLDER: "Inserisci indirizzo mail",
  SOCIAL_LABEL: "Link social",
  SOCIAL_PLACEHOLDER: "Inserisci link social (es. https://socialnetwork.com/utente)",
  INCARICO_LABEL: "Ruolo nell'istituto",
  ISTITUTO_LABEL: "Istituto",
  SCHEDA_ISTITUTO_LABEL: "Scheda istituto",
  YES: "Sì",
  NO: "No",
  ERROR_DATE: "La data inserita deve essere compresa tra",
  REQUIRED_FIELD: "Campo obbligatorio",
  LOGOUT_CONFIRM: "Sei sicuro di voler effettuare il logout?",
  ABSTRACT: "Abstract",
  NOTE: "Note",
  NUMERO_STUDENTI: "Numero studenti",
  STUDENTI_BES: "Studenti BES (prevalente)",
  COMPOSIZIONE_GRUPPO_STUDENTI: "Composizione gruppo studenti",
  TEMA_PREVALENTE: "Tema prevalente",
  ANNO_SCOLASTICO_REALIZZAZIONE: "Anno scolastico di realizzazione*",
  ATTIVITA_DIGITALE: "Attività digitale",
  CO_RESPONSABILE: "Co-responsabile progetto",
  CO_REFERENTE: "Co-referente",
  DISCIPLINE_COINVOLTE: "Discipline coinvolte",
  INTERDISCIPLINARIETA: "Interdisciplinarietà",
  ATTIVITA_CLIL: "Attività Clil",
  CONTESTO_ACCOMPAGNAMENTO: "Contesto di accompagnamento",
  DESCRIZIONE_ACCOMPAGNAMENTO: "Descrizione dell'accompagnamento",
  TEMPI_SPAZI_COMPETENZE: "Tempi/Spazi/Competenze",
  LINK_DOCUMENTAZIONE: "Link documentazione",
  AZIONE_DIFF_DISSEMINAZIONE: "[Azioni di diffusione/disseminazione] Indica come è stato condiviso pubblicamente il progetto",
  PUNTI_FORZA: "Punti di forza/opportunità",
  PUNTI_DEBOLEZZA: "Punti di debolezza/vincoli",
  TIPO_BENEFICIARI: "Tipo beneficiari",
  SUPPORTO_PROGETTO: "Supporto del progetto SDL",
  TIPOLOGIA_ISTITUTO: "Tipologia istituto",
  PROFILO_USCITA: "Profilo in uscita",
  TITOLO_PROGETTO: "Titolo progetto",
  REFERENTE_PROGETTO: "Referente progetto",
  DATA_INIZIO_PROGETTO: "Data inizio progetto",
  DENOMINAZIONE: "Denominazione istituto",
  CODICE_MECCANOGRAFICO: "Codice meccanografico",
  DATA_INSERIMENTO: "Data inserimento",
  ORDINE_SCUOLA: "Ordine di scuola",
  TIPOLOGIA_BENEFICIARIO: "Tipologia beneficiario",
  TIPOLOGIA_PROGETTO: "Tipologia progetto",
  COMUNE: "Comune",
  PROVINCIA: "Provincia",
  INDIRIZZO: "Indirizzo",
  FONDI_CONTESTO: "Fondi e contesto di finanziamento",
  ANNO_REALIZZAZIONE: "Anno scolastico di realizzazione",
  AREA_COINVOLTA: "Area coinvolta",
  PUBBLICATO: "Pubblicato",
  TEMPO_REALIZZAZIONE: "Tempo di realizzazione",
  REFERENTE: "Referente",
  DOCUMENTATO: "Documentato",
  ATTIVITA: "Attività",
  ATTIVITA_CONTENUTI: "Attività e contenuti",
  DOCUMENTAZIONE_VALUTAZIONE: "Documentazione e valutazione",
  DOCUMENTAZIONE: "Documentazione",
  VALUTAZIONE: "Valutazione",
  MODALE_INSERIMENTO: "Scegli la tipologia del progetto da inserire",
  INFO_CARATTERI: "Numero caratteri inseriti:",
  COMPLETAMENTO: "Completamento",
  PUBBLICAZIONE: "Pubblicazione",
  DETTAGLI: "Dettagli progetto",
  AZIONI: "Azioni",
  MODIFICA: "Modifica",
  ELIMINA: "Elimina",
  DENOMINAZIONE_ISTITUTO: "Denominazione istituto",
  CHEDA_ISTITUTO: "Scheda istituto",
  BIOGRAFIA: "Biografia",
  PLACEHOLDER_BIOGRAFIA: "Inserisci una biografia...",
  DATI_PERSONALI: "I tuoi dati personali",
  CELLULARE: "Cellulare",
  PLACEHOLDER_CELLULARE: "InserisciCellulare",
  MAIL: "Indirizzo mail",
  LINK_SOCIAL: "Link social",
  IMPOSTAZIONI: "Altre impostazioni",
  ISTITUTO_APPARTENENZA: "Istituto di appartenenza",
  RUOLO: "Ruolo",
  RUOLO_ISTITUTO: "Ruolo dell'istituto",
  CAMPO_CAMBIA_RUOLO: "Cambia ruolo",
  INFORMATIVA: "Informativa sul trattamento dei dati personali",
  CONTROLLO_CELLULARE: "Numero di cellulare valido",
  CONTROLLO_EMAIL: "Indirizzo email corretto",
  ISTITUTO: "Istituto",
  CAMPO_RUOLO_NUOVO: "Quale sarà il tuo ruolo?",
  INFO_ISTITUTO_APPARTENENZA: "Il tuo istituto è:",
  TITOLO_MODALE_PROFILO: "Cambia istituto",
  NUMERO_SEGRETERIA: "Numero segreteria",
  AVVISO_CONTATTO_ISTITUTO: "Per notificare modifiche sui dati dell'Istituto, scrivere a",
  SITO_WEB: "Sito web",
  ANAGRAFICA: "Anagrafica",
  CONTESTO: "Contesto",
  RICERCA_TITOLO_PROGETTI: "Ricerca per titolo",
  PROGETTI_BOZZA: "I miei progetti in bozza",
  PROGETTI_PUBBLICATI: "I miei progetti pubblicati",
  PLESSI_ISTITUTO: "Plessi istituto",
  PROFILI_USCITA: "Profili in uscita",
  PROGETTI_ISTITUTO: "I progetti dell'istituto",
  MEMBRI_TEAM: "I membri del team dell'istituto",
  TITOLO_DATI_ISTITUTO: "I dati dell'istituto",
  CONTATTI_ISTITUTO: "I contatti dell'istituto",
  ORDINAMENTO: "Ordinamento",
  GRADO_SCUOLA: "Ordine scuola",
  SETTORE: "Settore",
  INDIRIZZO_QUALIFICA: "Indirizzo-qualifica",
  ARTICOLAZIONE: "Articolazione",
  MAPPA: "Mappa osservatorio",
  RICERCA: "Cerca nell'osservatorio",
  ISTITUTI_SCOLASTICI: "Istituti scolastici",
  PROGETTI_INNOVAZIONE: "Progetti Innovazione scuola",
  PROGETTI_CURRICOLARE: "Progetti Curricolari o Extra-Curricolari",
  PROGETTI: "Progetti -",
  DATI_SINTESI: "Dati sintesi",
  PROFILO: "Il mio profilo",
  GESTIONE_PROGETTI: "Gestione progetti",
  INSERIMENTO: "Inserimento progetto",
  SCELTA_TIPOLOGIA: "Scegli tipologia",
  TIPOLOGIA_INNOVAZIONE: "Innovazione",
  TESTO_MODALE_ELIMINA: "Sei sicuro di voler eliminare il progetto?",
  AVVISO_RISULTATO_RICERCA: "I filtri impostati non hanno restituito nessun risultato. Cambiare i parametri di ricerca e riprovare.",
  AVVISO_ASSENZA_PLESSI: "Non sono presenti plessi",
  AVVISO_ASSENZA_ISTITUTI: "I filtri impostati non hanno restituito nessun risultato. Cambiare i parametri di ricerca e riprovare.",
  AVVISO_ASSENZA_PROFILI_USCITA: "Non sono presenti profili in uscita",
  AVVISO_ASSENZA_PROGETTI: "Non sono presenti progetti",
  TEXT_DIALOG_COMPLETAMENTO_PROGETTI: "Complimenti! Hai raggiunto il 100% del progetto: assicurati di aver pubblicato il progetto su mappa pubblica rendendolo patrimonio dell'Istituto e della Community di Scuola Digitale Liguria.", // prettier-ignore
  TEXT_DIALOG_PROGETTO_COMPLETATO: "Non hai ancora raggiunto il 100% del progetto: compila tutti i campi opzionali e inserisci il link alla documentazione multimediale.", // prettier-ignore
  TESTO_CONTROLLO_USCITA_INSERIMENTO: "Sei sicuro di voler uscire senza salvare eventuali nuovi dati inseriti e non ancora salvati?",
  TESTO_MODALE_SPOSTAMENTO_TAB:"Eventuali dati inseriti e non salvati nella presente sezione andranno persi. Vuoi continuare?",
  STATO_PUBBLICAZIONE: "Stato di pubblicazione",
  PUBBLICAZIONE_MAPPA: "Progetto pubblicato su mappa",
  PUBBLICAZIONE_OSSERVATORIO: "Progetto pubblicato nell'osservatorio",
  PUBBLICAZIONE_BOZZA: "Progetto in bozza",
  PUBBLICAZIONE_BOZZA_DESCRIZIONE: "Il progetto è visibile al team dell'istituto e modificabile solo da te e dai collaboratori del progetto",
  PUBBLICAZIONE_OSSERVATORIO_DESCRIZIONE: "Il progetto è visibile all'interno dell'osservatorio,da tutti i docenti ",
  PUBBLICAZIONE_MAPPA_DESCRIZIONE: "Il progetto è visibile su mappa da chiunque",
  TEXT_BUTTON_PUBBLICAZIONE: "Raggiungi il 100% del progetto compilando tutti i campi opzionali e inserendo il link alla documentazione multimediale.", // prettier-ignore
  TEXT_BUTTON_PUBBLICAZIONE_2: "Per una guida alla realizzazione della documentazione, consulta la ",
  TEXT_BUTTON_PUBBLICAZIONE_LINK: "Check-list",
  CONTENUTI_CORRELATI: "Contenuti correlati",
  NUMERO_ATTIVITA_INSERITE: "Numero attività inserite:",
  LINK_FORMAT_ERROR: "Link inserito non valido",
  AVVISO_SESSIONE_ELIMINAZIONE_PROGETTO: "Eliminazione non abilitata, progetto aperto in un'altra sessione",
  DICHIARAZIONE_ACCESSIBILITA: "Dichiarazione di accessibilità",
  SCHEDA_UTENTE: "Scheda utente",
  AVVISO_UTENTE_DISABILITATO: "L'utente cercato è disabilitato",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const tableResponsive = {
  TITLE_TABLE_RESPONSIVE: "Progetto",
  CAMPO0_TABLE_RESPONSIVE: "Completamento",
  CAMPO1_TABLE_RESPONSIVE: "Tipologia",
  CAMPO2_TABLE_RESPONSIVE: "Denominazione istituto",
  CAMPO3_TABLE_RESPONSIVE: "Data inserimento",
  CAMPO4_TABLE_RESPONSIVE: "Referente",
  CAMPO5_TABLE_RESPONSIVE: "Pubblicazione",
  DETTAGLI_TABLE_RESPONSIVE: "Dettagli",
  MODIFICA_TABLE_RESPONSIVE: "Modifica",
  ELIMINA_TABLE_RESPONSIVE: "Elimina",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const menu = {
  MENU_BACKOFFICE: "Backoffice",
  MENU_GESTISCI_PROFILO: "Gestisci profilo",
  MENU_SCHEDA_ISTITUTO: "Scheda istituto",
  MENU_STRUMENTI: "Strumenti",
  MENU_LOGOUT: "Logout",
  MENU_AREA_PERSONALE: "Area personale",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const profilo = {
  PROFILO_UPLOAD_ERROR_DIMENSION_TITLE: "File troppo grande",
  PROFILO_UPLOAD_ERROR_DIMENSION_DESCRIPTION: "Controlla che il file che intendi caricare abbia una dimensione inferiore ai 3 MB", //prettier-ignore
  PROFILO_UPLOAD_AVATAR: "Carica immagine",
  PROFILO_UPLOAD_AVATAR_DIMENSIONE: "(Dimensione massima: 3 MB)",
  PROFILO_AVATAR_A_SISTEMA: "Immagine recuperata da sistema",
  PROFILO_BIOGRAFIA_LABEL: "Biografia",
  PROFILO_BIOGRAFIA_PLACEHOLDER: "Inserisci una breve biografia...",
  PROFILO_DATI_PERSONALI: "I tuoi dati personali",
  PROFILO_CELLULARE_ERROR: "Numero di cellulare non valido",
  PROFILO_CELLULARE_ERROR_LENGTH: "Il numero di cellulare deve essere di 10 cifre",
  PROFILO_MAIL_ERROR: "Formato email non valido",
  PROFILO_NEWSLETTER: "Iscriviti alla newsletter",
  PROFILO_PRIVACY: "Informativa sul trattamento dei dati personali",
  PROFILO_AUTOCERTIFICAZIONE: "Autocertificazione - dichiarazione sostitutiva",
  PROFILO_ISTITUTO_APPARTENENZA: "Istituto di appartenenza e ruolo",
  PROFILO_ISTITUTO_APPART: "Istituto di appartenenza",
  PROFILO_ISTITUTO_RUOLO: "Ruolo nell'istituto",
  PROFILO_INCARICO_PROVVISORIO: "Il ruolo attuale non è modificabile in quanto in attesa di approvazione",
  INCARICO_PLACEHOLDER: "Seleziona un ruolo",
  ISTITUTO_PLACEHOLDER: "Seleziona un istituto",
  PROFILO_ISTITUOTO_PROVVISORIO: "L'istituto non è modificabile fino a quando il nuovo ruolo non verrà approvato",
  PROFILO_RUOLO_NON_UNICO: "Per l'incarico selezionato esiste già una persona incaricata ",
  PROFILO_RUOLO_NON_UNICO_CONTINUA: "Vuoi procedere comunque?",
  PROFILO_DISABILITA_UTENTE: "Disabilita profilo",
  PROFILO_DISABILITA_UTENTE_CONFERMA: "Sei sicuro di disabilitare il profilo?",
  PROFILO_DISABILITA_UTENTE_CONFERMA_SI: "Sì, disabilita",
  PROFILO_DISABILITA_UTENTE_CONFERMA_NO: "No, annulla",
  PROFILO_CONFERMA_MODIFICA_ALERT: "Attenzione!",
  PROFILO_CONFERMA_MODIFICA_ALERT_INFO: "I campi di questa sezione sono soggetti all'autocertificazione per cui hai già prestato il consenso. Vuoi proseguire?", // prettier-ignore
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const strumenti = {
  STRUMENTI_IN_LABEL: "In",
  STRUMENTI_INTRO: "In questa sezione puoi consultare alcune risorse per utilizzare al meglio la piattaforma Osservatorio",
  STRUMENTI_SCHEDA_INNOVAZIONE: "Scheda innovazione",
  STRUMENTI_SCHEDA_INNOVAZIONE_HELP: "trovi una descrizione dettagliata di tutti i campi da utilizzare per descrivere i progetti innovativi all’interno del sistema informativo.", // prettier-ignore
  STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE: "Linee guida 'Documentazione multimediale'",
  STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE_HELP: "trovi indicazioni utili per realizzare una documentazione multimediale efficace da allegare ai progetti nel campo 'link documentazione'", // prettier-ignore
  STRUMENTI_TUTORIAL: "Tutorial",
  STRUMENTI_TUTORIAL_HELP: "trovi un tour guidato all’interno della piattaforma per conoscere le funzioni a tua disposizione e le buone pratiche di utilizzo.", // prettier-ignore
  STRUMENTI_FAQ: "FAQ dell’Osservatorio",
  STRUMENTI_FAQ_HELP:
    "trovi una serie di domande e risposte frequenti che possono esserti d’aiuto nell’utilizzo dell’Osservatorio dei Progetti Innovativi.",
  STRUMENTI_PIATTAFORMA_MOODLE: "Piattaforma Moodle",
  STRUMENTI_PIATTAFORMA_MOODLE_HELP: "trovi un archivio digitale di risorse e contenuti pratici organizzati in corsi tematici suddivisi per argomento.", // prettier-ignore
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const sessionCountdown = {
  SESSION_COUNTDOWN_INFO: "La tua sessione scadrà fra",
  SESSION_COUNTDOWN_SECONDS: "secondi",
  SESSION_COUNTDOWN_BUTTON: "Mantieni sessione",
  SESSION_COUNTDOWN_SESSION_EXPIRED: "La tua sessione è scaduta",
  SESSION_COUNTDOWN_LOGIN: "Accedi",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const gatewayError = {
  GATEWAY_ERROR_INFO: "Si è verificato un errore con il sistema di autenticazione",
  GATEWAY_ERROR_INSTRUCTIONS: "Chiudere il browser e riprovare",
  GATEWAY_ERROR_HELP: "In caso di persistenza dell'errore contattare l'amministratore",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const privacyError = {
  PRIVACY_ERROR_INFO: "Aggiornamento informativa sul trattamento dei dati personali",
  PRIVACY_ERROR_INSTRUCTIONS: "Abbiamo aggiornato la nosta informativa sul trattamento dei dati personali.",
  PRIVACY_ERROR_HELP: "Per poter procedere è necessario leggerla e accettarla",
  PRIVACY_INFORMATIVA: "Informativa",
  PRIVACY_ACCETTA: "Accetta e chiudi",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const pageNotFound = {
  BACK_HOME: "Torna alla home page",
  NOT_FOUND: "Ops! Pagina non trovata",
  EMPTY_MESSAGE: "Nessun risultato",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const sagas = {
  // Profilo
  SAGAS_PROFILO_ERROR_TITLE: "Errore durante il recupero del profilo utente",
  SAGAS_PROFILO_ERROR_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero del profilo utente",
  SAGAS_CHECK_PRIVACY_ERROR_TITLE: "Errore durante il recupero delle informazioni sui consensi privacy",
  SAGAS_CHECK_PRIVACY_ERROR_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero delle informazioni sui consensi privacy",
  SAGAS_SET_PRIVACY_SUCCESS_TITLE: "Informativa privacy aggiornata correttamente",
  SAGAS_SET_PRIVACY_SUCCESS_DESCRIPTION: "L'informativa privacy è stata aggiornata con successo alla versione più recente",
  SAGAS_SET_PRIVACY_ERROR_TITLE: "Errore durante l'aggiornamento dell'informativa privacy",
  SAGAS_SET_PRIVACY_ERROR_DESCRIPTION: "Si è verificato un errore imprevisto durante l'aggiornamento dell'informativa privacy",
  SAGAS_VERIFICA_RUOLO_LIBERO_ERROR_TITLE: "Errore durante la verifica dell'unicità dell'incarico selezionato",
  SAGAS_VERIFICA_RUOLO_LIBERO_ERROR_DESCRIPTION: "Si è verificato un errore imprevisto durante la verifica dell'unicità dell'incarico selezionato",
  SAGAS_UPDATE_PROFILO_SUCCESS_TITLE: "Profilo utente aggiornato correttamente",
  SAGAS_UPDATE_PROFILO_SUCCESS_DESCRIPTION: "Con richiesta di cambio ruolo potresti essere in attesa di approvazione",
  SAGAS_UPDATE_PROFILO_ERROR_TITLE: "Errore durante l'aggiornamento del profilo utente",
  SAGAS_UPDATE_PROFILO_ERROR_DESCRIPTION: "Si è verificato un errore imprevisto durante l'aggiornamento del profilo utente",
  SAGAS_DISABILITA_PROFILO_ERROR_TITLE: "Errore durante la disattivazione del profilo",
  SAGAS_DISABILITA_PROFILO_ERROR_DESCRIPTION: "Si è verificato un errore imprevisto durante la disattivazione del profilo",
  // Common
  SAGAS_VALUTAZIONE_EFFICACIA_PROGETTO_TITLE: "Errore durante il recupero della lista di valutazione dell'efficacia del progetto",
  SAGAS_VALUTAZIONE_EFFICACIA_PROGETTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista di valutazione dell'efficacia del progetto", //prettier-ignore
  SAGAS_TIPOLOGIA_PROGETTO_TITLE: "Errore durante il recupero della lista delle tipologie di progetto",
  SAGAS_TIPOLOGIA_PROGETTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle tipologie di progetto",
  SAGAS_TIPOLOGIA_ISTITUTO_TITLE: "Errore durante il recupero della lista delle tipologie di istituto",
  SAGAS_TIPOLOGIA_ISTITUTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle tipologie di istituto",
  SAGAS_TIPOLOGIA_STUDENTI_BES_TITLE: "Errore durante il recupero della lista delle tipologie di studenti Bes",
  SAGAS_TIPOLOGIA_STUDENTI_BES_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle tipologie di studenti Bes",
  SAGAS_STATO_PUBBLICAZIONE_TITLE: "Errore durante il recupero della lista degli stati di pubblicazione",
  SAGAS_STATO_PUBBLICAZIONE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista degli stati di pubblicazione",
  SAGAS_PROVINCE_TITLE: "Errore durante il recupero della lista delle province",
  SAGAS_PROVINCE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle province",
  SAGAS_ISTITUTI_SCOLASTICI_TITLE: "Errore durante il recupero della lista degli istituti scolastici",
  SAGAS_ISTITUTI_SCOLASTICI_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista degli istituti scolastici",
  SAGAS_TIPOLOGIA_DOCUMENTAZIONE_TITLE: "Errore durante il recupero della lista delle tipologie di documentazione",
  SAGAS_TIPOLOGIA_DOCUMENTAZIONE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle tipologie di documentazione", //prettier-ignore
  SAGAS_CONTESTO_ACCOMPAGNAMENTO_TITLE: "Errore durante il recupero della lista dei contesti di accompagnamento",
  SAGAS_CONTESTO_ACCOMPAGNAMENTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista dei contesti di accompagnamento",
  SAGAS_INCARICHI_TITLE: "Errore durante il recupero della lista degli incarichi",
  SAGAS_INCARICHI_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista degli incarichi",
  SAGAS_INDIRIZZO_QUALIFICA_ISTITUTO_TITLE: "Errore durante il recupero della lista degli indirizzi di qualifica istituto",
  SAGAS_INDIRIZZO_QUALIFICA_ISTITUTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista degli indirizzi di qualifica istituto", //prettier-ignore
  SAGAS_ATTIVITA_CURRICOLARI_TITLE: "Errore durante il recupero della lista delle attività curricolari",
  SAGAS_ATTIVITA_CURRICOLARI_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle attività curricolari",
  SAGAS_ATTIVITA_CURRICOLARI_RICERCA_TITLE: "Errore durante il recupero della lista delle attività curricolari per la ricerca",
  SAGAS_ATTIVITA_CURRICOLARI_RICERCA_DESCRIPTION:
    "Si è verificato un errore imprevisto durante il recupero della lista delle attività curricolari per la ricerca",
  SAGAS_ATTIVITA_CLIL_TITLE: "Errore durante il recupero della lista delle attività Clil",
  SAGAS_ATTIVITA_CLIL_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle attività Clil",
  SAGAS_TIPOLOGIA_BENEFICIARIO_PROGETTO_TITLE: "Errore durante il recupero della lista delle tipologie di beneficiario del progetto",
  SAGAS_TIPOLOGIA_BENEFICIARIO_PROGETTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle tipologie di beneficiario del progetto", // prettier-ignore
  SAGAS_TEMPO_REALIZZAZIONE_PROGETTO_TITLE: "Errore durante il recupero della lista dei tempi di realizazione del progetto",
  SAGAS_TEMPO_REALIZZAZIONE_PROGETTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista dei tempi di realizazione del progetto", //prettier-ignore
  SAGAS_TEMATICA_PROGETTO_TITLE: "Errore durante il recupero della lista delle tematiche del progetto",
  SAGAS_TEMATICA_PROGETTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle tematiche del progetto",
  SAGAS_ORDINE_SCUOLA_TITLE: "Errore durante il recupero della lista degli ordini delle scuole",
  SAGAS_ORDINE_SCUOLA_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista degli ordini delle scuole",
  SAGAS_FONDI_CONTESTO_PROGETTO_TITLE: "Errore durante il recupero della lista dei fondi contesto del progetto",
  SAGAS_FONDI_CONTESTO_PROGETTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista dei fondi contesto del progetto",
  SAGAS_ATTIVITA_DIGITALE_TITLE: "Errore durante il recupero della lista delle attività digitali",
  SAGAS_ATTIVITA_DIGITALE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle attività digitali",
  SAGAS_COMPOSIZIONE_GRUPPO_STUDENTI_TITLE: "Errore durante il recupero della lista della composizione del gruppo studenti",
  SAGAS_COMPOSIZIONE_GRUPPO_STUDENTI_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista della composizione del gruppo studenti", //prettier-ignore
  SAGAS_AREA_COINVOLTA_TITLE: "Errore durante il recupero della lista delle aree coinvolte",
  SAGAS_AREA_COINVOLTA_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle aree coinvolte",
  SAGAS_ANNO_SCOLASTICO_REALIZZAZIONE_TITLE: "Errore durante il recupero della lista degli anni scolastici di realizzazione",
  SAGAS_ANNO_SCOLASTICO_REALIZZAZIONE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista degli anni scolastici di realizzazione", //prettier-ignore
  SAGAS_TITOLO_TITLE: "Errore durante il recupero della lista dei titoli",
  SAGAS_TITOLO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista dei titoli",
  SAGAS_AZIONI_DIFFUSIONE_DISSEMINAZIONE_TITLE: "Errore durante il recupero della lista delle azioni di diffusione e disseminazione",
  SAGAS_AZIONI_DIFFUSIONE_DISSEMINAZIONE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle azioni di diffusione e disseminazione", //prettier-ignore
  SAGAS_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_TITLE: "Errore durante il recupero della lista delle attività di valutazione dell'efficacia del progetto", //prettier-ignore
  SAGAS_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero della lista delle attività di valutazione dell'efficacia del progetto", //prettier-ignore
  SAGAS_REFERENTI_TITLE: "Errore durante il recupero dell'elenco dei referenti",
  SAGAS_REFERENTI_DESCRIPTION: "Si è verificato un errore durante il recupero dell'elenco dei referenti",
  SAGAS_CO_REFERENTI_TITLE: "Errore durante il recupero dell'elenco dei coReferenti",
  SAGAS_CO_REFERENTI_DESCRIPTION: "Si è verificato un errore durante il recupero dell'elenco dei coReferenti",
  SAGAS_CO_RESPONSABILE_TITLE: "Errore durante il recupero dell'elenco dei co-responsabili",
  SAGAS_CO_RESPONSABILE_DESCRIPTION: "Si è verificato un errore durante il recupero dell'elenco dei co-responsabili",
  // Progetti
  SAGAS_PROGETTI_TITLE: "Errore durante il recupero dei progetti",
  SAGAS_PROGETTI_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei progetti",
  SAGAS_PROGETTI_INNOVAZIONE_TITLE: "Errore durante il recupero dei progetti innovazione della Scuola",
  SAGAS_PROGETTI_INNOVAZIONE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei progetti innovazione della Scuola",
  SAGAS_PROGETTI_BOZZA_TITLE: "Errore durante il recupero dei progetti in bozza",
  SAGAS_PROGETTI_BOZZA_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei progetti in bozza dell'utente",
  SAGAS_PROGETTI_CONCLUSI_TITLE: "Errore durante il recupero dei progetti conclusi",
  SAGAS_PROGETTI_CONCLUSI_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei progetti conclusi dell'utente",
  SAGAS_PROGETTI_ISTITUTO_TITLE: "Errore durante il recupero dei progetti dell'istituto",
  SAGAS_PROGETTI_ISTITUTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei progetti dell'istituto di appartenenza dell'utente", // prettier-ignore
  SAGAS_DETTAGLI_PROGETTO_TITLE: "Errore durante il recupero dei dati del progetto",
  SAGAS_DETTAGLI_PROGETTO_DESCRIPTION: "Si è verificato un imprevisto durante il recupero dei dati per i dettagli del progetto",
  // Dati scheda istituti
  SAGAS_ISTITUTO_TITLE: "Errore durante il recupero dei dati dell'istito scolastico",
  SAGAS_ISTITUTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei dati dell'istituto scolastico",
  SAGAS_PLESSI_ISTITUTO_TITLE: "Errore durante il recupero dei dati dei plessi dell'istituto",
  SAGAS_PLESSI_ISTITUTO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei dati dei plessi dell'istituto",
  SAGAS_PROFILI_USCITA_TITLE: "Errore durante il recupero dei dati dei profili in uscita",
  SAGAS_PROFILI_USCITA_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei dati dei profili in uscita",
  SAGAS_MEMBRI_TEAM_TITLE: "Errore durante il recupero dei dati dei membri del team",
  SAGAS_MEMBRI_TEAM_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei dati dei membri del team",
  // Dati osservatorio
  SAGAS_COMUNE_TITLE: "Errore durante il recupero dell'elenco dei comuni",
  SAGAS_COMUNE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dell'elenco dei comuni",
  SAGAS_ATTIVITA_INNOVAZIONE_TITLE: "Errore durante il recupero delle attivita inerenti alla sezione innovazione",
  SAGAS_ATTIVITA_INNOVAZIONE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero delle attivita inerenti alla sezione innovazione",
  SAGAS_DISCIPLINE_COINVOLTE_TITLE: "Errore durante il recupero delle discipline coinvolte",
  SAGAS_DISCIPLINE_COINVOLTE_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero delle discipline coinvolte",
  //scheda profilo
  SAGAS_SCHEDA_PROFILO_TITLE: "Errore durante il recupero dei dati del profilo",
  SAGAS_SCHEDA_PROFILO_DESCRIPTION: "Si è verificato un errore imprevisto durante il recupero dei dati del profilo",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const buttons = {
  BUTTON_OK: "Ok",
  BUTTON_HOME: "Home",
  BUTTON_SALVA: "Salva",
  BUTTON_PULISCI_FILTRI: "Pulisci",
  BUTTON_APPLICA_FILTRI: "Applica",
  BUTTON_FILTRI: "Filtri",
  BUTTON_ANNULLAMENTO_ELIMINAZIONE: "No, non eliminare",
  BUTTON_ELIMINA: "Si, elimina",
  BUTTON_ESCI: "Si, esci",
  BUTTON_ANNULLA: "No, annulla",
  BUTTON_SALVA_ESCI: "Salva ed esci",
  BUTTON_SALVA_AVANTI: "Salva e avanti",
  BUTTON_SALVA_CHIUDI: "Salva e chiudi",
  BUTTON_CONTINUA_MODIFICA: "Continua a modificare",
  BUTTON_GESTIONE_PROGETTI: "Gestione progetti",
  BUTTON_INSERIMENTO: "Inserisci progetto e documentazione",
  BUTTON_INSERIMENTO_PROGETTI_CURRICOLARI: "Progetti curricolare/extracurricolari:attività con gli studenti",
  BUTTON_INSERIMENTO_PROGETTI_INNOVAZIONE: "Progetti innovazione:attività d'Istituto",
  BUTTON_MAPPA: "Mappa dell'osservatorio",
  BUTTON_RICERCA: "Cerca nell'osservatorio",
  BUTTON_DATI_SINTESI: "Consulta i dati di sintesi",
  BUTTON_LOGOUT: "Logout",
  BUTTON_NEWSLETTER: "Iscriviti a newsletter",
  BUTTON_DISABILITA_PROFILO: "Disabilita profilo",
  BUTTON_SCHEDA_ISTITUTO: "Scheda istituto",
  BUTTON_CAMBIA_ISTITUTO: "Cambia istituto",
  BUTTON_TEAM_DIGITALE: "Gestisci team digitale",
  BUTTON_PROFILO: "Il mio profilo",
  BUTTON_INSERIMENTO_CURRICOLARE: "Progetti curricolari/extracurricolari: attività con gli studenti",
  BUTTON_INSERIMENTO_INNOVAZIONE: "Progetti innovazione: attività d’Istituto",
  BUTTON_SCARICA: "Stampa",
  BUTTON_CONDIVIDI: "Condividi",
  BUTTON_CHIUDI: "Chiudi",
  BUTTON_APRI: "Apri",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const placeholder = {
  PLACEHOLDER_ESPLORA: "Esplora",
  PLACEHOLDER_SCELTA: "Scegli",
  PLACEHOLDER_STATO_PUBBLICAZIONE: "Seleziona",
  PLACEHOLDER_SCRIVI: "Scrivi...",
  PLACEHOLDER_INSERISCI: "Inserisci",
  PLACEHOLDER_DESCRIVI: "Descrivi",
  PLACEHOLDER_DISCIPLINE_COINVOLTE: "Esplora discipline coinvolte",
  PLACEHOLDER_DESCRIVI_OPPORTUNITA: "Descrivi opportunità",
  PLACEHOLDER_DESCRIVI_VINCOLI: "Descrivi vincoli",
  PLACEHOLDER_INSERISCI_TESTO: "Inserisci testo",
  PLACEHOLDER_COMPOSIZIONE_STUDENTI: "Inserisci composizione gruppo studenti",
  PLACEHOLDER_TEMA_PREVALENTE: "Inserisci tema prevalente",
  PLACEHOLDER_ORDINE_SCUOLA: "Inserisci ordine di scuola",
  PLACEHOLDER_ANNO_REALIZZAZIONE: "Inserisci anno",
  PLACEHOLDER_ATTIVITA_DIGITALE: "Inserisci attività digitale",
  PLACEHOLDER_ATTIVITA_CLIL: "Inserisci attività CLIL",
  PLACEHOLDER_TIPOLOGIA_PROGETTO: "Inserisci tipologia di progetto",
  PLACEHOLDER_TEAM_DIGITALE: "Seleziona dal team digitale",
  PLACEHOLDER_DESCRIZIONE_PROGETTO: "Inserisci una breve descrizione del progetto",
  PLACEHOLDER_TITOLO_PROGETTO: "Inserisci titolo del progetto",
  PLACEHOLDER_NUMERO: "Inserisci il numero",
  PLACEHOLDER_VALUTAZIONE: "Inserisci valutazione",
  PLACEHOLDER_DOCUMENTAZIONE: "Inserisci documentazione progetti",
  PLACEHOLDER_REFERENTE: "Seleziona",
  PLACEHOLDER_CONTESTO_ACCOMPAGNAMENTO: "Inserisci contesto di accompagnamento",
  PLACEHOLDER_DENOMINAZIONE: "Inserisci denominazione",
  PLACEHOLDER_TEMPO_REALIZZAZIONE: "Seleziona tempo di realizzazione",
  PLACEHOLDER_STUDENTI_BES: "Inserisci tipologia",
  PLACEHOLDER_LINK: "https://www.esempio.com/",
  PLACEHOLDER_SUPPORTO_DEL_PROGETTO: "Descrivi il supporto ricevuto dal Progetto Scuola Digitale Liguria",
  PLACEHOLDER_ABSTRACT: "Inserisci breve descrizione del progetto",
  PLACEHOLDER_TITOLO: "Scrivi titolo",
  PLACEHOLDER_CODICE_MECCANOGRAFICO: "Scrivi codice meccanografico",
  PLACEHOLDER_TIPOLOGIA_BENEFICIARIO: "Scegli tipologia beneficiario",
  PLACEHOLDER_PROVINCIA: "Scegli provincia",
  PLACEHOLDER_FONDI_CONTESTO: "Scegli fondi e contesto",
  PLACEHOLDER_AREA_COINVOLTA: "Scegli area coinvolta",
  PLACEHOLDER_ATTIVITA_CONTENUTI: "Scegli attività e contenuti",
  PLACEHOLDER_ATTIVITA: "Scegli attività",
  PLACEHOLDER_TIPOLOGIA: "Scegli tipologia istituto",
  PLACEHOLDER_COMUNE: "Scegli comune",
  PLACEHOLDER_PROFILO_USCITA: "Inserisci indirizzo",
  PLACEHOLDER_INTERDISCIPLINARIETA: "Scegli interdisciplinarietà",
  PLACEHOLDER_DATA: "Inserisci data",
  PLACEHOLDER_DOCUMENTATO: "Seleziona",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const modaleAttivita = {
  TITOLO_MODALE_ATTIVITA: "Attività inserite della sezione:",
  TITOLO_MODALE_ATTIVITA_RICERCA: "Raffina la tua ricerca:",
  SWITCH_ATTIVITA: "Mostra solo le attività obbligatorie",
  TESTO_MODALE_ATTIVITA: "Seleziona le seguenti voci e scegli i contenuti più adeguati per descrivere il tuo progetto: scorri tutto il menu, compila i campi, e al termine clicca sul pulsante SALVA.", //prettier-ignore
  TESTO_MODALE_ATTIVITA_RICERCA:'Seleziona i campi e scegli fra le opzioni quelle che ti consentiranno di trovare i progetti che rispondono ai tuoi obiettivi di ricerca. Al termine clicca sul pulsante SALVA', //prettier-ignore
  DESCRIZIONE_SOTTOATTIVITA: "Scrivi descrizione",
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

const tabAttivitaCurricolariExtraCurricolari = {
  DIDATTICA: "Didattica",
  LAVORO: "Lavoro",
  WEB: "Web",
  COMUNICAZIONE: "Comunicazione",
  CONTENUTI: "Contenuti",
  SICUREZZA: "Sicurezza",
  PROBLEM_SOLVING: "Problem solving",
};

/* ----------------------------------------------------------------------------------------------------------------------------------------------------- */

export const labels = {
  ...common,
  ...tableResponsive,
  ...menu,
  ...profilo,
  ...strumenti,
  ...buttons,
  ...tabAttivitaCurricolariExtraCurricolari,
  ...sagas,
  ...placeholder,
  ...modaleAttivita,
  ...sessionCountdown,
  ...gatewayError,
  ...privacyError,
  ...privacyError,
  ...pageNotFound,
  ...sagas,
};
