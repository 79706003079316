import {
  REDUX_CODICE_PROGETTO,
  REDUX_DETTAGLI_PROGETTO,
  REDUX_PROGETTI_BOZZA,
  REDUX_PROGETTI_CONCLUSI,
  REDUX_PROGETTI_CURRICOLARI_EXTRACURRICOLARI,
  REDUX_PROGETTI_ISTITUTO,
} from "../../constants";
import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";
import {
  fetchCodiceProgettoFailed,
  fetchCodiceProgettoSuccess,
  fetchDettagliProgettoFiled,
  fetchDettagliProgettoReset,
  fetchDettagliProgettoSuccess,
  fetchProgettiBozzaFailed,
  fetchProgettiBozzaSuccess,
  fetchProgettiConclusiFailed,
  fetchProgettiConclusiSuccess,
  fetchProgettiCurricolariExtraCurricolariFailed,
  fetchProgettiCurricolariExtraCurricolariSuccess,
  fetchProgettiIstitutoFailed,
  fetchProgettiIstitutoSuccess,
} from "./";
import { resetDatiIstituti } from "../istituti";

const initialState = {
  //PROGETTI

  [REDUX_PROGETTI_BOZZA]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_PROGETTI_CONCLUSI]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_PROGETTI_ISTITUTO]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_PROGETTI_CURRICOLARI_EXTRACURRICOLARI]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_DETTAGLI_PROGETTO]: {
    data: [],
    fetchEnded: false,
  },
  [REDUX_CODICE_PROGETTO]: {
    data: [],
    fetchEnded: false,
  },
};

//PROGETTI
const fetchProgBozzaSuccess = (state, action) => {
  return update(state, {
    [REDUX_PROGETTI_BOZZA]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProgBozzaFailed = (state, action) => {
  return update(state, {
    [REDUX_PROGETTI_BOZZA]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProgConclusiSuccess = (state, action) => {
  return update(state, {
    [REDUX_PROGETTI_CONCLUSI]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProgConclusiFailed = (state, action) => {
  return update(state, {
    [REDUX_PROGETTI_CONCLUSI]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProgIstitutoSuccess = (state, action) => {
  return update(state, {
    [REDUX_PROGETTI_ISTITUTO]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProgIstitutoFailed = (state, action) => {
  return update(state, {
    [REDUX_PROGETTI_ISTITUTO]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProgettiCurrSuccess = (state, action) => {
  return update(state, {
    [REDUX_PROGETTI_CURRICOLARI_EXTRACURRICOLARI]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProgettiCurrFailed = (state, action) => {
  return update(state, {
    [REDUX_PROGETTI_CURRICOLARI_EXTRACURRICOLARI]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchDettagliSuccess = (state, action) => {
  return update(state, {
    [REDUX_DETTAGLI_PROGETTO]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchDettagliReset = (state, action) => {
  return update(state, {
    [REDUX_DETTAGLI_PROGETTO]: {
      data: { $set: initialState[REDUX_DETTAGLI_PROGETTO] },
      fetchEnded: { $set: false },
    },
  });
};

const fetchDettagliFailed = (state, action) => {
  return update(state, {
    [REDUX_DETTAGLI_PROGETTO]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCodiceSuccess = (state, action) => {
  return update(state, {
    [REDUX_CODICE_PROGETTO]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCodiceFailed = (state, action) => {
  return update(state, {
    [REDUX_CODICE_PROGETTO]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const resetDatiIstitutiHandler = (state) => {
  return update(state, {
    [REDUX_PROGETTI_ISTITUTO]: { $set: initialState[REDUX_PROGETTI_ISTITUTO] },
  });
};

export const progettiSlice = createSlice({
  name: "progetti",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //PROGETTI
    builder.addCase(fetchProgettiBozzaSuccess, (state, action) => fetchProgBozzaSuccess(state, action));
    builder.addCase(fetchProgettiBozzaFailed, (state, action) => fetchProgBozzaFailed(state, action));
    builder.addCase(fetchProgettiConclusiSuccess, (state, action) => fetchProgConclusiSuccess(state, action));
    builder.addCase(fetchProgettiConclusiFailed, (state, action) => fetchProgConclusiFailed(state, action));
    builder.addCase(fetchProgettiIstitutoSuccess, (state, action) => fetchProgIstitutoSuccess(state, action));
    builder.addCase(fetchProgettiIstitutoFailed, (state, action) => fetchProgIstitutoFailed(state, action));
    builder.addCase(fetchProgettiCurricolariExtraCurricolariSuccess, (state, action) => fetchProgettiCurrSuccess(state, action));
    builder.addCase(fetchProgettiCurricolariExtraCurricolariFailed, (state, action) => fetchProgettiCurrFailed(state, action));
    builder.addCase(fetchDettagliProgettoSuccess, (state, action) => fetchDettagliSuccess(state, action));
    builder.addCase(fetchDettagliProgettoFiled, (state, action) => fetchDettagliFailed(state, action));
    builder.addCase(fetchDettagliProgettoReset, (state, action) => fetchDettagliReset(state, action));
    builder.addCase(fetchCodiceProgettoSuccess, (state, action) => fetchCodiceSuccess(state, action));
    builder.addCase(fetchCodiceProgettoFailed, (state, action) => fetchCodiceFailed(state, action));
    builder.addCase(resetDatiIstituti, (state) => resetDatiIstitutiHandler(state));
  },
});

export const datiProgettiReducer = progettiSlice.reducer;
