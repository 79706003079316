import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Stack, Typography, Dialog, DialogTitle, IconButton, DialogContent, Button } from "@mui/material";
import {
  CloseOutlined as CloseOutlinedIcon,
  DvrOutlined as DvrOutlinedIcon,
  Person as PersonIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { labels } from "../../labels";
import { PATH_BACKOFFICE, PATH_HOME, PATH_PROFILO, PATH_SCHEDA_ISTITUTO, PATH_STRUMENTI } from "../../path";
import { logout } from "../../modules/auth";
import { PROFILO_INIZIALI, PROFILO_RUOLO, RUOLO_AMM, RUOLO_GES, RUOLO_SUP, getInfoProfilo } from "../../modules/profilo";
import { LogoutModal } from "./LogoutModal";
import { IstitutoIcon } from "../../styles/icons";
import { savePath, setCodiceMecc, setThemeColor } from "../../modules/configuration";

const { MENU_BACKOFFICE, MENU_GESTISCI_PROFILO, MENU_SCHEDA_ISTITUTO, MENU_STRUMENTI, MENU_LOGOUT, MENU_AREA_PERSONALE } = labels;

const KEY_BACKOFFICE = "backoffice";
const KEY_PROFILO = "profilo";
const KEY_SCHEDA_ISTITUTO = "schedaIstituto";
const KEY_STRUMENTI = "strumenti";
const KEY_LOGOUT = "logout";

export const MobileLoggedMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profilo = useSelector(getInfoProfilo);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const paths = [
    profilo &&
      RUOLO_AMM === profilo[PROFILO_RUOLO] && {
        key: KEY_BACKOFFICE,
        url: PATH_BACKOFFICE,
        label: MENU_BACKOFFICE,
        icon: <DvrOutlinedIcon />,
      },
    profilo &&
      [RUOLO_AMM, RUOLO_GES, RUOLO_SUP].includes(profilo[PROFILO_RUOLO]) && {
        key: KEY_PROFILO,
        url: PATH_PROFILO,
        label: MENU_GESTISCI_PROFILO,
        icon: <PersonIcon />,
      },
    profilo &&
      [RUOLO_AMM, RUOLO_GES, RUOLO_SUP].includes(profilo[PROFILO_RUOLO]) && {
        key: KEY_SCHEDA_ISTITUTO,
        url: PATH_SCHEDA_ISTITUTO,
        label: MENU_SCHEDA_ISTITUTO,
        icon: (
          <Box
            sx={{
              ".cls-1": { fill: "#105b70", width: "35px !important" },
              marginBottom: "-10px",
            }}
          >
            <IstitutoIcon />
          </Box>
        ),
      },
    profilo &&
      [RUOLO_AMM, RUOLO_GES, RUOLO_SUP].includes(profilo[PROFILO_RUOLO]) && {
        key: KEY_STRUMENTI,
        url: PATH_STRUMENTI,
        label: MENU_STRUMENTI,
        icon: <SettingsIcon />,
      },
    {
      key: KEY_LOGOUT,
      label: MENU_LOGOUT,
      icon: <PowerSettingsNewIcon />,
    },
  ].filter((item) => item);

  const UserAvatar = () => profilo && <Avatar>{profilo[PROFILO_INIZIALI]}</Avatar>;

  const NavigationModal = () => (
    <Dialog open={dialogOpen} className="navigationModal">
      <DialogTitle>
        <Stack sx={{ opacity: 0 }}>Modal navigation</Stack>
        <IconButton onClick={() => setDialogOpen(false)} aria-label="Chiudi">
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {paths.map((path) => (
          <Button
            onClick={() => {
              setDialogOpen(false);
              if ([KEY_SCHEDA_ISTITUTO].includes(path.key)) {
                dispatch(setThemeColor({ color1: "#105B70 !important", color2: "#98BCBD !important" }));
                dispatch(setCodiceMecc(profilo.codiceScuola));
                dispatch(savePath(PATH_HOME));
                navigate(path.url);
              } else {
                if ([KEY_BACKOFFICE, KEY_PROFILO, KEY_STRUMENTI].includes(path.key)) navigate(path.url);
                else setShowLogoutModal(true);
              }
            }}
            startIcon={path.icon}
            key={path.key}
          >
            <Typography>{path.label}</Typography>
          </Button>
        ))}
      </DialogContent>
    </Dialog>
  );

  return (
    profilo && (
      <Box className="mobileLoggedMenu">
        <Stack onClick={() => setDialogOpen(true)}>
          <UserAvatar />
        </Stack>
        <Typography className="link" sx={{ textAlign: "center", marginTop: "-2px" }}>
          {MENU_AREA_PERSONALE}
        </Typography>
        <NavigationModal />
        <LogoutModal open={showLogoutModal} onYes={() => dispatch(logout())} onNo={() => setShowLogoutModal(false)} />
      </Box>
    )
  );
};
